import {
  CrossDomainRPCServer,
  ServerEventEmitter,
  ServiceHandlerFor,
} from "@meta-web/cross-domain-rpc";
import { OnlineStatus } from "@meta-web/peer-social-common/src/netcode";
import * as React from "react";
import * as ReactDom from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { getDefaultSignallingServerHost } from "../../host";
import {
  ClosedSession,
  KnownPeer,
  OngoingPeerConnection,
  PeerSocialRemoteEvent,
  PeerSocialServiceDefinition,
  SessionClosePeers,
} from "../../postMessages";
import { GlobalIdentityStorage } from "../GlobalIdentityStorage";
import { GlobalSessionStorage } from "../GlobalSessionStorage";
import { App } from "./App";
import { Group, SerializedGroup } from "./group-manager/Group";
import { GroupManager } from "./group-manager/GroupManager";
import { InvitedGroup } from "./group-manager/InvitedGroup";
import { GroupsPanel } from "./GroupList/GroupsPanel";
import { MyIdentityPanel } from "./MyIdentityPanel";
import { PeersPanel } from "./PeersPanel";
import { SignallingServerRemoteContext } from "./signalling/SignallingServerRemoteContext";
import { SignallingServerUserContext } from "./signalling/SignallingServerUserContext";

const webSocketHost = getDefaultSignallingServerHost();

const autoApproveTimeout = 60 * 1000;

export enum KnownPeerConnectionStatus {
  Connected,
  ConnectingOutgoing,
  ConnectingIncoming,
  AutoApprovedWasOutgoing,
  AutoApprovedWasIncoming,
  AutoConnectIfSameUrl,
  Disconnected,
}

export type KnownPeerState = {
  onlineStatus: OnlineStatus | null;
  knownPeer: KnownPeer;
  remoteSignallingServerContext: SignallingServerRemoteContext;
  autoApproveUntil: number;
  autoJoinGroupIdOnConnect?: number;
  connectionStatus: KnownPeerConnectionStatus;
  scripts: Map<
    string,
    {
      url: string;
      name: string;
    }
  >;
};

export class ScriptIframe {
  private container: HTMLDivElement;
  private root: ReactDom.Root;
  private identityStorage: GlobalIdentityStorage;
  private sessionStorage: GlobalSessionStorage;

  private storedKnownPeersCache = new Map<number, KnownPeer>();
  private knownPeerStates = new Map<number, KnownPeerState>();

  private hostPageOrigin: string;
  private rpcServer: CrossDomainRPCServer<typeof PeerSocialServiceDefinition>;
  private eventEmitter: ServerEventEmitter<PeerSocialRemoteEvent>;
  private userSignallingServerContext: SignallingServerUserContext;
  private remoteSignallingServersMap = new Map<string, SignallingServerRemoteContext>();
  private groupManager: GroupManager;
  private currentUserId: number;
  private ownScripts = new Map<string, { url: string; name: string }>();
  private currentPageUrl: string;

  static init(
    origin: string,
    currentHostPageUrl: string,
    navigationTime: undefined | number,
    autoJoinPeerAddress?: KnownPeer,
    autoJoinGroupId?: number,
  ) {
    const scriptIframe = new ScriptIframe(origin, currentHostPageUrl, navigationTime);
    scriptIframe.sendIdentity();
    scriptIframe.setAutoJoinPeer(autoJoinPeerAddress, autoJoinGroupId);
    return scriptIframe;
  }

  constructor(origin: string, currentHostPageUrl: string, pageLoadTime?: number) {
    this.hostPageOrigin = origin;
    this.setCurrentPageUrl(currentHostPageUrl);

    this.identityStorage = new GlobalIdentityStorage();
    this.sessionStorage = new GlobalSessionStorage();
    this.currentUserId = this.identityStorage.getId();

    this.userSignallingServerContext = new SignallingServerUserContext(
      this.identityStorage.getId(),
      this.identityStorage.getUsername(),
      this.getCurrentParentPageUrl(),
      webSocketHost,
      {
        onOffer: (knownPeer: KnownPeer, remoteConnectionAttemptId: number, offer: string) => {
          if (this.shouldHandleIncomingOffer(knownPeer)) {
            this.sendMessage({
              incomingConnectionOffer: {
                remoteConnectionAttemptId,
                knownPeer,
                offer,
              },
            });
          }
        },
        onCandidate: (userId: number, remoteConnectionAttemptId: number, candidate: string) => {
          this.sendMessage({
            incomingConnectionCandidate: {
              remoteConnectionAttemptId,
              userId,
              candidate,
            },
          });
        },
      },
    );

    this.groupManager = new GroupManager(this.currentUserId!, {
      onNewInviteGroup: (invitedGroup: InvitedGroup) => {
        this.render();
      },
      onUpdateInviteGroup: (group: InvitedGroup) => {
        this.render();
      },
      onDeleteInviteGroup: (group: InvitedGroup) => {
        this.render();
      },
      onNewGroup: (group: Group) => {
        this.render();
      },
      onUpdateGroup: (group: Group) => {
        this.render();
      },
      onDeleteGroup: (group: Group) => {
        this.render();
      },
      connectToUser: (userId: number) => {
        console.warn("connectToUser", userId);
        const peer: KnownPeer = {
          userId,
          server: getDefaultSignallingServerHost(),
          token: "",
        };
        if (!this.knownPeerStates.has(userId)) {
          console.warn("unknown user to connect to. Adding and autojoining", userId);
          const knownPeerState = this.addKnownPeer(peer);
          knownPeerState.connectionStatus = KnownPeerConnectionStatus.AutoConnectIfSameUrl;
          knownPeerState.autoApproveUntil = new Date().getTime() + autoApproveTimeout;
        } else {
          this.connectToUser(peer);
        }
      },
      sendAcceptGroupInvite: (userId: number, groupId: number) => {
        console.warn("sendAcceptGroupInvite", userId);
        this.sendMessage({ sendAcceptGroupInvite: { userId, groupId } });
      },
      sendGroupUpdate: (userId: number, group: SerializedGroup) => {
        console.warn("sendGroupUpdate", userId);
        this.sendMessage({ sendGroupUpdate: { userId, group } });
      },
      sendGroupInvite: (userId: number, group: SerializedGroup) => {
        console.warn("sendGroupInvite", userId);
        this.sendMessage({ sendGroupInvite: { userId, group } });
      },
    });

    const serviceHandler: ServiceHandlerFor<typeof PeerSocialServiceDefinition> = {
      peerConnected: (req: KnownPeer) => {
        console.warn("peerConnected", req);

        const peer = this.knownPeerStates.get(req.userId);
        if (!peer) {
          console.error("peerConnected - unknown peer", req);
          return;
        }
        peer.connectionStatus = KnownPeerConnectionStatus.Connected;
        this.groupManager.addPeerConnection(peer.knownPeer);

        if (peer.autoJoinGroupIdOnConnect) {
          console.log("peerConnected - autojoining group", peer.autoJoinGroupIdOnConnect);
          this.sendMessage({
            sendAcceptAutoJoinGroupInvite: {
              userId: peer.knownPeer.userId,
              groupId: peer.autoJoinGroupIdOnConnect,
            },
          });
        }

        this.render();
        return Promise.resolve({ acknowledged: true });
      },
      peerDisconnected: (req: KnownPeer) => {
        console.warn("peerDisconnected", req);

        this.groupManager.removePeerConnection(req.userId);

        const peer = this.knownPeerStates.get(req.userId);
        if (!peer) {
          console.error("peerDisconnected - unknown peer", req);
          return;
        }
        peer.connectionStatus = KnownPeerConnectionStatus.Disconnected;
        this.render();
        return Promise.resolve({ acknowledged: true });
      },

      changedUrl: (req: { url: string }) => {
        this.setCurrentPageUrl(req.url);
        for (const [, peer] of this.knownPeerStates) {
          this.checkIfShouldAutoConnect(peer);
        }
        return Promise.resolve({ acknowledged: true });
      },

      addOwnLoadedScript: (req: { name: string; url: string }) => {
        this.ownScripts.set(req.url, {
          name: req.name,
          url: req.url,
        });
        this.render();
        return Promise.resolve({ acknowledged: true });
      },

      removeOwnLoadedScript: (req: { name: string; url: string }) => {
        this.ownScripts.delete(req.url);
        this.render();
        return Promise.resolve({ acknowledged: true });
      },

      peerSetIdentity: (req: { peer: KnownPeer; displayName: string }) => {
        console.warn("peerSetIdentity", req);
        const peer = this.knownPeerStates.get(req.peer.userId);
        if (!peer) {
          console.error("peerSetIdentity - unknown peer", req);
          return;
        }
        peer.knownPeer.displayName = req.displayName;
        this.identityStorage.addPeerIdentity(
          peer.knownPeer.userId,
          peer.knownPeer.server,
          peer.knownPeer.token,
          peer.knownPeer.displayName,
        );
        this.render();
        return Promise.resolve({ acknowledged: true });
      },

      peerScriptAdded: (req: {
        peer: KnownPeer;
        script: {
          url: string;
          name: string;
        };
      }) => {
        const peer = this.knownPeerStates.get(req.peer.userId);
        if (!peer) {
          console.error("peerScriptAdded - unknown peer", req);
          return;
        }
        peer.scripts.set(req.script.url, {
          url: req.script.url,
          name: req.script.name,
        });
        this.render();
        return Promise.resolve({ acknowledged: true });
      },
      peerScriptRemoved: (req: { peer: KnownPeer; scriptUrl: string }) => {
        const peer = this.knownPeerStates.get(req.peer.userId);
        if (!peer) {
          console.error("peerScriptRemoved - unknown peer", req);
          return;
        }
        peer.scripts.delete(req.scriptUrl);
        this.render();
        return Promise.resolve({ acknowledged: true });
      },

      closedSession: (req: { time: number }) => {
        this.handleClosingOfSession(req.time, this.getConnectedOrDesiredPeers());
        return Promise.resolve({ acknowledged: true });
      },

      receivedInvite: (req: { userId: number; group: SerializedGroup }) => {
        this.groupManager.receivedInvite(req.userId, req.group);
        return Promise.resolve({ acknowledged: true });
      },
      remoteAcceptedInvite: (req: { userId: number; groupId: number }) => {
        this.groupManager.remoteAcceptedInvite(req.userId, req.groupId);
        return Promise.resolve({ acknowledged: true });
      },
      receivedGroupUpdate: (req: { userId: number; group: SerializedGroup }) => {
        this.groupManager.receivedGroupUpdate(req.userId, req.group);
        return Promise.resolve({ acknowledged: true });
      },
      receivedAutoJoinGroup: (req: { userId: number; groupId: number }) => {
        this.groupManager.receivedAutoJoinGroup(req.userId, req.groupId);
        return Promise.resolve({ acknowledged: true });
      },

      initialOfferFromRemoteConnectionToServer: (req: {
        connectedUserId: number;
        offer: string;
      }) => {
        console.warn("initialOfferFromRemoteConnectionToServer", req);

        // TODO - check if this peer is meant to be being connected to
        const peer = this.knownPeerStates.get(req.connectedUserId);
        if (!peer) {
          console.error("initialOfferFromRemoteConnectionToServer - unknown peer", req);
        } else {
          const signallingServer = this.remoteSignallingServersMap.get(peer.knownPeer.server);
          if (!signallingServer) {
            console.error(
              "initialOfferFromRemoteConnectionToServer - unknown signalling server",
              req,
            );
          } else {
            signallingServer.sendOffer(peer.knownPeer.userId, req.offer);
          }
        }

        return Promise.resolve({ acknowledged: true });
      },
      iceNegotiationFromRemoteConnectionToServer: (req: {
        connectedUserId: number;
        ice: string;
      }) => {
        console.warn("iceNegotiationFromRemoteConnectionToServer", req);

        // TODO - check if this peer is meant to be being connected to
        const peer = this.knownPeerStates.get(req.connectedUserId);
        if (!peer) {
          console.error("iceNegotiationFromRemoteConnectionToServer - unknown peer", req);
        } else {
          const signallingServer = this.remoteSignallingServersMap.get(peer.knownPeer.server);
          if (!signallingServer) {
            console.error(
              "iceNegotiationFromRemoteConnectionToServer - unknown signalling server",
              req,
            );
          } else {
            signallingServer.sendCandidate(peer.knownPeer.userId, req.ice);
          }
        }

        return Promise.resolve({ acknowledged: true });
      },

      initialAnswerFromConnectedUserToServer: (req: {
        remoteConnectionAttemptId: number;
        answer: string;
      }) => {
        console.warn("initialAnswerFromConnectedUserToServer", req);
        this.userSignallingServerContext.sendAnswer(req.remoteConnectionAttemptId, req.answer);
        return Promise.resolve({ acknowledged: true });
      },
      iceNegotiationFromConnectedUserToServer: (req: {
        remoteConnectionAttemptId: number;
        ice: string;
      }) => {
        console.warn("iceNegotiationFromConnectedUserToServer", req);
        this.userSignallingServerContext.sendCandidate(req.remoteConnectionAttemptId, req.ice);
        return Promise.resolve({ acknowledged: true });
      },
    };
    this.rpcServer = new CrossDomainRPCServer<typeof PeerSocialServiceDefinition>(serviceHandler);
    this.eventEmitter = this.rpcServer.eventEmitter();
    this.identityStorage = new GlobalIdentityStorage();
    this.sessionStorage = new GlobalSessionStorage();

    window.addEventListener("storage", (e) => {
      console.log("storage updated", e);
      this.updateKnownPeersFromStorage();
      this.sendIdentity();
      this.render();
    });

    this.container = document.createElement("div");
    document.body.appendChild(this.container);
    this.root = ReactDom.createRoot(this.container);

    this.updateKnownPeersFromStorage();

    if (pageLoadTime) {
      this.handleClosedSessions(pageLoadTime);
    }

    this.render();
  }

  private setAutoJoinPeer(knownPeer?: KnownPeer, autoJoinGroupId?: number) {
    console.warn("setAutoJoinPeer", knownPeer, autoJoinGroupId);
    const currentTime = new Date().getTime();
    const currentUrl = this.getCurrentParentPageUrl();
    if (knownPeer) {
      if (knownPeer.userId === this.identityStorage.getId()) {
        console.error("Cannot auto join self");
        return;
      }
      this.addKnownPeer(knownPeer);
      const knownPeerState = this.knownPeerStates.get(knownPeer.userId);
      if (knownPeerState) {
        // TODO - dedupe with closed session autojoin
        // If the peer was previously connected with an outgoing connection then try to connect again
        knownPeerState.connectionStatus = KnownPeerConnectionStatus.AutoConnectIfSameUrl;
        knownPeerState.autoApproveUntil = currentTime + autoApproveTimeout;
        if (autoJoinGroupId) {
          knownPeerState.autoJoinGroupIdOnConnect = autoJoinGroupId;
        }
        if (knownPeerState.onlineStatus && knownPeerState.onlineStatus.url === currentUrl) {
          this.connectToUser(knownPeer);
        }
      }
    }
  }

  private getConnectedOrDesiredPeers(): SessionClosePeers {
    const peers: Array<OngoingPeerConnection> = [];
    for (const [id, knownPeerState] of this.knownPeerStates) {
      if (
        knownPeerState.connectionStatus === KnownPeerConnectionStatus.Connected ||
        ((knownPeerState.connectionStatus === KnownPeerConnectionStatus.AutoApprovedWasIncoming ||
          knownPeerState.connectionStatus === KnownPeerConnectionStatus.AutoApprovedWasOutgoing) &&
          knownPeerState.autoApproveUntil > new Date().getTime()) ||
        (knownPeerState.connectionStatus === KnownPeerConnectionStatus.AutoConnectIfSameUrl &&
          knownPeerState.autoApproveUntil > new Date().getTime())
      ) {
        // If still connected, or did not intentionally disconnect and would like to reconnect
        peers.push({
          knownPeer: knownPeerState.knownPeer,
          outgoingConnection:
            knownPeerState.connectionStatus === KnownPeerConnectionStatus.Connected ||
            knownPeerState.connectionStatus === KnownPeerConnectionStatus.AutoApprovedWasOutgoing ||
            knownPeerState.connectionStatus === KnownPeerConnectionStatus.AutoConnectIfSameUrl,
        });
      }
    }
    return { peers };
  }

  // If a session was closed prior to this one starting then resume it (try to reconnect to peers)
  private resumeClosedSession(closedSession: ClosedSession) {
    console.log("Auto-approving peers from closed session", closedSession);
    const currentTime = new Date().getTime();
    const currentUrl = this.getCurrentParentPageUrl();
    for (const peer of closedSession.peers) {
      const knownPeerState = this.knownPeerStates.get(peer.knownPeer.userId);
      if (knownPeerState) {
        console.log("RESUMING CLOSED SESSION AND AUTO-APPROVING", peer);
        // If the peer was previously connected with an outgoing connection then try to connect again
        knownPeerState.connectionStatus = KnownPeerConnectionStatus.AutoConnectIfSameUrl;
        knownPeerState.autoApproveUntil = currentTime + autoApproveTimeout;
        if (knownPeerState.onlineStatus && knownPeerState.onlineStatus.url === currentUrl) {
          this.connectToUser(peer.knownPeer);
        }
      }
    }
  }

  private handleClosedSessions(pageLoadTime: number) {
    const closedSessions = this.sessionStorage.getClosedSessions();

    const windowLoadTime = pageLoadTime;
    closedSessions.sort((a, b) => {
      return a.timestamp - b.timestamp;
    });
    for (const closedSession of closedSessions) {
      const delta = closedSession.timestamp - windowLoadTime;
      if (delta >= -500) {
        this.resumeClosedSession(closedSession);
        return;
      }
    }
  }

  private getRemoteSignallingServer(serverAddress: string): SignallingServerRemoteContext | null {
    const existing = this.remoteSignallingServersMap.get(serverAddress);
    if (existing) {
      return existing;
    }
    return null;
  }

  private getOrCreateRemoteSignallingServer(serverAddress: string): SignallingServerRemoteContext {
    const existing = this.getRemoteSignallingServer(serverAddress);
    if (existing) {
      return existing;
    }
    const newContext = new SignallingServerRemoteContext(this.currentUserId!, serverAddress, {
      onUserStatus: (userId: number, online: OnlineStatus | null) => {
        console.log("onUserStatus", userId, online);
        const peer = this.knownPeerStates.get(userId);
        if (!peer) {
          console.error("onUserStatus - Could not find known peer with id", userId);
          return;
        }
        peer.onlineStatus = online;
        if (online !== null && online.name && peer.knownPeer.displayName !== online.name) {
          peer.knownPeer.displayName = online.name;
          this.identityStorage.addPeerIdentity(
            peer.knownPeer.userId,
            peer.knownPeer.server,
            peer.knownPeer.token,
            peer.knownPeer.displayName,
          );
        }

        // If online, and we should autoconnect on the same url, and we would approve the connection
        this.checkIfShouldAutoConnect(peer);

        this.render();
      },
      onAnswer: (userId: number, answer: string) => {
        this.sendMessage({
          outgoingConnectionAnswer: {
            userId,
            answer,
          },
        });
      },
      onCandidate: (userId: number, candidate: string) => {
        this.sendMessage({
          outgoingConnectionCandidate: {
            userId,
            candidate,
          },
        });
      },
    });
    this.remoteSignallingServersMap.set(serverAddress, newContext);
    return newContext;
  }

  private addKnownPeer(knownPeer: KnownPeer): KnownPeerState {
    const remoteSignallingContext = this.getOrCreateRemoteSignallingServer(knownPeer.server);
    remoteSignallingContext.addInterestedUsers([
      { userId: knownPeer.userId, token: knownPeer.token },
    ]);
    const newKnownPeer: KnownPeerState = {
      knownPeer: {
        userId: knownPeer.userId,
        server: knownPeer.server,
        token: knownPeer.token,
        displayName: knownPeer.displayName,
      },
      onlineStatus: null,
      remoteSignallingServerContext: remoteSignallingContext,
      autoApproveUntil: 0,
      connectionStatus: KnownPeerConnectionStatus.Disconnected,
      scripts: new Map(),
    };
    this.knownPeerStates.set(knownPeer.userId, newKnownPeer);

    if (!this.storedKnownPeersCache.has(knownPeer.userId)) {
      this.identityStorage.addPeerIdentity(
        knownPeer.userId,
        knownPeer.server,
        knownPeer.token,
        knownPeer.displayName,
      );
      this.updateKnownPeersFromStorage();
    }
    return newKnownPeer;
  }

  private updateKnownPeersFromStorage() {
    const peers = this.identityStorage.getKnownPeers();
    for (const peer of peers) {
      if (!this.storedKnownPeersCache.has(peer.userId)) {
        this.storedKnownPeersCache.set(peer.userId, peer);
      }
      if (!this.knownPeerStates.has(peer.userId)) {
        this.addKnownPeer(peer);
      }
    }
  }

  private sendIdentity() {
    const username = this.identityStorage.getUsername();
    const id = this.identityStorage.getId();
    this.sendMessage({
      storedIdentity: {
        id,
        username,
        server: "",
      },
    });
  }

  private handleClosingOfSession(time: number, closedSession: SessionClosePeers) {
    this.sessionStorage.addClosedSession(time, closedSession);
  }

  private sendMessage(message: PeerSocialRemoteEvent) {
    console.log("global.sendMessage", message);
    this.eventEmitter.sendEvent(message);
  }

  private render() {
    const ownUserId = this.identityStorage.getId();
    const ownDisplayName = this.identityStorage.getUsername();

    const scriptSrc = this.getScriptUrl();
    const currentUrl = this.getCurrentParentPageUrl();

    this.root.render(
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              path="/groups/*"
              element={
                <GroupsPanel
                  ownUserId={ownUserId}
                  ownDisplayName={ownDisplayName}
                  scriptSrc={scriptSrc}
                  currentUrl={currentUrl}
                  groupManager={this.groupManager}
                />
              }
            />
            <Route
              path="/"
              element={
                <div>
                  <MyIdentityPanel
                    scriptSrc={scriptSrc}
                    currentUrl={currentUrl}
                    id={ownUserId}
                    username={ownDisplayName}
                    onSetUsername={(newUsername) => {
                      this.userSignallingServerContext.updateDisplayName(newUsername);
                      this.identityStorage.persistIdentity(newUsername);
                      this.sendIdentity();
                      this.render();
                    }}
                  />
                  <PeersPanel
                    onConnectPress={(knownPeer) => {
                      this.connectToUser(knownPeer);
                    }}
                    onDisconnectPress={(knownPeer) => {
                      this.knownPeerStates.get(knownPeer.userId)!.connectionStatus =
                        KnownPeerConnectionStatus.Disconnected;
                      this.sendMessage({
                        disconnectFromPeer: knownPeer,
                      });
                    }}
                    ownScripts={this.ownScripts}
                    knownPeerStates={this.knownPeerStates}
                    loadScript={(scriptUrl: string) => {
                      this.sendMessage({
                        loadScript: { url: scriptUrl },
                      });
                    }}
                  />
                </div>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>,
    );
  }

  private shouldHandleIncomingOffer(knownPeer: KnownPeer): boolean {
    const userId = knownPeer.userId;
    const knownPeerState = this.knownPeerStates.get(userId);
    if (!knownPeerState) {
      this.addKnownPeer(knownPeer);
      return true;
    }
    if (knownPeerState.connectionStatus === KnownPeerConnectionStatus.Connected) {
      console.warn(`shouldHandleIncomingOffer - Already connected: ${userId}`);
      return false;
    }

    if (knownPeerState.connectionStatus === KnownPeerConnectionStatus.ConnectingOutgoing) {
      // If there is an outgoing connection then it might be racing this incoming attempt. The outgoing attempt from this client is going to hit the same check here on the remote.
      // Use the "highest" id's outgoing connection attempt
      const shouldAccept = this.currentUserId! < userId;
      if (!shouldAccept) {
        console.warn(`shouldHandleIncomingOffer - being passive against: ${userId}`);
      }
      return shouldAccept;
    }
    return true;
  }

  private connectToUser(knownPeer: KnownPeer) {
    console.warn("connectToUser", knownPeer.userId);

    const knownPeerState = this.knownPeerStates.get(knownPeer.userId);
    if (!knownPeerState) {
      console.error("Attempted to connect to peer without known peer state");
      return;
    }

    if (
      knownPeerState.connectionStatus === KnownPeerConnectionStatus.Connected ||
      knownPeerState.connectionStatus === KnownPeerConnectionStatus.ConnectingOutgoing ||
      knownPeerState.connectionStatus === KnownPeerConnectionStatus.ConnectingIncoming
    ) {
      console.warn(
        `Cannot connect to user ${knownPeer.userId} - state is invalid: ${
          KnownPeerConnectionStatus[knownPeerState.connectionStatus]
        }`,
      );
      return;
    }

    knownPeerState.connectionStatus = KnownPeerConnectionStatus.ConnectingOutgoing;

    this.sendMessage({
      connectToPeer: knownPeer,
    });
  }

  private getScriptUrl(): string {
    const url = new URL(`${window.location.protocol}//${window.location.host}`);
    url.pathname = `peer-social.js`;
    return url.toString();
  }

  private getCurrentParentPageUrl(): string {
    return this.currentPageUrl;
  }

  private checkIfShouldAutoConnect(peer: KnownPeerState) {
    if (
      peer.onlineStatus &&
      peer.connectionStatus === KnownPeerConnectionStatus.AutoConnectIfSameUrl &&
      peer.autoApproveUntil > new Date().getTime() &&
      peer.onlineStatus.url === this.getCurrentParentPageUrl()
    ) {
      this.connectToUser(peer.knownPeer);
    }
  }

  private setCurrentPageUrl(url: string) {
    if (url !== this.currentPageUrl) {
      const parsedUrl = new URL(url);
      if (parsedUrl.origin !== this.hostPageOrigin) {
        console.error(
          "setCurrentPageUrl - url is from different origin to original page load - ignoring",
          url,
        );
        return;
      }
      this.currentPageUrl = url;
    }
  }
}
