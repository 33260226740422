
const digest = '64f03111fcb25e4f985b449405985cc674e97822bce4c99aad377e0803046a8a';
const css = `._app_qffk7_1 {
    font-family: sans-serif;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
}

._peer-social-header_qffk7_10 {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: linear-gradient(91.9deg, #2605ED 20.38%, #003C9A 51.89%, #04661A 74.7%, #164629 81.75%, #000000 100%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
}

._peer-social-title_qffk7_24 {
    padding: 15px;
}

._tabs_qffk7_28 {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

._tab-title-holder_qffk7_38 {
    flex-grow: 0;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 20px;
    background: #F2F2F2;
    cursor: pointer;
}

._tab_qffk7_28 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    margin: 2px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    text-decoration: none;
    color: black;
}

._active-tab_qffk7_63 {
    font-weight: bold;
    background: #FFFFFF;
}

._tab-contents_qffk7_68 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    width: 100%;
    text-align: left;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"app":"_app_qffk7_1","peer-social-header":"_peer-social-header_qffk7_10","peerSocialHeader":"_peer-social-header_qffk7_10","peer-social-title":"_peer-social-title_qffk7_24","peerSocialTitle":"_peer-social-title_qffk7_24","tabs":"_tabs_qffk7_28","tab-title-holder":"_tab-title-holder_qffk7_38","tabTitleHolder":"_tab-title-holder_qffk7_38","tab":"_tab_qffk7_28","active-tab":"_active-tab_qffk7_63","activeTab":"_active-tab_qffk7_63","tab-contents":"_tab-contents_qffk7_68","tabContents":"_tab-contents_qffk7_68"};
export { css, digest };
  