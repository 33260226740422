
const digest = '9f904bc6b5fb81ce42e582194e11f702efb10a039792aef4b7d31e67bd79b244';
const css = `._section-header_xpwpp_1 {
    color: #787878;
    padding-top: 10px;
    padding-bottom: 0;
}._button_xchsj_1 {
    cursor: pointer;
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
}

._group-list_1d9ly_1 {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-left: 8px;
    padding-right: 8px;
}

._section-header_1d9ly_11 {
}

._create-group-section_1d9ly_15 {
    display: flex;
    justify-content: center;
    padding: 10px;
}

._create-group-button_1d9ly_21 {
}

._groups-section_1d9ly_25 {
    padding-top: 8px;
}

._no-groups-message_1d9ly_29 {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
}

._invited-groups-section_1d9ly_35 {

}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"group-list":"_group-list_1d9ly_1","groupList":"_group-list_1d9ly_1","section-header":"_section-header_1d9ly_11 _section-header_xpwpp_1","sectionHeader":"_section-header_1d9ly_11 _section-header_xpwpp_1","create-group-section":"_create-group-section_1d9ly_15","createGroupSection":"_create-group-section_1d9ly_15","create-group-button":"_create-group-button_1d9ly_21 _button_xchsj_1","createGroupButton":"_create-group-button_1d9ly_21 _button_xchsj_1","groups-section":"_groups-section_1d9ly_25","groupsSection":"_groups-section_1d9ly_25","no-groups-message":"_no-groups-message_1d9ly_29","noGroupsMessage":"_no-groups-message_1d9ly_29","invited-groups-section":"_invited-groups-section_1d9ly_35","invitedGroupsSection":"_invited-groups-section_1d9ly_35"};
export { css, digest };
  