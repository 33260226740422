export type PeerAddress = {
  server: string;
  userId: number;
};

export function parsePeerAddress(address: string): PeerAddress | Error {
  console.log("parsePeerAddress", address);
  const split = address.split("?");
  if (split.length !== 2) {
    return new Error("Zero or more than one '?'");
  }
  const server = split[0];
  const userIdString = split[1];
  const parsedUserId = parseInt(userIdString, 10);
  if (parsedUserId < 1 || parsedUserId.toString(10) !== userIdString) {
    return new Error("Invalid numeric user id");
  }
  return {
    server,
    userId: parsedUserId,
  };
}
