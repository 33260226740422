
const digest = 'e9fefaef08ad74615add64b7607ab5d55f6aeb1baa39678529ad07cd8704ce92';
const css = `._section-header_xpwpp_1 {
    color: #787878;
    padding-top: 10px;
    padding-bottom: 0;
}._button_xchsj_1 {
    cursor: pointer;
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
}._group_x30z6_1 {
    margin-bottom: 10px;
    border-radius: 8px;
    background: #F2F2F2;
    padding: 10px;
    padding-top: 15px;
    position: relative;
}._group-id_x30z6_10 {
    position: absolute;
    top:0;
    left:0;
    padding: 4px;
    font-size: 10px;
}._group-header_x30z6_18 {
}._members_x30z6_22 {
}._invitees_x30z6_25 {
    display: flex;
}._button-section_x30z6_29 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}._button_x30z6_29 {
}

._invited-group_11e8b_1 {
    background-color: #50c350;
}

._invite-group-id_11e8b_6 {
}

._loading-indicator_11e8b_10 {
    width: 30px;
    height: 30px;
}

._loading-indicator_11e8b_10:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 8px;
    border-radius: 50%;
    border: 2px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: _loading-indicator_11e8b_10 1.2s linear infinite;
}

._button_11e8b_27 {
}

@keyframes _loading-indicator_11e8b_10 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

._members_11e8b_40 {
}

._join-area_11e8b_43 {
    padding-top: 8px;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"invited-group":"_invited-group_11e8b_1 _group_x30z6_1","invitedGroup":"_invited-group_11e8b_1 _group_x30z6_1","invite-group-id":"_invite-group-id_11e8b_6 _group-id_x30z6_10","inviteGroupId":"_invite-group-id_11e8b_6 _group-id_x30z6_10","loading-indicator":"_loading-indicator_11e8b_10","loadingIndicator":"_loading-indicator_11e8b_10","button":"_button_11e8b_27 _button_xchsj_1","members":"_members_11e8b_40","join-area":"_join-area_11e8b_43","joinArea":"_join-area_11e8b_43"};
export { css, digest };
  