export type StringToHslOptions = {
  hueThresholds?: [number, number][];
  saturationThresholds?: [number, number][];
  lightnessThresholds?: [number, number][];
};

const DEFAULT_HUE_RANGES: [number, number][] = [[10, 350]];
const DEFAULT_SATURATION_RANGES: [number, number][] = [[80, 100]];
const DEFAULT_LIGHTNESS_RANGES: [number, number][] = [[40, 70]];

const DEFAULT_HSL_OPTIONS: StringToHslOptions = {
  hueThresholds: DEFAULT_HUE_RANGES,
  saturationThresholds: DEFAULT_SATURATION_RANGES,
  lightnessThresholds: DEFAULT_LIGHTNESS_RANGES,
};

export function DJBReverseHash(input: string): number {
  // Hash has an initial value of 5381. As bit shifting is used, output can be any signed 32 bit Integer.
  const stringLength = input.length;
  let hash = 5381;

  for (let i = stringLength - 1; i >= 0; i--) {
    hash = (hash << 5) + hash + input.charCodeAt(i);
  }

  return hash;
}

function generateValueFromThresholds(hash: number, thresholds: [number, number][]): number {
  const selectedThreshold = thresholds[hash % thresholds.length];
  const min = Math.min(...selectedThreshold);
  const max = Math.max(...selectedThreshold);

  const thresholdRange = Math.abs(max - min);
  return (hash % thresholdRange) + min;
}

export function hslToRgb(hsl: [number, number, number]): [number, number, number] {
  const [h, s, l] = hsl;
  // Must be fractions of 1

  const c = ((1 - Math.abs((2 * l) / 100 - 1)) * s) / 100;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l / 100 - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h <= 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = r + m;
  g = g + m;
  b = b + m;

  return [r * 255, g * 255, b * 255];
}

export function hashAndConvertToHsl(
  input: string,
  options: StringToHslOptions = DEFAULT_HSL_OPTIONS,
): [number, number, number] {
  if (!input) {
    return [0, 0, 0];
  }

  // Because JS bit shifting only operates on 32-Bit, signed Integers, in the case of overflow where a negative
  // hash is inappropriate, the absolute value has to be taken. This 'halves' our theoretical hash distribution.
  // This may require an alternate approach if collisions are too frequent.
  let hash = Math.abs(DJBReverseHash("lightness: " + input));

  const lightness = options.lightnessThresholds
    ? generateValueFromThresholds(hash, options.lightnessThresholds)
    : hash % 99;

  hash = Math.abs(DJBReverseHash("saturation: " + input));
  const saturation = options.saturationThresholds
    ? generateValueFromThresholds(hash, options.saturationThresholds)
    : hash % 99;

  hash = Math.abs(DJBReverseHash("hue: " + input));
  const hue = options.hueThresholds ? generateValueFromThresholds(hash, options.hueThresholds) : hash % 359;

  return [hue, saturation, lightness];
}
