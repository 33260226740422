import { GroupManagerInterface } from "./GroupManager";
import { GroupMember, SerializedMember } from "./GroupMember";
import { ProposedGroupMember } from "./ProposedGroupMember";

export type SerializedGroup = {
  groupId: number;
  members: Array<SerializedMember>;
};

export class Group {
  public readonly groupId: number;
  public members = new Map<number, GroupMember>();
  public ownProposedMembers = new Map<number, ProposedGroupMember>();
  private groupRunner: GroupManagerInterface;
  private ownUserId: number;
  private ownCounter: number;

  // TODO - handle being told that this user is already part of an existing group
  constructor(ownUserId: number, groupId: number, groupRunner: GroupManagerInterface) {
    this.ownUserId = ownUserId;
    this.groupId = groupId;
    this.groupRunner = groupRunner;
  }

  inviteUsers(userIds: Array<number>) {
    const toSendToUserIds: Array<number> = [];
    for (const userId of userIds) {
      const existingMember = this.members.has(userId);
      const existingProposed = this.ownProposedMembers.has(userId);
      if (existingMember) {
        console.warn("Attempted to invite existing member user id. Ignoring.");
      } else {
        toSendToUserIds.push(userId);
        if (existingProposed) {
          console.warn(
            "Attempted to invite existing invited user id. Not modifying, but sending invite again.",
          );
        } else {
          this.ownProposedMembers.set(userId, new ProposedGroupMember(userId, 1));
        }
      }
    }
    for (const userId of toSendToUserIds) {
      this.groupRunner.sendProposal(userId, this.serialize());
    }
    this.groupRunner.didUpdateGroup(this);
  }

  serialize(): SerializedGroup {
    const members: Array<SerializedMember> = [];
    for (const [userId, groupMember] of this.members) {
      members.push(groupMember.serialize());
    }

    // Add the current user as a member
    members.push({
      userId: this.ownUserId,
      userOwnCounter: this.ownCounter,
    });

    const serialized: SerializedGroup = {
      groupId: this.groupId,
      members,
    };
    return serialized;
  }

  remoteAcceptedAutoJoin(userId: number) {
    const groupMember = new GroupMember(userId, 1);
    this.members.set(userId, groupMember);

    this.sendGroupToMembers();
    this.sendGroupToProposedMembers();
  }

  remoteAcceptedInvite(userId: number) {
    const proposedMember = this.ownProposedMembers.get(userId);
    if (!proposedMember) {
      console.error("Missing proposed member from invitation acceptance");
    }
    this.ownProposedMembers.delete(userId);
    const groupMember = new GroupMember(userId, 1);
    this.members.set(userId, groupMember);

    this.sendGroupToMembers();
    this.sendGroupToProposedMembers();
  }

  private sendGroupToMembers() {
    const serialized = this.serialize();
    for (const [userId, member] of this.members) {
      this.groupRunner.sendGroupUpdate(member.userId, this.serialize());
    }
  }

  private sendGroupToProposedMembers() {
    const serialized = this.serialize();
    for (const [userId, proposedMember] of this.ownProposedMembers) {
      this.groupRunner.sendProposal(proposedMember.userId, this.serialize());
    }
  }

  applySerializedGroup(serializedGroup: SerializedGroup) {
    for (const member of serializedGroup.members) {
      const existing = this.members.get(member.userId);
      if (!existing) {
        if (member.userId !== this.ownUserId) {
          const groupMember = new GroupMember(member.userId, member.userOwnCounter);
          this.members.set(member.userId, groupMember);
          this.groupRunner.connectToUser(member.userId);
        }
      }
    }
  }
}
