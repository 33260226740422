import { IframeInternalListener } from "@meta-web/messaging-iframe-helper";

import { GlobalToLocalPostMessage, LocalToGlobalPostMessage, RPCRequest } from "./postMessages";
import { ServiceDefinition, ServiceHandlerFor } from "./rpc";

export type ServerEventEmitter<T extends {}> = {
  sendEvent: (msg: T) => void;
};

export class CrossDomainRPCServer<SD extends ServiceDefinition> {
  private listener: IframeInternalListener<LocalToGlobalPostMessage>;
  private serviceHandler: ServiceHandlerFor<SD>;

  constructor(serviceHandler: ServiceHandlerFor<SD>) {
    this.serviceHandler = serviceHandler;
    this.listener = new IframeInternalListener((msg) => {
      this.handleMessage(msg);
    });
    this.sendMessage({ didLoad: true });
  }

  public close() {
    this.listener.close();
  }

  private handleMessage(msg: {}) {
    const message: LocalToGlobalPostMessage = msg as LocalToGlobalPostMessage;
    if (message.rpcRequest) {
      this.handleRPC(message.rpcRequest);
    }
  }

  public eventEmitter<T extends {}>(): ServerEventEmitter<T> {
    return this as unknown as ServerEventEmitter<T>;
  }

  private sendEvent(event: {}) {
    this.sendMessage({
      remoteEvent: {
        data: event,
      },
    });
  }

  private sendMessage(message: GlobalToLocalPostMessage) {
    window.parent.postMessage(message, "*");
  }

  private handleRPC(rpcRequest: RPCRequest) {
    const { rpcId, method, data } = rpcRequest;
    this.serviceHandler[method](data as any).then((res: any) => {
      this.sendMessage({
        rpcResponse: {
          rpcId,
          data: res,
        },
      });
    });
  }
}
