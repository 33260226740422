import { decode, encode } from "@msgpack/msgpack";

// RPCs (before WS)
// RegisterIdentity

// Remote Websocket
// AddStatusCheck ({id,token}[])
// RemoveStatusCheck (id[])
// AttemptConnection (id, token, description)
// Send Offer

export enum messageNumbers {
  error = 100,

  // Start - Registered User Messages
  loginUser = 101,
  updateUser = 102,

  // Start - WebRTC Handshake Messages
  initialOfferFromServerToConnectedUser = 108,
  initialAnswerFromConnectedUserToServer = 109,
  iceNegotiationFromServerToConnectedUser = 110,
  iceNegotiationFromConnectedUserToServer = 111,
  // End - WebRTC Handshake Messages
  // End - Registered User Messages

  // Start - Remote Connection (non-registered users)
  loginRemoteUser = 200,
  AddStatusCheck = 201,
  RemoveStatusCheck = 202,
  UserStatus = 203,
  initialOfferFromRemoteConnectionToServer = 204,
  initialAnswerFromServerToRemoteConnection = 205,
  iceNegotiationFromRemoteConnectionToServer = 206,
  iceNegotiationFromServerToRemoteConnection = 207,
  // End - Remote Connection (non-registered users)
}

export type OnlineStatus = {
  name: string;
  url: string;
};

export type MessagePayloads = {
  [messageNumbers.error]: {
    message: string;
  };

  // Start - Registered User Messages
  [messageNumbers.loginUser]: {
    userId: number;
    token: string;
    name: string;
    url: string;
  };
  [messageNumbers.updateUser]: {
    name: string;
  };

  // Start - WebRTC Handshake Messages
  [messageNumbers.initialOfferFromServerToConnectedUser]: {
    remoteConnectionAttemptId: number;
    remoteUserId: number;
    remoteUserServer: string;
    offer: string;
  };
  [messageNumbers.initialAnswerFromConnectedUserToServer]: {
    remoteConnectionAttemptId: number;
    answer: string;
  };
  [messageNumbers.iceNegotiationFromConnectedUserToServer]: {
    remoteConnectionAttemptId: number;
    ice: string;
  };
  [messageNumbers.iceNegotiationFromServerToConnectedUser]: {
    remoteConnectionAttemptId: number;
    ice: string;
  };
  // End - WebRTC Handshake Messages
  // End - Registered User Messages

  // Start - Remote Connection (non-registered users)
  [messageNumbers.loginRemoteUser]: {
    userId: number;
    server: string;
    token: string;
    name: string;
  };
  [messageNumbers.AddStatusCheck]: {
    userIdAndTokens: Array<{ userId: number; token: string }>;
  };
  [messageNumbers.RemoveStatusCheck]: {
    userIds: Array<number>;
  };
  [messageNumbers.UserStatus]: {
    users: Array<{
      userId: number;
      online?: OnlineStatus;
      offline?: {};
    }>;
  };
  [messageNumbers.initialOfferFromRemoteConnectionToServer]: {
    connectedUserId: number;
    offer: string;
  };
  [messageNumbers.initialAnswerFromServerToRemoteConnection]: {
    connectedUserId: number;
    answer: string;
  };
  [messageNumbers.iceNegotiationFromRemoteConnectionToServer]: {
    connectedUserId: number;
    ice: string;
  };
  [messageNumbers.iceNegotiationFromServerToRemoteConnection]: {
    connectedUserId: number;
    ice: string;
  };
  // End - Remote Connection (non-registered users)
};

export function encodeMessage<N extends messageNumbers>(
  messageNumber: N,
  messageContents: MessagePayloads[N],
) {
  return encode({
    type: messageNumber,
    payload: messageContents,
  });
}

export type DecodedResponse<N extends messageNumbers> = Readonly<{
  readonly type: N;
  readonly payload: MessagePayloads[N];
}>;

export function decodeMessage<N extends messageNumbers>(
  messageBytes: Uint8Array,
): DecodedResponse<N> {
  return decode(messageBytes) as DecodedResponse<N>;
}

export function isMessageType<N extends messageNumbers>(
  n: N,
  decoded: DecodedResponse<messageNumbers>,
): decoded is DecodedResponse<N> {
  return decoded.type === n;
}
