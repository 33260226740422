
const digest = 'a2307578fa25e45f3d5ba4261e1b9e3ae90bbf4afaf877187373ff157705b1c6';
const css = `._button_xchsj_1 {
    cursor: pointer;
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
}

._admin-contents_l9vrl_1 {
    padding: 10px;
    font-family: sans-serif;
    margin-bottom: 50px;
}

._tab-contents_l9vrl_7{
    margin-top: 10px;
}

._warning_l9vrl_11 {
    padding: 5px;
    background-color: lightgoldenrodyellow;
    border: 1px solid darkgoldenrod;
    margin-bottom: 5px;
    border-radius: 8px;
}

._section-title_l9vrl_19 {
    font-weight: bold;
    margin-bottom: 2px;
}

._section_l9vrl_19 {
    padding: 10px;
    border: 1px solid gray;
    margin-bottom: 10px;
    border-radius: 8px;
}

._allow-host-prompt_l9vrl_31 {
    padding: 10px;
    background-color: #89d377;
}

._prompt-hostname_l9vrl_37 {
    font-weight: bold;
}

._recent-attempts-list_l9vrl_41 {
    margin-bottom: 5px;
}

._row_l9vrl_45 {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-bottom: 2px;
    background-color: #DDD;
    border: 1px solid gray;
}

._allowed-row_l9vrl_54 {
    background-color: darkseagreen;
}

._attempt-row_l9vrl_58 {
    background-color: darksalmon;
}

._row-text-content_l9vrl_62 {
    flex-grow: 1;
}

._allowed-hosts-list_l9vrl_66 {
    margin-bottom: 5px;
}

._peer-input-holder_l9vrl_70 {
    margin-bottom: 5px;
}

._action-row_l9vrl_74 {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    background-color: gray;
    display: flex;
    justify-content: flex-end;
}

._ok-button_l9vrl_84 {
    height: 40px;
    font-weight: bold;
    margin: 5px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

._button_l9vrl_94 {
}

._allow-button_l9vrl_98 {
    background: #0063FF;
    margin-right: 10px;
    color: #FFFFFF;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"admin-contents":"_admin-contents_l9vrl_1","adminContents":"_admin-contents_l9vrl_1","tab-contents":"_tab-contents_l9vrl_7","tabContents":"_tab-contents_l9vrl_7","warning":"_warning_l9vrl_11","section-title":"_section-title_l9vrl_19","sectionTitle":"_section-title_l9vrl_19","section":"_section_l9vrl_19","allow-host-prompt":"_allow-host-prompt_l9vrl_31 _section_l9vrl_19","allowHostPrompt":"_allow-host-prompt_l9vrl_31 _section_l9vrl_19","prompt-hostname":"_prompt-hostname_l9vrl_37","promptHostname":"_prompt-hostname_l9vrl_37","recent-attempts-list":"_recent-attempts-list_l9vrl_41","recentAttemptsList":"_recent-attempts-list_l9vrl_41","row":"_row_l9vrl_45","allowed-row":"_allowed-row_l9vrl_54","allowedRow":"_allowed-row_l9vrl_54","attempt-row":"_attempt-row_l9vrl_58","attemptRow":"_attempt-row_l9vrl_58","row-text-content":"_row-text-content_l9vrl_62","rowTextContent":"_row-text-content_l9vrl_62","allowed-hosts-list":"_allowed-hosts-list_l9vrl_66","allowedHostsList":"_allowed-hosts-list_l9vrl_66","peer-input-holder":"_peer-input-holder_l9vrl_70","peerInputHolder":"_peer-input-holder_l9vrl_70","action-row":"_action-row_l9vrl_74","actionRow":"_action-row_l9vrl_74","ok-button":"_ok-button_l9vrl_84 _button_xchsj_1","okButton":"_ok-button_l9vrl_84 _button_xchsj_1","button":"_button_l9vrl_94 _button_xchsj_1","allow-button":"_allow-button_l9vrl_98 _button_l9vrl_94 _button_xchsj_1","allowButton":"_allow-button_l9vrl_98 _button_l9vrl_94 _button_xchsj_1"};
export { css, digest };
  