import * as Netcode from "@meta-web/peer-social-common/src/netcode";
import {
  decodeMessage,
  encodeMessage,
  isMessageType,
} from "@meta-web/peer-social-common/src/netcode";

import { KnownPeer } from "../../../postMessages";

type SignallingServerUserContextProps = {
  onOffer(knownPeer: KnownPeer, remoteConnectionAttemptId: number, offer: string): void;
  onCandidate(remoteUserId: number, connectionAttemptId: number, candidate: string): void;
};

export class SignallingServerUserContext {
  private ws: WebSocket;
  private props: SignallingServerUserContextProps;

  private connectionOpened = false;
  private userId: number;
  private displayName: string;
  private remoteConnectionAttemptIdToUserId = new Map<number, number>();

  constructor(
    userId: number,
    displayName: string,
    currentUrl: string,
    signallingServerUrl: string,
    props: SignallingServerUserContextProps,
  ) {
    this.userId = userId;
    this.displayName = displayName;
    this.props = props;
    this.ws = new WebSocket(`${signallingServerUrl}/login`);
    this.ws.onopen = (e: Event) => {
      this.connectionOpened = true;
      this.ws.send(
        encodeMessage(Netcode.messageNumbers.loginUser, {
          userId: this.userId,
          token: "", // TODO - token
          name: this.displayName, // TODO - name
          url: currentUrl,
        }),
      );
    };
    this.ws.addEventListener("message", async (event) => {
      const decoded = decodeMessage(await event.data.arrayBuffer());
      if (isMessageType(Netcode.messageNumbers.error, decoded)) {
        console.error("SignallingServerUserContext.error", decoded);
      } else if (
        isMessageType(Netcode.messageNumbers.initialOfferFromServerToConnectedUser, decoded)
      ) {
        console.log("initialOfferFromServerToReceiver", decoded);
        this.remoteConnectionAttemptIdToUserId.set(
          decoded.payload.remoteConnectionAttemptId,
          decoded.payload.remoteUserId,
        );
        this.props.onOffer(
          {
            userId: decoded.payload.remoteUserId,
            server: decoded.payload.remoteUserServer,
            token: "TODO - token",
          },
          decoded.payload.remoteConnectionAttemptId,
          decoded.payload.offer,
        );
      } else if (
        isMessageType(Netcode.messageNumbers.iceNegotiationFromServerToConnectedUser, decoded)
      ) {
        console.log("iceNegotiationFromServerToConnectedUser", decoded);
        this.props.onCandidate(
          this.remoteConnectionAttemptIdToUserId.get(decoded.payload.remoteConnectionAttemptId)!,
          decoded.payload.remoteConnectionAttemptId,
          decoded.payload.ice,
        );
      }
    });
  }

  updateDisplayName(displayName: string) {
    this.displayName = displayName;
    if (this.connectionOpened) {
      this.ws.send(
        encodeMessage(Netcode.messageNumbers.updateUser, {
          name: this.displayName,
        }),
      );
    } else {
      // Haven't opened the connection yet so just overwrite the display name and it will be sent as the first message
    }
  }

  close() {
    this.ws.close();
  }

  // private acceptOffer(
  //   remoteConnectionAttemptId: number,
  //   remoteUserId: number,
  //   remoteUserServer: string,
  //   offer: string,
  // ) {
  //   console.log("acceptOffer", remoteConnectionAttemptId, remoteUserId, offer);
  //   const connectionAttemptContext = this.remoteConnectionAttempts[remoteConnectionAttemptId];
  //   if (!connectionAttemptContext) {
  //     throw new Error(`User context not found for id: ${remoteConnectionAttemptId}`);
  //   }
  //   if (connectionAttemptContext.incomingConnectionAttempt) {
  //     // TODO - should not already be present if receiving an offer
  //     throw new Error("Received offer for an existing connection attempt");
  //   } else {
  //     console.log("UserContext.handleOffer", offer);
  //     connectionAttemptContext.incomingConnectionAttempt.handleOffer(JSON.parse(offer));
  //
  //     // Use existing candidates that have been sent
  //     for (const candidate of connectionAttemptContext.iceCandidates) {
  //       console.log("UserContext.addingQueuedIceCandidate", candidate);
  //       connectionAttemptContext.incomingConnectionAttempt.addIceCandidate(candidate);
  //     }
  //   }
  // }
  //
  // private initialOfferFromServerToConnectedUser(
  //   payload: Netcode.MessagePayloads[Netcode.messageNumbers.initialOfferFromServerToConnectedUser],
  // ) {
  //   const remoteConnectionAttemptId = payload.remoteConnectionAttemptId;
  //   const connectionAttemptContext: ConnectionAttemptContext = {
  //     remoteConnectionAttemptId,
  //     iceCandidates: [],
  //   };
  //   this.remoteConnectionAttempts[remoteConnectionAttemptId] = connectionAttemptContext;
  //
  //   if (this.props.shouldAcceptIncomingOffer(payload.remoteUserId)) {
  //     this.acceptOffer(
  //       payload.remoteConnectionAttemptId,
  //       payload.remoteUserId,
  //       payload.remoteUserServer,
  //       payload.offer,
  //     );
  //   }
  // }
  //
  // private iceNegotiationFromServerToConnectedUser(
  //   payload: Netcode.MessagePayloads[Netcode.messageNumbers.iceNegotiationFromServerToConnectedUser],
  // ) {
  //   const connectionAttemptContext =
  //     this.remoteConnectionAttempts[payload.remoteConnectionAttemptId];
  //   console.log(
  //     "iceNegotiationFromServerToConnectedUser",
  //     payload,
  //     "context",
  //     connectionAttemptContext,
  //   );
  //   if (!connectionAttemptContext) {
  //     throw new Error(`User context not found for id: ${payload.remoteConnectionAttemptId}`);
  //   }
  //
  //   if (connectionAttemptContext.incomingConnectionAttempt) {
  //     const parsedIce = JSON.parse(payload.ice);
  //     connectionAttemptContext.incomingConnectionAttempt.addIceCandidate(parsedIce);
  //   } else {
  //     if (connectionAttemptContext.connection) {
  //       console.warn(
  //         "iceNegotiationFromServerToConnectedUser - received ice candidate after connection established",
  //       );
  //       return;
  //     }
  //     console.log("Queueing up ice candidate", payload.ice);
  //     connectionAttemptContext.iceCandidates.push(payload.ice);
  //   }
  // }

  sendAnswer(remoteConnectionAttemptId: number, answer: string) {
    this.ws.send(
      encodeMessage(Netcode.messageNumbers.initialAnswerFromConnectedUserToServer, {
        remoteConnectionAttemptId,
        answer,
      }),
    );
  }

  sendCandidate(remoteConnectionAttemptId: number, ice: string) {
    this.ws.send(
      encodeMessage(Netcode.messageNumbers.iceNegotiationFromConnectedUserToServer, {
        remoteConnectionAttemptId,
        ice,
      }),
    );
  }
}
