import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { GroupManager } from "../group-manager/GroupManager";
import { GroupElement } from "./Group";
import styles from "./GroupList.module.css";
import { InvitedGroupElement } from "./InvitedGroup";
import { InviteModalElement } from "./InviteModalElement";

export function GroupsPanel(props: {
  ownUserId: number;
  ownDisplayName: string;
  currentUrl: string;
  scriptSrc: string;
  groupManager: GroupManager;
}) {
  return (
    <div className={styles.groupList}>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className={styles.createGroupSection}>
                <button
                  className={styles.createGroupButton}
                  onClick={() => {
                    props.groupManager.createNewGroup();
                  }}
                >
                  Create Group
                </button>
              </div>
              {props.groupManager.receivedInvites.size > 0 && (
                <div className={styles.invitedGroupsSection}>
                  <div className={styles.sectionHeader}>Invites</div>
                  {Array.from(props.groupManager.receivedInvites).map(([, invitedGroup]) => {
                    return (
                      <InvitedGroupElement
                        key={invitedGroup.groupId}
                        invitedGroup={invitedGroup}
                        groupManager={props.groupManager}
                      />
                    );
                  })}
                </div>
              )}
              <div className={styles.sectionHeader}>Groups</div>
              <div className={styles.groupsSection}>
                {props.groupManager.groups.size > 0 ? (
                  Array.from(props.groupManager.groups).map(([, group]) => {
                    return (
                      <GroupElement
                        key={group.groupId}
                        ownUserId={props.ownUserId}
                        ownDisplayName={props.ownDisplayName}
                        currentUrl={props.currentUrl}
                        scriptSrc={props.scriptSrc}
                        group={group}
                        groupManager={props.groupManager}
                      />
                    );
                  })
                ) : (
                  <div className={styles.noGroupsMessage}>No groups</div>
                )}
              </div>
            </>
          }
        />
        <Route
          path={":groupId/invite"}
          element={<InviteModalElement groupManager={props.groupManager} />}
        />
      </Routes>
    </div>
  );
}
