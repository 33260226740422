import { MessagingIframe } from "@meta-web/messaging-iframe-helper";

import {
  GlobalToLocalPostMessage,
  LocalToGlobalPostMessage,
  RemoteEvent,
  RPCResponse,
} from "./postMessages";
import { createRpcClient, ServiceDefinition, ServiceMethodMap } from "./rpc";

export class CrossDomainRPCClient<SD extends ServiceDefinition> {
  public readonly rpc: ServiceMethodMap<SD>;

  public messagingIframe: MessagingIframe<GlobalToLocalPostMessage, LocalToGlobalPostMessage>;
  private rpcCounter = 1;
  private responseMap = new Map<
    number,
    { resolve: (value: unknown) => void; reject: (reason?: unknown) => void }
  >();
  private queuedMessages: Array<LocalToGlobalPostMessage> | null = [];
  private eventHandler?: (event: unknown) => void;

  constructor(
    remotePageUrl: string,
    serviceDefinition: SD,
    eventHandler?: (event: unknown) => void,
    htmlIframeElement?: HTMLIFrameElement,
  ) {
    this.messagingIframe = htmlIframeElement
      ? new MessagingIframe(htmlIframeElement, remotePageUrl, (msg: GlobalToLocalPostMessage) => {
          this.handleMessage(msg);
        })
      : MessagingIframe.CreateAndAppendToBody(remotePageUrl, (msg: GlobalToLocalPostMessage) => {
          this.handleMessage(msg);
        });
    this.rpc = createRpcClient(
      {
        call: (method: string, req: unknown) => {
          const rpcId = this.rpcCounter++;
          return new Promise<unknown>((resolve, reject) => {
            this.responseMap.set(rpcId, { resolve, reject });
            const msg = {
              rpcRequest: {
                rpcId,
                method,
                data: req,
              },
            };
            if (this.queuedMessages !== null) {
              this.queuedMessages.push(msg);
            } else {
              this.messagingIframe.sendMessage(msg);
            }
          });
        },
      },
      serviceDefinition,
    );
    this.eventHandler = eventHandler;
  }

  close() {
    this.messagingIframe.close();
  }

  private handleMessage(msg: GlobalToLocalPostMessage) {
    if (msg.didLoad) {
      const queuedMessages = this.queuedMessages;
      if (queuedMessages === null) {
        return;
      }
      this.queuedMessages = null;
      for (const msg of queuedMessages) {
        this.messagingIframe.sendMessage(msg);
      }
    } else if (msg.rpcResponse) {
      this.handleRPCResponse(msg.rpcResponse);
    } else if (msg.remoteEvent) {
      this.handleRemoteEvent(msg.remoteEvent);
    }
  }

  private handleRPCResponse(rpcResponse: RPCResponse) {
    const { rpcId, data } = rpcResponse;
    const getPromise = this.responseMap.get(rpcId);
    if (!getPromise) {
      throw new Error("Received response for missing RPC id");
    }
    getPromise.resolve(data);
  }

  private handleRemoteEvent(remoteEvent: RemoteEvent) {
    if (!this.eventHandler) {
      console.warn("No event handler for incoming events", remoteEvent.data);
    } else {
      this.eventHandler(remoteEvent.data);
    }
  }
}
