
const digest = 'e27a5ddd66b922118473faca212f33dd54a48c3a9945bc658cbcbe33c5605d0a';
const css = `._tabs_1w9xi_1 {
    flex-grow: 1;
    width: 100%;
    text-align: center;
    margin-top: 16px;
    z-index: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

._tab-title-holder_1w9xi_13 {
    flex-grow: 0;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 20px;
    background: #F2F2F2;
    cursor: pointer;
}

._tab_1w9xi_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    margin: 2px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
}

._tab-contents_1w9xi_36 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    width: 100%;
    text-align: left;
}

._active-tab_1w9xi_46 {
    font-weight: bold;
    background: #FFFFFF;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"tabs":"_tabs_1w9xi_1","tab-title-holder":"_tab-title-holder_1w9xi_13","tabTitleHolder":"_tab-title-holder_1w9xi_13","tab":"_tab_1w9xi_1","tab-contents":"_tab-contents_1w9xi_36","tabContents":"_tab-contents_1w9xi_36","active-tab":"_active-tab_1w9xi_46","activeTab":"_active-tab_1w9xi_46"};
export { css, digest };
  