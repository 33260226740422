
const digest = '83699c44c65edec82250bc9c16fbef2e0a235b4660ec0496aa6e755812bb187a';
const css = `._tabs_1rijd_1 {

}

._tab-title-holder_1rijd_5 {
    display: flex;
}

._tab_1rijd_1 {
    color: black;
    cursor: pointer;
}

._active-tab_1rijd_14 {
    color: blue;
}

._tab-contents_1rijd_18 {

}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"tabs":"_tabs_1rijd_1","tabTitleHolder":"_tab-title-holder_1rijd_5","tab":"_tab_1rijd_1","activeTab":"_active-tab_1rijd_14","tabContents":"_tab-contents_1rijd_18"};
export { css, digest };
  