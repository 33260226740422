import * as React from "react";
import { useState } from "react";

import { autoJoinParamName } from "../../common/constants";
import { getDefaultSignallingServerHost } from "../../host";
import styles from "./MyIdentityPanel.module.css";
import { PeerIcon } from "./PeerList/PeerIcon/PeerIcon";

export function MyIdentityPanel(props: {
  id: number;
  username: string;
  onSetUsername: (username: string) => void;
  scriptSrc: string;
  currentUrl: string;
}) {
  const [newUsername, setNewUsername] = useState(props.username);

  return (
    <div className={styles.myIdentityPanel}>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          props.onSetUsername(newUsername);
        }}
      >
        <PeerIcon userId={props.id} online={true} />
        <input
          className={styles.usernameInput}
          autoFocus
          type={"text"}
          value={newUsername}
          placeholder={"Username"}
          required={true}
          pattern={"[a-zA-Z0-9-_ ]+"}
          onChange={(e) => setNewUsername(e.target.value)}
        />
        {props.username !== newUsername && (
          <button className={styles.button} onClick={() => props.onSetUsername(newUsername)}>
            Set
          </button>
        )}
      </form>
      <button
        className={styles.button}
        onClick={() => {
          const url = new URL(props.currentUrl);
          // Wholly replace the search params (to avoid accidentally leaking keys or similar)
          const postSearchParams = new URLSearchParams();
          postSearchParams.set("scriptsV0", props.scriptSrc);
          postSearchParams.set(
            autoJoinParamName,
            `${getDefaultSignallingServerHost()}?${props.id}`,
          );
          url.search = postSearchParams.toString();
          const joinLink = url.href;
          console.warn("joinLink", joinLink);
          navigator.clipboard.writeText(joinLink).then(
            () => {
              console.log("Did copy to clipboard");
            },
            (err) => {
              console.error("Failed to copy to clipboard", err);
            },
          );
        }}
      >
        Copy Join Link
      </button>
    </div>
  );
}
