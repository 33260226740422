const thisScript = document.currentScript as HTMLScriptElement;
const thisScriptSrcURL = new URL(thisScript.src);
const devWebsocketPort = 12121;

export function getDefaultSignallingServerHost(): string {
  if (process.env.DEV) {
    return `${thisScriptSrcURL.protocol === "https" ? "wss:" : "ws:"}//${
      thisScriptSrcURL.hostname
    }:${devWebsocketPort}`;
  }
  // TODO - use an environment-based hostname
  return "wss://someserver.metaweb.host:12121";
}
