
const digest = '76a682e236b5294e02e3e10ccb3d0a22ad1e505d656227be2488cb338429ba5e';
const css = `._peer-group-member_1ea5n_1 {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    align-items: center;
}

._peer-name_1ea5n_8 {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    padding: 4px;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"peer-group-member":"_peer-group-member_1ea5n_1","peerGroupMember":"_peer-group-member_1ea5n_1","peer-name":"_peer-name_1ea5n_8","peerName":"_peer-name_1ea5n_8"};
export { css, digest };
  