import { SerializedGroup } from "./Group";
import { GroupMember } from "./GroupMember";

export class InvitedGroup {
  public readonly groupId: number;
  public readonly inviterUserId: number;
  public members = new Map<number, GroupMember>();

  constructor(inviterUserId: number, serializedGroup: SerializedGroup) {
    this.inviterUserId = inviterUserId;
    this.groupId = serializedGroup.groupId;

    this.applySerializedGroup(serializedGroup);
  }

  applySerializedGroup(serializedGroup: SerializedGroup) {
    for (const member of serializedGroup.members) {
      const existing = this.members.get(member.userId);
      if (!existing) {
        const groupMember = new GroupMember(member.userId, member.userOwnCounter);
        this.members.set(member.userId, groupMember);
      }
    }
  }
}
