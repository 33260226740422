import { KnownPeer, knownPeersEqual } from "../postMessages";

const localStorageIdentityKey = "peer-social-identity";

function generateRandomId(): number {
  return Math.ceil(Math.random() * Number.MAX_SAFE_INTEGER - 1) + 1;
}

function generateRandomName(): string {
  const words = {
    objects: [
      "Aardvark",
      "Aardwolf",
      "Abacus",
      "Abrosaurus",
      "Acai",
      "Acapella",
      "Accelerator",
      "Accordion",
      "Account",
      "Achillobator",
      "Acorn",
      "Acoustic",
      "Acrylic",
      "Act",
      "Action",
      "Activity",
      "Actor",
      "Actress",
      "Adapter",
      "Adasaurus",
      "Addition",
      "Address",
      "Adjustment",
      "Advantage",
      "Aerosteon",
      "Afrovenator",
      "Aftermath",
      "Afternoon",
      "Aftershave",
      "Afterthought",
      "Agate",
      "Age",
      "Agenda",
      "Agreement",
      "Agustinia",
      "Air",
      "Airboat",
      "Airbus",
      "Airedale",
      "Airmail",
      "Airplane",
      "Airport",
      "Airship",
      "Akubra",
      "Alamosaurus",
      "Alarm",
      "Albacore",
      "Albatross",
      "Albertonykus",
      "Albertosaurus",
      "Alder",
      "Aletopelta",
      "Alfalfa",
      "Algebra",
      "Alibi",
      "Alley",
      "Alligator",
      "Allium",
      "Allosaurus",
      "Alloy",
      "Allspice",
      "Almanac",
      "Almandine",
      "Almond",
      "Alpaca",
      "Alphabet",
      "Alto",
      "Aluminum",
      "Alvarezsaurus",
      "Alyssum",
      "Amaranthus",
      "Amaryllis",
      "Amazonsaurus",
      "Amber",
      "Ambert",
      "Ambulance",
      "Amethyst",
      "Amount",
      "Amphibian",
      "Amusement",
      "Anaconda",
      "Anatosaurus",
      "Anchovy",
      "Andesaurus",
      "Anemone",
      "Angelfish",
      "Anger",
      "Angle",
      "Anglerfish",
      "Angolatitan",
      "Angora",
      "Animal",
      "Animantarx",
      "Anise",
      "Ankle",
      "Ankylosaurus",
      "Anorak",
      "Answer",
      "Ant",
      "Antarctopelta",
      "Anteater",
      "Antelope",
      "Anthropology",
      "Antimatter",
      "Antimony",
      "Antique",
      "Antler",
      "Antlion",
      "Apartment",
      "Apatosaurus",
      "Aphid",
      "Apogee",
      "Apology",
      "Appalachiosaurus",
      "Apparatus",
      "Apparel",
      "Appeal",
      "Appendix",
      "Apple",
      "Appliance",
      "Approach",
      "Approval",
      "Apricot",
      "April",
      "Aquarius",
      "Aragon",
      "Aragosaurus",
      "Arch",
      "Archaeology",
      "Archaeopteryx",
      "Archduchess",
      "Archduke",
      "Archeology",
      "Archer",
      "Area",
      "Argon",
      "Argument",
      "Aries",
      "Aristosuchus",
      "Arithmetic",
      "Armadillo",
      "Armchair",
      "Army",
      "Arrhinceratops",
      "Arrow",
      "Art",
      "Arthropod",
      "Artichoke",
      "Ash",
      "Asiago",
      "Asp",
      "Asparagus",
      "Aspen",
      "Asphalt",
      "Aster",
      "Asterisk",
      "Asteroid",
      "Astrodon",
      "Astronaut",
      "Astronomy",
      "Athlete",
      "Atlasaurus",
      "Atmosphere",
      "Atom",
      "Atrociraptor",
      "Attack",
      "Attempt",
      "Attention",
      "Attic",
      "Attraction",
      "August",
      "Aunt",
      "Aura",
      "Aurora",
      "Auroraceratops",
      "Author",
      "Authority",
      "Authorization",
      "Avatar",
      "Avenue",
      "Avocado",
      "Axolotl",
      "Azimuth",
      "Babcat",
      "Baboon",
      "Backbone",
      "Background",
      "Backpack",
      "Bacon",
      "Bactrosaurus",
      "Badge",
      "Badger",
      "Bag",
      "Bagel",
      "Bagpipe",
      "Bait",
      "Baker",
      "Bakery",
      "Balance",
      "Balaur",
      "Ball",
      "Ballcap",
      "Balloon",
      "Balmoral",
      "Bambiraptor",
      "Bamboo",
      "Banana",
      "Band",
      "Bandana",
      "Bandicoot",
      "Bangle",
      "Banjo",
      "Bank",
      "Banon",
      "Bar",
      "Barber",
      "Barberry",
      "Bard",
      "Barge",
      "Baritone",
      "Barnacle",
      "Barometer",
      "Baron",
      "Baroness",
      "Barracuda",
      "Baryonyx",
      "Base",
      "Baseball",
      "Basement",
      "Basil",
      "Basilisk",
      "Basin",
      "Basket",
      "Basketball",
      "Bass",
      "Bassoon",
      "Bat",
      "Bath",
      "Bathroom",
      "Bathtub",
      "Battery",
      "Bay",
      "Bayberry",
      "Beach",
      "Bead",
      "Beak",
      "Beam",
      "Bean",
      "Beanie",
      "Bear",
      "Bearberry",
      "Beard",
      "Bearskin",
      "Beast",
      "Beat",
      "Beaufort",
      "Beauty",
      "Becklespinax",
      "Bed",
      "Bedbug",
      "Bee",
      "Beech",
      "Beechnut",
      "Beef",
      "Beet",
      "Beetle",
      "Beginner",
      "Begonia",
      "Behavior",
      "Belief",
      "Bell",
      "Bellflower",
      "Bellusaurus",
      "Belly",
      "Belt",
      "Beluga",
      "Bench",
      "Beret",
      "Bergamot",
      "Berry",
      "Beryl",
      "Beryllium",
      "Bestseller",
      "Bicycle",
      "Bike",
      "Bill",
      "Billboard",
      "Binder",
      "Biology",
      "Biplane",
      "Birch",
      "Bird",
      "Birth",
      "Birthday",
      "Bismuth",
      "Bison",
      "Bit",
      "Bite",
      "Bittersweet",
      "Blackberry",
      "Blackbird",
      "Blackcurrant",
      "Bladder",
      "Blade",
      "Blanket",
      "Blarney",
      "Blender",
      "Blinker",
      "Blizzard",
      "Blob",
      "Block",
      "Bloom",
      "Blossom",
      "Blouse",
      "Blue",
      "Bluebell",
      "Boa",
      "Boar",
      "Board",
      "Boat",
      "Boater",
      "Bobcat",
      "Bobolink",
      "Bolt",
      "Bone",
      "Bongo",
      "Bonnet",
      "Bonobo",
      "Bonsai",
      "Book",
      "Bookcase",
      "Booklet",
      "Boot",
      "Booth",
      "Border",
      "Borogovia",
      "Boron",
      "Bosworth",
      "Botany",
      "Bottle",
      "Bottom",
      "Bougon",
      "Boursin",
      "Bovid",
      "Bow",
      "Bowl",
      "Bowler",
      "Box",
      "Boysenberry",
      "Brace",
      "Brachiosaurus",
      "Bracket",
      "Braid",
      "Brain",
      "Brake",
      "Bramble",
      "Branch",
      "Brand",
      "Brass",
      "Brazil",
      "Bread",
      "Breadfruit",
      "Break",
      "Breakfast",
      "Bream",
      "Breath",
      "Breeze",
      "Brian",
      "Brick",
      "Bridge",
      "Brie",
      "Brisket",
      "Broccoli",
      "Brochure",
      "Broker",
      "Bromine",
      "Brontomerus",
      "Bronze",
      "Brook",
      "Broom",
      "Brother",
      "Brow",
      "Brownie",
      "Browser",
      "Brush",
      "Bubble",
      "Bubbler",
      "Buckaroo",
      "Bucket",
      "Buckthorn",
      "Buckthornpepperberry",
      "Budget",
      "Buffalo",
      "Buffer",
      "Buffet",
      "Bug",
      "Bugle",
      "Building",
      "Bulb",
      "Bull",
      "Bumper",
      "Bun",
      "Bunny",
      "Burglar",
      "Burn",
      "Burrito",
      "Burst",
      "Bus",
      "Busby",
      "Bush",
      "Butter",
      "Buttercup",
      "Butterfly",
      "Butterkase",
      "Butternut",
      "Button",
      "Buzzard",
      "Cabbage",
      "Cabin",
      "Cabinet",
      "Caboc",
      "Cacao",
      "Cactus",
      "Cadet",
      "Cadmium",
      "Cafe",
      "Cairnsmore",
      "Cake",
      "Calcium",
      "Calculator",
      "Calendula",
      "Calf",
      "Calliandra",
      "Camel",
      "Camelotia",
      "Camera",
      "Camp",
      "Can",
      "Canary",
      "Candle",
      "Candy",
      "Candytuft",
      "Canid",
      "Canidae",
      "Cannon",
      "Canoe",
      "Canopy",
      "Cantaloupe",
      "Canvas",
      "Cap",
      "Capacity",
      "Caper",
      "Cappelletti",
      "Capri",
      "Capricorn",
      "Captain",
      "Caption",
      "Capybara",
      "Car",
      "Caravan",
      "Caravel",
      "Caraway",
      "Carbon",
      "Card",
      "Cardamom",
      "Cardboard",
      "Cardigan",
      "Cardinal",
      "Care",
      "Caribou",
      "Carnation",
      "Carob",
      "Carol",
      "Carp",
      "Carpenter",
      "Carpet",
      "Carriage",
      "Carrot",
      "Cart",
      "Cartoon",
      "Cartwheel",
      "Carver",
      "Case",
      "Cashew",
      "Casquette",
      "Cast",
      "Castanet",
      "Cat",
      "Catamaran",
      "Catboat",
      "Catcher",
      "Caterpillar",
      "Catfish",
      "Cathedral",
      "Catmint",
      "Catshark",
      "Catsup",
      "Cattle",
      "Cauliflower",
      "Cause",
      "Caution",
      "Cave",
      "Cayenne",
      "Ceder",
      "Ceiling",
      "Celery",
      "Celestite",
      "Cell",
      "Cellar",
      "Cello",
      "Celsius",
      "Cement",
      "Cemetery",
      "Cent",
      "Centaur",
      "Centipede",
      "Century",
      "Cephalopod",
      "Ceramic",
      "Ceratonykus",
      "Cereal",
      "Ceres",
      "Cerise",
      "Cesium",
      "Chair",
      "Chalk",
      "Challenge",
      "Chameleon",
      "Chamomile",
      "Chance",
      "Change",
      "Channel",
      "Chanter",
      "Character",
      "Chard",
      "Charger",
      "Chartreuse",
      "Chasmosaurus",
      "Chatter",
      "Chauffeur",
      "Check",
      "Cheddar",
      "Cheek",
      "Cheese",
      "Cheetah",
      "Chef",
      "Chemistry",
      "Cheque",
      "Cherry",
      "Cheshire",
      "Chess",
      "Chestnut",
      "Chevre",
      "Chickadee",
      "Chicken",
      "Chicory",
      "Child",
      "Chili",
      "Chill",
      "Chiller",
      "Chime",
      "Chimpanzee",
      "Chinchilla",
      "Chip",
      "Chipmunk",
      "Chips",
      "Chive",
      "Chocolate",
      "Chokeberry",
      "Chopper",
      "Chord",
      "Christmas",
      "Chronometer",
      "Chrysanthemum",
      "Chungkingosaurus",
      "Church",
      "Cicada",
      "Cilantro",
      "Cinema",
      "Cinnamon",
      "Circle",
      "Cirrus",
      "Citipati",
      "Citrine",
      "Citron",
      "Citrus",
      "City",
      "Clam",
      "Clarinet",
      "Class",
      "Clave",
      "Clavicle",
      "Clef",
      "Clematis",
      "Clementine",
      "Clerk",
      "Click",
      "Client",
      "Climb",
      "Clipper",
      "Cloak",
      "Cloche",
      "Clock",
      "Close",
      "Closet",
      "Cloth",
      "Cloud",
      "Cloudberry",
      "Clove",
      "Clover",
      "Clownfish",
      "Club",
      "Clutch",
      "Coach",
      "Coal",
      "Coast",
      "Coaster",
      "Coat",
      "Cobalt",
      "Cobbler",
      "Cobra",
      "Cobweb",
      "Cockatoo",
      "Cockroach",
      "Cocoa",
      "Coconut",
      "Cod",
      "Coelurus",
      "Coffee",
      "Coil",
      "Coin",
      "Colby",
      "Cold",
      "Collar",
      "Collarbone",
      "Collard",
      "College",
      "Collision",
      "Colony",
      "Color",
      "Colossus",
      "Colt",
      "Column",
      "Columnist",
      "Comb",
      "Comet",
      "Comfort",
      "Comic",
      "Comma",
      "Composer",
      "Comte",
      "Concavenator",
      "Conchoraptor",
      "Concrete",
      "Condition",
      "Condor",
      "Condorraptor",
      "Cone",
      "Coneflower",
      "Confidence",
      "Conga",
      "Conifer",
      "Conkerberry",
      "Consonant",
      "Constellation",
      "Constrictor",
      "Continent",
      "Contraption",
      "Cook",
      "Cookie",
      "Copper",
      "Coral",
      "Cord",
      "Coreopsis",
      "Coriander",
      "Cork",
      "Corleggy",
      "Cormorant",
      "Corn",
      "Cornet",
      "Cornflower",
      "Cornucopia",
      "Cosmonaut",
      "Cosmos",
      "Cost",
      "Cotija",
      "Cotton",
      "Couch",
      "Cougar",
      "Cough",
      "Count",
      "Countess",
      "Country",
      "Course",
      "Court",
      "Cousin",
      "Cover",
      "Cow",
      "Cowbell",
      "Cowl",
      "Coyote",
      "Crab",
      "Crabapple",
      "Crafter",
      "Crane",
      "Crate",
      "Crater",
      "Crawdad",
      "Crayfish",
      "Crayon",
      "Cream",
      "Creator",
      "Creature",
      "Credit",
      "Creek",
      "Crepe",
      "Cress",
      "Crest",
      "Crib",
      "Cricket",
      "Crime",
      "Crocodile",
      "Crocus",
      "Croissant",
      "Crop",
      "Cross",
      "Crow",
      "Crowberry",
      "Crowd",
      "Crowley",
      "Crown",
      "Cruiser",
      "Crush",
      "Crustacean",
      "Cry",
      "Crystal",
      "Cub",
      "Cuckoo",
      "Cucumber",
      "Culotte",
      "Cup",
      "Cupboard",
      "Cupcake",
      "Curio",
      "Curiosity",
      "Curler",
      "Currant",
      "Currency",
      "Curtain",
      "Curve",
      "Cushion",
      "Custard",
      "Cut",
      "Cuticle",
      "Cyclamen",
      "Cycle",
      "Cyclone",
      "Cylinder",
      "Cymbal",
      "Daemonosaurus",
      "Daffodil",
      "Dahlia",
      "Daisy",
      "Damselfly",
      "Dance",
      "Dancer",
      "Dandelion",
      "Danger",
      "Danthus",
      "Daphne",
      "Darkness",
      "Dart",
      "Dash",
      "Date",
      "Daughter",
      "Dawn",
      "Day",
      "Dead",
      "Deal",
      "Death",
      "Debt",
      "Decade",
      "Decimal",
      "Decision",
      "Dedication",
      "Deer",
      "Deerstalker",
      "Degree",
      "Delivery",
      "Delphinium",
      "Deltadromeus",
      "Den",
      "Denim",
      "Dentist",
      "Deposit",
      "Derby",
      "Desert",
      "Desk",
      "Dessert",
      "Detail",
      "Detective",
      "Devourer",
      "Dew",
      "Dewberry",
      "Diabloceratops",
      "Diadem",
      "Diagnostic",
      "Diagram",
      "Diamond",
      "Dianella",
      "Diascia",
      "Dibble",
      "Dichondra",
      "Dietician",
      "Digestion",
      "Digit",
      "Dill",
      "Dilophosaurus",
      "Dime",
      "Dimple",
      "Dinghy",
      "Dingo",
      "Dinner",
      "Dinosaur",
      "Diplodocus",
      "Diploma",
      "Direction",
      "Dirigible",
      "Dirt",
      "Discovery",
      "Dish",
      "Distance",
      "Divan",
      "Dive",
      "Diver",
      "Dodo",
      "Dog",
      "Dogsled",
      "Dogwood",
      "Doll",
      "Dollar",
      "Dolomite",
      "Dolphin",
      "Domain",
      "Donkey",
      "Donut",
      "Doom",
      "Door",
      "Dormouse",
      "Dosa",
      "Double",
      "Dove",
      "Dracopelta",
      "Dracorex",
      "Dragon",
      "Dragonfly",
      "Dragonfruit",
      "Drain",
      "Draw",
      "Drawbridge",
      "Drawer",
      "Dreadnought",
      "Dream",
      "Dress",
      "Dresser",
      "Drifter",
      "Drill",
      "Drink",
      "Drip",
      "Drive",
      "Driver",
      "Drizzle",
      "Drop",
      "Droplet",
      "Drug",
      "Drum",
      "Dry",
      "Dryer",
      "Dryosaurus",
      "Duchess",
      "Duck",
      "Duckling",
      "Dugong",
      "Dugout",
      "Duke",
      "Dumpling",
      "Dungeon",
      "Durian",
      "Dust",
      "Eagle",
      "Ear",
      "Earl",
      "Earth",
      "Earthquake",
      "Earthworm",
      "Earwig",
      "Ease",
      "Eater",
      "Echidna",
      "Echinacea",
      "Echinodon",
      "Echium",
      "Echo",
      "Eclipse",
      "Edam",
      "Edge",
      "Editor",
      "Editorial",
      "Education",
      "Eel",
      "Effect",
      "Efraasia",
      "Egg",
      "Eggnog",
      "Eggplant",
      "Egret",
      "Elbow",
      "Elderberry",
      "Element",
      "Elephant",
      "Elf",
      "Elk",
      "Ellipse",
      "Elm",
      "Emery",
      "Emmental",
      "Emoji",
      "Emperor",
      "Empress",
      "Emu",
      "End",
      "Enemy",
      "Energy",
      "Engine",
      "Engineer",
      "English",
      "Enigmosaurus",
      "Enquiry",
      "Enthusiasm",
      "Entrance",
      "Entree",
      "Environment",
      "Eocursor",
      "Eoraptor",
      "Epoch",
      "Epoxy",
      "Equinox",
      "Equipment",
      "Era",
      "Erica",
      "Erigeron",
      "Eris",
      "Ermine",
      "Error",
      "Espadrille",
      "Estimate",
      "Ethernet",
      "Eucalyptus",
      "Euphonium",
      "Eustoma",
      "Evening",
      "Event",
      "Evergreen",
      "Exception",
      "Exhaust",
      "Existence",
      "Exoplanet",
      "Expansion",
      "Experience",
      "Expert",
      "Explanation",
      "Eye",
      "Eyebrow",
      "Eyelash",
      "Eyeliner",
      "Fabrosaurus",
      "Face",
      "Fact",
      "Factory",
      "Fahrenheit",
      "Failing",
      "Fairy",
      "Falcon",
      "Fall",
      "Family",
      "Fan",
      "Fang",
      "Farm",
      "Farmer",
      "Farmhouse",
      "Fascinator",
      "Father",
      "Faucet",
      "Fear",
      "Feast",
      "Feather",
      "Fedora",
      "Feeling",
      "Feels",
      "Feet",
      "Felidae",
      "Femur",
      "Fender",
      "Fennel",
      "Fenugreek",
      "Fern",
      "Fernleaf",
      "Ferret",
      "Ferry",
      "Ferryboat",
      "Feta",
      "Feverfew",
      "Fiber",
      "Fibre",
      "Fibula",
      "Fiction",
      "Field",
      "Fifth",
      "Fig",
      "Fight",
      "Fighter",
      "Filament",
      "File",
      "Fin",
      "Finch",
      "Find",
      "Fine",
      "Fir",
      "Fire",
      "Firefly",
      "Fireman",
      "Fireplace",
      "Fireplant",
      "Firewall",
      "Fish",
      "Fisher",
      "Fisherman",
      "Flag",
      "Flame",
      "Flamingo",
      "Flannel",
      "Flare",
      "Flat",
      "Flavor",
      "Flax",
      "Flea",
      "Flier",
      "Flight",
      "Flock",
      "Flood",
      "Floor",
      "Floss",
      "Flower",
      "Fluorine",
      "Flute",
      "Fly",
      "Flyaway",
      "Flyingfish",
      "Foam",
      "Foe",
      "Fog",
      "Fold",
      "Fontina",
      "Food",
      "Football",
      "Force",
      "Forest",
      "Forger",
      "Forgery",
      "Fork",
      "Form",
      "Forsythia",
      "Fortnight",
      "Fossa",
      "Foundation",
      "Fountain",
      "Fowl",
      "Fox",
      "Foxglove",
      "Foxtail",
      "Foxtrot",
      "Fragrance",
      "Frame",
      "Freckle",
      "Freedom",
      "Freesia",
      "Freeze",
      "Freezer",
      "Freighter",
      "Freon",
      "Friction",
      "Friday",
      "Fridge",
      "Friend",
      "Frigate",
      "Fright",
      "Frill",
      "Frog",
      "Front",
      "Frost",
      "Frown",
      "Fruit",
      "Fruitadens",
      "Fuchsia",
      "Fuel",
      "Fukuiraptor",
      "Fur",
      "Furniture",
      "Galaxy",
      "Galette",
      "Galleon",
      "Galley",
      "Galliform",
      "Gallimimus",
      "Gallium",
      "Gallon",
      "Gambler",
      "Game",
      "Gander",
      "Garage",
      "Garden",
      "Gardenia",
      "Gargoyle",
      "Gargoyleosaurus",
      "Garlic",
      "Garment",
      "Garnet",
      "Gas",
      "Gasoline",
      "Gasosaurus",
      "Gastonia",
      "Gate",
      "Gateway",
      "Gatsby",
      "Gauge",
      "Gaura",
      "Gazelle",
      "Gear",
      "Gecko",
      "Geese",
      "Gem",
      "Gemini",
      "Geography",
      "Geology",
      "Geometry",
      "Geranium",
      "Gerbera",
      "Gerbil",
      "Germanium",
      "Ghost",
      "Ghoul",
      "Giant",
      "Gibbon",
      "Giganotosaurus",
      "Gigantoraptor",
      "Gigantspinosaurus",
      "Gilmoreosaurus",
      "Ginger",
      "Giraffatitan",
      "Giraffe",
      "Girdle",
      "Girl",
      "Giver",
      "Glade",
      "Gladiolus",
      "Glass",
      "Glasses",
      "Glazer",
      "Glider",
      "Glitter",
      "Globe",
      "Globeflower",
      "Glockenspiel",
      "Glove",
      "Glue",
      "Goal",
      "Goat",
      "Gojirasaurus",
      "Gold",
      "Goldenrod",
      "Goldfish",
      "Golf",
      "Gondola",
      "Gong",
      "Goose",
      "Gooseberry",
      "Gopher",
      "Gorgonzola",
      "Gorilla",
      "Gosling",
      "Gouda",
      "Gourd",
      "Governor",
      "Grade",
      "Grain",
      "Gram",
      "Grandiflora",
      "Grandparent",
      "Grape",
      "Grapple",
      "Grass",
      "Grasshopper",
      "Gravity",
      "Gray",
      "Grease",
      "Green",
      "Grenadilla",
      "Grey",
      "Griffin",
      "Grill",
      "Grin",
      "Grip",
      "Ground",
      "Group",
      "Grouse",
      "Growth",
      "Gruyere",
      "Guan",
      "Guanaco",
      "Guarantee",
      "Guardian",
      "Guava",
      "Guavaberry",
      "Guide",
      "Guilty",
      "Guitar",
      "Gull",
      "Gum",
      "Guppy",
      "Gym",
      "Gymnast",
      "Hacksaw",
      "Haddock",
      "Hadrosaurus",
      "Hail",
      "Hair",
      "Haircut",
      "Halibut",
      "Hall",
      "Halloumi",
      "Hallway",
      "Hamburger",
      "Hammer",
      "Hamster",
      "Handball",
      "Handbell",
      "Handle",
      "Handsaw",
      "Handspring",
      "Handstand",
      "Harbor",
      "Hardboard",
      "Hardcover",
      "Hardhat",
      "Hardware",
      "Hare",
      "Harmonica",
      "Harmony",
      "Harp",
      "Harpymimus",
      "Harrier",
      "Hat",
      "Haumea",
      "Havarti",
      "Hawk",
      "Hawthorn",
      "Haze",
      "Headlight",
      "Headline",
      "Headphones",
      "Headstand",
      "Healer",
      "Health",
      "Hearing",
      "Heart",
      "Heat",
      "Heath",
      "Heather",
      "Heaven",
      "Hedge",
      "Hedgehog",
      "Height",
      "Helenium",
      "Helicona",
      "Helicopter",
      "Heliotrope",
      "Helium",
      "Hell",
      "Hellebore",
      "Helmet",
      "Help",
      "Hemisphere",
      "Hen",
      "Henley",
      "Hero",
      "Heron",
      "Herring",
      "Hexagon",
      "Hibiscus",
      "Hickory",
      "Hide",
      "Hill",
      "Hip",
      "Hippodraco",
      "Hippopotamus",
      "Hiss",
      "Hisser",
      "Hockey",
      "Hole",
      "Holiday",
      "Holly",
      "Hollyhock",
      "Homburg",
      "Homegrown",
      "Honesty",
      "Honey",
      "Honeycrisp",
      "Honeycup",
      "Honeydew",
      "Honeysuckle",
      "Hoodie",
      "Hoof",
      "Hook",
      "Hope",
      "Horesradish",
      "Horn",
      "Hornet",
      "Horse",
      "Hortensia",
      "Hose",
      "Hospital",
      "Hosta",
      "Hotel",
      "Hound",
      "Hour",
      "Hourglass",
      "House",
      "Houseboat",
      "Hovercraft",
      "Hoverfly",
      "Howler",
      "Hub",
      "Hubcap",
      "Huckleberry",
      "Hugger",
      "Humerus",
      "Humidity",
      "Hummingbird",
      "Humor",
      "Hunter",
      "Hurricane",
      "Hyacinth",
      "Hydrangea",
      "Hydrant",
      "Hydrofoil",
      "Hydrogen",
      "Hyena",
      "Hygienic",
      "Hyssop",
      "Iberis",
      "Ice",
      "Icebreaker",
      "Icecream",
      "Icicle",
      "Icon",
      "Idea",
      "Iguana",
      "Iguanacolossus",
      "Iguanadon",
      "Iguanodon",
      "Impala",
      "Impatiens",
      "Impulse",
      "Inch",
      "Income",
      "Increase",
      "Index",
      "Indigo",
      "Individual",
      "Industry",
      "Infinity",
      "Ink",
      "Innocent",
      "Innovation",
      "Insect",
      "Inspiration",
      "Intelligence",
      "Interest",
      "Iodine",
      "Iridium",
      "Iris",
      "Iron",
      "Ironclad",
      "Island",
      "Isthmus",
      "Ixia",
      "Ixora",
      "Jaborosa",
      "Jackal",
      "Jacket",
      "Jackfruit",
      "Jackrabbit",
      "Jade",
      "Jaguar",
      "Jam",
      "Jar",
      "Jasmine",
      "Jasper",
      "Jaw",
      "Jaxartosaurus",
      "Jay",
      "Jeep",
      "Jelly",
      "Jellyfish",
      "Jersey",
      "Jester",
      "Jet",
      "Jewel",
      "Jingle",
      "Jitterbug",
      "Jobaria",
      "Jodhpur",
      "Joggers",
      "Join",
      "Joke",
      "Jonquil",
      "Journey",
      "Judge",
      "Judo",
      "Juice",
      "Jujube",
      "Jumbo",
      "Jump",
      "Jumper",
      "Jumpsuit",
      "Juniper",
      "Jupiter",
      "Juravenator",
      "Jury",
      "Justice",
      "Jute",
      "Kale",
      "Kangaroo",
      "Kayak",
      "Keeper",
      "Ketch",
      "Ketchup",
      "Kettle",
      "Kettledrum",
      "Key",
      "Keyboard",
      "Khaan",
      "Kick",
      "Kicker",
      "Kidney",
      "Kileskus",
      "Kilogram",
      "Kilometer",
      "King",
      "Kingfisher",
      "Kip",
      "Kiss",
      "Kitchen",
      "Kite",
      "Kitten",
      "Kitty",
      "Kiwi",
      "Knave",
      "Knee",
      "Knife",
      "Knight",
      "Knot",
      "Knuckle",
      "Koala",
      "Koi",
      "Kookaburra",
      "Kosmoceratops",
      "Krill",
      "Krypton",
      "Kryptops",
      "Kumquat",
      "Laborer",
      "Lace",
      "Lady",
      "Ladybug",
      "Lake",
      "Lamb",
      "Lamp",
      "Lamprey",
      "Lan",
      "Lancer",
      "Land",
      "Language",
      "Lantana",
      "Lantern",
      "Larch",
      "Lark",
      "Larkspur",
      "Lasagna",
      "Laser",
      "Latency",
      "Lathe",
      "Laugh",
      "Launch",
      "Laundry",
      "Lavender",
      "Law",
      "Lawyer",
      "Layer",
      "Lead",
      "Leader",
      "Leaf",
      "Learning",
      "Leather",
      "Leech",
      "Leek",
      "Legal",
      "Legend",
      "Legume",
      "Lemming",
      "Lemon",
      "Lemonade",
      "Lemongrass",
      "Lemur",
      "Lens",
      "Lentil",
      "Leo",
      "Leopard",
      "Leopon",
      "Leotard",
      "Leptoceratops",
      "Letter",
      "Lettuce",
      "Level",
      "Libra",
      "Library",
      "License",
      "Licorice",
      "Lifeboat",
      "Lift",
      "Lifter",
      "Light",
      "Lighter",
      "Lightning",
      "Lightyear",
      "Lilac",
      "Lillipilli",
      "Lily",
      "Limburger",
      "Lime",
      "Limit",
      "Limpet",
      "Line",
      "Linen",
      "Lingonberry",
      "Link",
      "Linseed",
      "Lint",
      "Lion",
      "Lip",
      "Lipstick",
      "Liquid",
      "List",
      "Literature",
      "Litter",
      "Liver",
      "Lizard",
      "Llama",
      "Loaf",
      "Loan",
      "Lobe",
      "Lobster",
      "Lock",
      "Locket",
      "Locust",
      "Lodge",
      "Log",
      "Loganberry",
      "Longan",
      "Longship",
      "Look",
      "Lord",
      "Lotus",
      "Louse",
      "Lumber",
      "Lunaria",
      "Lunch",
      "Lung",
      "Lunge",
      "Lungfish",
      "Lupin",
      "Lute",
      "Lychee",
      "Lycra",
      "Lynx",
      "Lyre",
      "Lyric",
      "Macadamia",
      "Macaroni",
      "Macaroon",
      "Macaw",
      "Machine",
      "Mackerel",
      "Macrame",
      "Magazine",
      "Magic",
      "Magician",
      "Magnesium",
      "Magnolia",
      "Magnosaurus",
      "Magpie",
      "Mahogany",
      "Maiasaura",
      "Mail",
      "Mailbox",
      "Mailman",
      "Maize",
      "Makemake",
      "Makeup",
      "Mall",
      "Mallet",
      "Mallow",
      "Mambo",
      "Mammal",
      "Manager",
      "Manatee",
      "Manchego",
      "Mandarin",
      "Mandevilla",
      "Mandible",
      "Mandolin",
      "Mandrill",
      "Mango",
      "Mangosteen",
      "Manicure",
      "Manta",
      "Mantis",
      "Manuscript",
      "Manx",
      "Map",
      "Maple",
      "Mapusaurus",
      "Maraca",
      "Marble",
      "March",
      "Mare",
      "Margin",
      "Marigold",
      "Marimba",
      "Marionberry",
      "Marjoram",
      "Market",
      "Marlin",
      "Marmoset",
      "Marmot",
      "Marquess",
      "Marquis",
      "Mars",
      "Marscapone",
      "Marshmallow",
      "Marsupial",
      "Marten",
      "Mascara",
      "Mask",
      "Mass",
      "Mastodon",
      "Mat",
      "Match",
      "Math",
      "Maxilla",
      "May",
      "Mayflower",
      "Mayonnaise",
      "Meadow",
      "Meadowlark",
      "Meal",
      "Measure",
      "Meat",
      "Mechanic",
      "Medicine",
      "Medusaceratops",
      "Meerkat",
      "Meeting",
      "Megalosaurus",
      "Megaraptor",
      "Melody",
      "Melon",
      "Memory",
      "Menu",
      "Mercury",
      "Message",
      "Metacarpal",
      "Metal",
      "Metatarsal",
      "Meteor",
      "Meteorite",
      "Meteoroid",
      "Meteorology",
      "Meter",
      "Methane",
      "Mice",
      "Microceratops",
      "Microraptor",
      "Microwave",
      "Middle",
      "Midnight",
      "Mile",
      "Milk",
      "Milkshake",
      "Millennium",
      "Mimosa",
      "Mind",
      "Mine",
      "Minibus",
      "Mink",
      "Minnow",
      "Minotaurasaurus",
      "Mint",
      "Minute",
      "Mirror",
      "Mist",
      "Mistake",
      "Mitten",
      "Moat",
      "Mochi",
      "Mockingbird",
      "Modem",
      "Mojoceratops",
      "Mole",
      "Molecule",
      "Mollusk",
      "Molybdenum",
      "Monarch",
      "Monday",
      "Money",
      "Mongoose",
      "Monitor",
      "Monkey",
      "Month",
      "Moon",
      "Moonflower",
      "Moonstone",
      "Moose",
      "Morning",
      "Morocco",
      "Mortarboard",
      "Mosquito",
      "Moss",
      "Moth",
      "Motion",
      "Motorcycle",
      "Mountain",
      "Mouse",
      "Mousepad",
      "Moustache",
      "Mouth",
      "Move",
      "Mozzarella",
      "Muenster",
      "Mulberry",
      "Mule",
      "Mum",
      "Munchkin",
      "Muscari",
      "Muscle",
      "Muse",
      "Museum",
      "Mushroom",
      "Music",
      "Musician",
      "Muskmelon",
      "Muskox",
      "Mustang",
      "Mustard",
      "Myrtle",
      "Myth",
      "Nail",
      "Name",
      "Nannyberry",
      "Nape",
      "Napkin",
      "Naranja",
      "Narcissus",
      "Narwhal",
      "Nasturtium",
      "Nation",
      "Nautilus",
      "Navy",
      "Nebula",
      "Nectarine",
      "Need",
      "Neem",
      "Neighbor",
      "Neighborhood",
      "Nemophila",
      "Neon",
      "Nephew",
      "Neptune",
      "Nerine",
      "Nerve",
      "Nest",
      "Net",
      "Network",
      "Newsboy",
      "Newsprint",
      "Newsstand",
      "Newt",
      "Nickel",
      "Niece",
      "Nigella",
      "Night",
      "Nightingale",
      "Nightshade",
      "Ninja",
      "Nitrogen",
      "Node",
      "Noise",
      "Noodle",
      "Nose",
      "Note",
      "Notebook",
      "Notify",
      "Nova",
      "Novel",
      "November",
      "Number",
      "Numeric",
      "Nurse",
      "Nut",
      "Nutmeg",
      "Nutria",
      "Nyala",
      "Nylon",
      "Nymphea",
      "Oak",
      "Oatmeal",
      "Objective",
      "Oboe",
      "Observation",
      "Observatory",
      "Ocarina",
      "Occupation",
      "Ocean",
      "Ocelot",
      "Ocicat",
      "Octagon",
      "Octave",
      "October",
      "Octopus",
      "Odometer",
      "Offer",
      "Office",
      "Ogre",
      "Oil",
      "Okapi",
      "Okra",
      "Olive",
      "Olivine",
      "Omelet",
      "Onion",
      "Onyx",
      "Opal",
      "Open",
      "Opera",
      "Operation",
      "Ophthalmologist",
      "Opinion",
      "Opossum",
      "Opportunity",
      "Option",
      "Orange",
      "Orangutan",
      "Orbit",
      "Orca",
      "Orchestra",
      "Orchid",
      "Order",
      "Oregano",
      "Organ",
      "Organization",
      "Origami",
      "Oriole",
      "Ornament",
      "Ostrich",
      "Othnielia",
      "Otter",
      "Ounce",
      "Output",
      "Outrigger",
      "Oval",
      "Overcoat",
      "Oviraptor",
      "Owl",
      "Owner",
      "Ox",
      "Oxygen",
      "Oyster",
      "Ozraraptor",
      "Package",
      "Packet",
      "Paddleboat",
      "Page",
      "Pail",
      "Paint",
      "Painter",
      "Pair",
      "Pajama",
      "Palladium",
      "Palm",
      "Pamphlet",
      "Pan",
      "Panama",
      "Pancake",
      "Pancreas",
      "Panda",
      "Paneer",
      "Pangolin",
      "Pansy",
      "Panther",
      "Pantry",
      "Pantydraco",
      "Papaya",
      "Paper",
      "Paperback",
      "Paprika",
      "Papyrus",
      "Parade",
      "Paradox",
      "Parakeet",
      "Parallelogram",
      "Paranthodon",
      "Parcel",
      "Parent",
      "Parenthesis",
      "Park",
      "Parka",
      "Parmesan",
      "Parrot",
      "Parrotfish",
      "Parsley",
      "Parsnip",
      "Part",
      "Particle",
      "Partner",
      "Partridge",
      "Party",
      "Passbook",
      "Passenger",
      "Passionfruit",
      "Passive",
      "Pasta",
      "Paste",
      "Pastry",
      "Patch",
      "Patella",
      "Path",
      "Patient",
      "Patio",
      "Paw",
      "Pawpaw",
      "Payment",
      "Pea",
      "Peace",
      "Peach",
      "Peacock",
      "Peak",
      "Peanut",
      "Pear",
      "Pearl",
      "Pecorino",
      "Pedestrian",
      "Pediatrician",
      "Pegasus",
      "Pelican",
      "Pen",
      "Penalty",
      "Pencil",
      "Pendulum",
      "Penguin",
      "Pentaceratops",
      "Pentagon",
      "Peony",
      "People",
      "Pepper",
      "Pepperberry",
      "Perch",
      "Perfume",
      "Peridot",
      "Perigee",
      "Period",
      "Periodical",
      "Peripheral",
      "Periwinkle",
      "Persimmon",
      "Pest",
      "Pet",
      "Petalite",
      "Petroleum",
      "Petunia",
      "Pewter",
      "Phalange",
      "Pharaoh",
      "Pheasant",
      "Philodendron",
      "Philosophy",
      "Phlox",
      "Phone",
      "Phosphorus",
      "Physician",
      "Piano",
      "Piccolo",
      "Pick",
      "Pickle",
      "Picture",
      "Pie",
      "Pig",
      "Pigeon",
      "Pigment",
      "Pike",
      "Pillow",
      "Pilot",
      "Pin",
      "Pincushion",
      "Pine",
      "Pineapple",
      "Ping",
      "Pink",
      "Pint",
      "Pipe",
      "Piper",
      "Piranha",
      "Pirate",
      "Pisces",
      "Pixie",
      "Pizza",
      "Place",
      "Plain",
      "Planarian",
      "Plane",
      "Planet",
      "Plant",
      "Plantain",
      "Plaster",
      "Plastic",
      "Plate",
      "Platinum",
      "Platypus",
      "Play",
      "Player",
      "Playground",
      "Playroom",
      "Pleasure",
      "Plier",
      "Plot",
      "Plough",
      "Plow",
      "Plum",
      "Plume",
      "Pluto",
      "Plutonium",
      "Plywood",
      "Pocket",
      "Podium",
      "Poet",
      "Poinsettia",
      "Point",
      "Poison",
      "Polish",
      "Politician",
      "Polka",
      "Polo",
      "Polonium",
      "Polyanthus",
      "Polyester",
      "Pomegranate",
      "Pomelo",
      "Pond",
      "Pony",
      "Popcorn",
      "Poppy",
      "Poppyseed",
      "Porch",
      "Porcupine",
      "Porkpie",
      "Porpoise",
      "Port",
      "Porter",
      "Position",
      "Possum",
      "Postage",
      "Postbox",
      "Pot",
      "Potassium",
      "Potato",
      "Potential",
      "Poultry",
      "Pound",
      "Powder",
      "Power",
      "Practice",
      "Pram",
      "Prawn",
      "Preface",
      "Prepared",
      "Pressure",
      "Price",
      "Primrose",
      "Primula",
      "Prince",
      "Princess",
      "Principal",
      "Principle",
      "Print",
      "Printer",
      "Process",
      "Produce",
      "Product",
      "Production",
      "Professor",
      "Profit",
      "Promise",
      "Promotion",
      "Property",
      "Prose",
      "Prosecution",
      "Protest",
      "Protoceratops",
      "Protocol",
      "Provelone",
      "Prune",
      "Pruner",
      "Psychiatrist",
      "Psychology",
      "Ptarmigan",
      "Puck",
      "Pudding",
      "Pufferfish",
      "Puffin",
      "Pull",
      "Pullover",
      "Pulsar",
      "Puma",
      "Pump",
      "Pumpkin",
      "Punch",
      "Punishment",
      "Puppet",
      "Puppy",
      "Purchase",
      "Purple",
      "Purpose",
      "Push",
      "Pyjama",
      "Pyramid",
      "Pyrite",
      "Pyroraptor",
      "Python",
      "Quail",
      "Quality",
      "Quark",
      "Quart",
      "Quarter",
      "Quartz",
      "Quasar",
      "Queen",
      "Quesadilla",
      "Question",
      "Quicksand",
      "Quiet",
      "Quill",
      "Quilt",
      "Quince",
      "Quit",
      "Quiver",
      "Quokka",
      "Quotation",
      "Rabbit",
      "Raccoon",
      "Racer",
      "Raclette",
      "Radar",
      "Radiator",
      "Radio",
      "Radish",
      "Radium",
      "Radius",
      "Radon",
      "Raft",
      "Ragamuffin",
      "Ragdoll",
      "Rail",
      "Railway",
      "Rain",
      "Rainbow",
      "Raincoat",
      "Rainforest",
      "Rainstorm",
      "Raisin",
      "Raja",
      "Rake",
      "Rambutan",
      "Random",
      "Range",
      "Ranunculus",
      "Raptor",
      "Raptorex",
      "Rat",
      "Rate",
      "Rattlesnake",
      "Raven",
      "Raver",
      "Ravioli",
      "Ray",
      "Rayon",
      "Reaction",
      "Reading",
      "Reaper",
      "Reason",
      "Receipt",
      "Recess",
      "Record",
      "Recorder",
      "Red",
      "Redcurrant",
      "Regnosaurus",
      "Reindeer",
      "Relation",
      "Relative",
      "Relish",
      "Reminder",
      "Repair",
      "Replace",
      "Reply",
      "Report",
      "Reptile",
      "Request",
      "Resistance",
      "Resolution",
      "Resonance",
      "Respect",
      "Responsibility",
      "Rest",
      "Restaurant",
      "Result",
      "Revolve",
      "Reward",
      "Rhinoceros",
      "Rhodium",
      "Rhubarb",
      "Rhythm",
      "Rib",
      "Rice",
      "Ricotta",
      "Riddle",
      "Ridge",
      "Rifle",
      "Ring",
      "Ringer",
      "Rise",
      "Risk",
      "River",
      "Riverbed",
      "Road",
      "Roadrunner",
      "Roadway",
      "Roar",
      "Roarer",
      "Roast",
      "Robe",
      "Robin",
      "Rock",
      "Rocket",
      "Rodent",
      "Roll",
      "Romano",
      "Rondeletia",
      "Roof",
      "Rook",
      "Room",
      "Rooster",
      "Root",
      "Roquefort",
      "Rose",
      "Rosehip",
      "Rosemary",
      "Rotate",
      "Roundworm",
      "Route",
      "Router",
      "Rover",
      "Rowboat",
      "Rubidium",
      "Ruby",
      "Rudbeckia",
      "Rugby",
      "Rule",
      "Rumba",
      "Run",
      "Runner",
      "Rutabaga",
      "Safflower",
      "Saffron",
      "Saga",
      "Sage",
      "Sagittarius",
      "Saguaro",
      "Sail",
      "Sailboat",
      "Sailfish",
      "Sailor",
      "Salad",
      "Salamander",
      "Salary",
      "Sale",
      "Salesman",
      "Salmon",
      "Salmonberry",
      "Salsa",
      "Salt",
      "Saltasaurus",
      "Salto",
      "Saltopus",
      "Salute",
      "Samba",
      "Sand",
      "Sandal",
      "Sandalwood",
      "Sandpaper",
      "Sandwich",
      "Santanaraptor",
      "Sapphire",
      "Sarahsaurus",
      "Sardine",
      "Sassafras",
      "Satellite",
      "Satin",
      "Saturday",
      "Saturn",
      "Saturnalia",
      "Sauce",
      "Sauroposeidon",
      "Save",
      "Saver",
      "Savory",
      "Saw",
      "Sawfish",
      "Saxophone",
      "Scabiosa",
      "Scaffold",
      "Scale",
      "Scallion",
      "Scallop",
      "Scapula",
      "Scar",
      "Scarecrow",
      "Scarer",
      "Scarf",
      "Scene",
      "Scent",
      "Sceptre",
      "School",
      "Schooner",
      "Science",
      "Scilla",
      "Scion",
      "Scissor",
      "Scooter",
      "Scorpio",
      "Scorpion",
      "Scourge",
      "Scowl",
      "Scraper",
      "Screen",
      "Screw",
      "Screwdriver",
      "Scribe",
      "Script",
      "Sea",
      "Seagull",
      "Seahorse",
      "Seal",
      "Seaplane",
      "Search",
      "Seashore",
      "Season",
      "Seat",
      "Seatbelt",
      "Second",
      "Secretary",
      "Secure",
      "Sedum",
      "Seed",
      "Seeder",
      "Seeker",
      "Seer",
      "Seismosaurus",
      "Selenium",
      "Sense",
      "September",
      "Serpent",
      "Servant",
      "Server",
      "Sesame",
      "Session",
      "Settee",
      "Shad",
      "Shade",
      "Shadow",
      "Shake",
      "Shaker",
      "Shallot",
      "Shame",
      "Shampoo",
      "Shamrock",
      "Shape",
      "Share",
      "Shark",
      "Shawl",
      "Shear",
      "Sheep",
      "Sheet",
      "Shelf",
      "Shell",
      "Sherbet",
      "Shield",
      "Shift",
      "Shingle",
      "Ship",
      "Shirt",
      "Shock",
      "Shoe",
      "Shoemaker",
      "Shop",
      "Shoulder",
      "Shovel",
      "Show",
      "Shrew",
      "Shrimp",
      "Shrine",
      "Shroud",
      "Side",
      "Sidecar",
      "Sidewalk",
      "Sight",
      "Sign",
      "Silence",
      "Silene",
      "Silica",
      "Silicon",
      "Silk",
      "Silkworm",
      "Silver",
      "Silverfish",
      "Sing",
      "Singer",
      "Single",
      "Singularity",
      "Sink",
      "Situation",
      "Skate",
      "Skateboard",
      "Ski",
      "Skiff",
      "Skink",
      "Skipjack",
      "Skirt",
      "Skull",
      "Skunk",
      "Sky",
      "Slash",
      "Slayer",
      "Sled",
      "Sleep",
      "Sleet",
      "Slice",
      "Slicer",
      "Slime",
      "Slip",
      "Slipper",
      "Sloop",
      "Slope",
      "Sloth",
      "Slouch",
      "Slug",
      "Smartphone",
      "Smash",
      "Smell",
      "Smelt",
      "Smile",
      "Smoke",
      "Snagglefoot",
      "Snail",
      "Snake",
      "Snap",
      "Snapdragon",
      "Snapper",
      "Snarl",
      "Sneeze",
      "Sneezeweed",
      "Snickerdoodle",
      "Snipe",
      "Snout",
      "Snow",
      "Snowboard",
      "Snowdrop",
      "Snowflake",
      "Snowman",
      "Snowplow",
      "Snowshoe",
      "Snowstorm",
      "Soap",
      "Soapwort",
      "Soarer",
      "Soccer",
      "Society",
      "Sociology",
      "Sock",
      "Socks",
      "Soda",
      "Sodalite",
      "Sodium",
      "Sofa",
      "Softball",
      "Soil",
      "Soldier",
      "Sole",
      "Sombrero",
      "Somersault",
      "Son",
      "Song",
      "Soprano",
      "Sorrel",
      "Sort",
      "Soul",
      "Sound",
      "Soup",
      "Source",
      "Soursop",
      "Sousaphone",
      "Sovereign",
      "Soy",
      "Soybean",
      "Space",
      "Spade",
      "Spaghetti",
      "Spandex",
      "Spark",
      "Sparrow",
      "Spatula",
      "Spear",
      "Specialist",
      "Spectacles",
      "Spectrograph",
      "Spectroscope",
      "Spectrum",
      "Speedboat",
      "Speedwell",
      "Spell",
      "Sphere",
      "Sphynx",
      "Spice",
      "Spider",
      "Spike",
      "Spinach",
      "Spinosaurus",
      "Spirit",
      "Splash",
      "Spleen",
      "Split",
      "Sponge",
      "Spoon",
      "Spoonbill",
      "Spot",
      "Spring",
      "Sprint",
      "Sprite",
      "Sprout",
      "Spruce",
      "Spur",
      "Spy",
      "Square",
      "Squash",
      "Squid",
      "Squirrel",
      "Stag",
      "Stage",
      "Staircase",
      "Stallion",
      "Stamp",
      "Star",
      "Starburst",
      "Starfish",
      "Starflower",
      "Stargazer",
      "Station",
      "Statistic",
      "Stay",
      "Stealer",
      "Steam",
      "Steed",
      "Steel",
      "Stegosaurus",
      "Stem",
      "Step",
      "Sternum",
      "Stetson",
      "Stew",
      "Stick",
      "Stilton",
      "Sting",
      "Stinger",
      "Stingray",
      "Stitch",
      "Stoat",
      "Stock",
      "Stocking",
      "Stomach",
      "Stone",
      "Stool",
      "Stop",
      "Stoplight",
      "Store",
      "Stork",
      "Storm",
      "Story",
      "Stove",
      "Strand",
      "Stranger",
      "Straw",
      "Strawflower",
      "Stream",
      "Street",
      "Streetcar",
      "Stretch",
      "String",
      "Structure",
      "Study",
      "Sturgeon",
      "Stygimoloch",
      "Submarine",
      "Substance",
      "Subway",
      "Success",
      "Suede",
      "Sugar",
      "Suggestion",
      "Suit",
      "Sulfur",
      "Sumac",
      "Summer",
      "Sun",
      "Sundae",
      "Sunday",
      "Sundial",
      "Sunfish",
      "Sunflower",
      "Sunscreen",
      "Sunset",
      "Sunshine",
      "Sunspot",
      "Sunstone",
      "Supermarket",
      "Supernova",
      "Supply",
      "Surf",
      "Surfboard",
      "Surgeon",
      "Surprise",
      "Sushi",
      "Swallow",
      "Swamp",
      "Swan",
      "Sweater",
      "Sweatpants",
      "Sweatshirt",
      "Swift",
      "Swim",
      "Swing",
      "Switch",
      "Swoop",
      "Sword",
      "Swordfish",
      "Swordtail",
      "Sycamore",
      "Syringa",
      "Syrup",
      "System",
      "Syzygy",
      "Table",
      "Tablecloth",
      "Tabletop",
      "Tachometer",
      "Tadpole",
      "Tail",
      "Tailor",
      "Taker",
      "Taleggio",
      "Talk",
      "Talon",
      "Talos",
      "Tamarillo",
      "Tamarind",
      "Tang",
      "Tangelo",
      "Tangerine",
      "Tango",
      "Tank",
      "Tanker",
      "Tapir",
      "Tarantula",
      "Tarascosaurus",
      "Target",
      "Tarn",
      "Tarp",
      "Tarragon",
      "Tarsal",
      "Tarsier",
      "Tartan",
      "Taste",
      "Taurus",
      "Tax",
      "Taxi",
      "Taxicab",
      "Tea",
      "Teacher",
      "Teal",
      "Team",
      "Technician",
      "Technosaurus",
      "Teeth",
      "Telephone",
      "Telescope",
      "Television",
      "Teller",
      "Tellurium",
      "Temper",
      "Temperature",
      "Temple",
      "Tempo",
      "Tendency",
      "Tennis",
      "Tenor",
      "Tent",
      "Termite",
      "Tern",
      "Terrier",
      "Territory",
      "Text",
      "Textbook",
      "Texture",
      "Theater",
      "Theory",
      "Thief",
      "Thimbleberry",
      "Thing",
      "Thistle",
      "Thorium",
      "Thorn",
      "Thought",
      "Thread",
      "Thrill",
      "Throat",
      "Throne",
      "Thrush",
      "Thumb",
      "Thunbergia",
      "Thunder",
      "Thursday",
      "Thyme",
      "Tiara",
      "Tibia",
      "Tick",
      "Ticket",
      "Tie",
      "Tiger",
      "Tiglon",
      "Tilapia",
      "Tile",
      "Timbale",
      "Time",
      "Timer",
      "Timimus",
      "Timpani",
      "Tin",
      "Tip",
      "Tire",
      "Titanium",
      "Titanoceratops",
      "Titanosaurus",
      "Tithonia",
      "Title",
      "Toad",
      "Toast",
      "Toaster",
      "Tomato",
      "Ton",
      "Toothbrush",
      "Toothpaste",
      "Top",
      "Topaz",
      "Toque",
      "Tornado",
      "Torta",
      "Tortellini",
      "Tortoise",
      "Tote",
      "Toucan",
      "Touch",
      "Tourmaline",
      "Tower",
      "Town",
      "Toy",
      "Track",
      "Tracker",
      "Tractor",
      "Trade",
      "Trader",
      "Traffic",
      "Trail",
      "Train",
      "Trampoline",
      "Transport",
      "Trapezoid",
      "Trawler",
      "Tray",
      "Treatment",
      "Tree",
      "Triangle",
      "Triceratops",
      "Trick",
      "Tricorne",
      "Trigonometry",
      "Trilby",
      "Trillium",
      "Trip",
      "Trollius",
      "Trombone",
      "Troodon",
      "Trouble",
      "Trouser",
      "Trout",
      "Trowel",
      "Truck",
      "Truffle",
      "Trumpet",
      "Trunk",
      "Trust",
      "Tsunami",
      "Tub",
      "Tuba",
      "Tuberose",
      "Tuck",
      "Tuesday",
      "Tugboat",
      "Tulip",
      "Tumble",
      "Tumbleweed",
      "Tuna",
      "Tune",
      "Tungsten",
      "Turkey",
      "Turn",
      "Turner",
      "Turnip",
      "Turnover",
      "Turquoise",
      "Turret",
      "Turtle",
      "Tv",
      "Twig",
      "Twilight",
      "Twill",
      "Twine",
      "Twist",
      "Twister",
      "Typhoon",
      "Tyrannosaurus",
      "Ulna",
      "Umbra",
      "Umbrella",
      "Uncle",
      "Unicorn",
      "Universe",
      "Uranium",
      "Ursinia",
      "Utahceratops",
      "Utahraptor",
      "Utensil",
      "Vacation",
      "Vacuum",
      "Valley",
      "Value",
      "Van",
      "Vanadium",
      "Vanilla",
      "Variraptor",
      "Vase",
      "Vault",
      "Vegetable",
      "Vegetarian",
      "Veil",
      "Vein",
      "Velociraptor",
      "Velvet",
      "Venom",
      "Venus",
      "Verbena",
      "Verdict",
      "Vermicelli",
      "Verse",
      "Vertebra",
      "Vessel",
      "Vest",
      "Veterinarian",
      "Vibraphone",
      "Viburnum",
      "Vicuna",
      "Vinca",
      "Vinyl",
      "Viola",
      "Violet",
      "Violin",
      "Viper",
      "Virgo",
      "Visage",
      "Viscose",
      "Viscount",
      "Viscountess",
      "Vise",
      "Vision",
      "Visitor",
      "Visor",
      "Voice",
      "Volcano",
      "Vole",
      "Volleyball",
      "Voyage",
      "Vulcanodon",
      "Vulture",
      "Waiter",
      "Waitress",
      "Wakeboard",
      "Walk",
      "Walker",
      "Walkover",
      "Wall",
      "Wallaby",
      "Wallet",
      "Walleye",
      "Wallflower",
      "Walnut",
      "Walrus",
      "Waltz",
      "Wanderer",
      "Wandflower",
      "Wannanosaurus",
      "War",
      "Warbler",
      "Warlock",
      "Warrior",
      "Wasabi",
      "Wash",
      "Washer",
      "Wasp",
      "Waste",
      "Watch",
      "Watcher",
      "Watchmaker",
      "Water",
      "Watercress",
      "Waterfall",
      "Waterlily",
      "Wave",
      "Wavelength",
      "Wax",
      "Waxflower",
      "Way",
      "Wealth",
      "Weather",
      "Weaver",
      "Web",
      "Wedelia",
      "Wedge",
      "Wednesday",
      "Weeder",
      "Week",
      "Weight",
      "Whale",
      "Wheel",
      "Whimsey",
      "Whip",
      "Whippet",
      "Whippoorwill",
      "Whistle",
      "Whitefish",
      "Wholesaler",
      "Wildcat",
      "Wildebeest",
      "Wilderness",
      "Wildflower",
      "William",
      "Willow",
      "Wind",
      "Windflower",
      "Windscreen",
      "Windshield",
      "Wineberry",
      "Wing",
      "Winter",
      "Winterberry",
      "Wire",
      "Wish",
      "Wishbone",
      "Wisteria",
      "Witch",
      "Witness",
      "Wizard",
      "Wok",
      "Wolf",
      "Wolfberry",
      "Wolfsbane",
      "Wolverine",
      "Wombat",
      "Wood",
      "Woodpecker",
      "Woodwind",
      "Wool",
      "Woolen",
      "Word",
      "Work",
      "Workshop",
      "Worm",
      "Wormhole",
      "Wound",
      "Wren",
      "Wrench",
      "Wrinkle",
      "Wrist",
      "Writer",
      "Xenon",
      "Xenoposeidon",
      "Xylocarp",
      "Xylophone",
      "Yacht",
      "Yak",
      "Yam",
      "Yamamomo",
      "Yard",
      "Yarn",
      "Yarrow",
      "Year",
      "Yellowhorn",
      "Yew",
      "Yogurt",
      "Yoke",
      "Yttrium",
      "Yumberry",
      "Yuzu",
      "Zebra",
      "Zebu",
      "Zenith",
      "Zenobia",
      "Zephyr",
      "Ziconium",
      "Zinc",
      "Zinnia",
      "Zipper",
      "Zircon",
      "Zone",
      "Zoo",
      "Zucchini",
      "Zydeco",
    ],
    predicates: [
      "Aback",
      "Abaft",
      "Abalone",
      "Abiding",
      "Ablaze",
      "Able",
      "Aboard",
      "Abounding",
      "Abrasive",
      "Abrupt",
      "Absorbed",
      "Absorbing",
      "Abstracted",
      "Abundant",
      "Abyssinian",
      "Accessible",
      "Accidental",
      "Accurate",
      "Achieved",
      "Acidic",
      "Acoustic",
      "Actually",
      "Acute",
      "Adaptable",
      "Adaptive",
      "Adhesive",
      "Adjoining",
      "Admitted",
      "Adorable",
      "Adventurous",
      "Aeolian",
      "Aerial",
      "Agate",
      "Aged",
      "Agreeable",
      "Ahead",
      "Airy",
      "Ajar",
      "Alabaster",
      "Alder",
      "Alert",
      "Alike",
      "Alive",
      "Alkaline",
      "Alluring",
      "Almond",
      "Almondine",
      "Alpine",
      "Aluminum",
      "Amazing",
      "Amber",
      "Ambiguous",
      "Ambitious",
      "Amenable",
      "Amethyst",
      "Amplified",
      "Amused",
      "Amusing",
      "Ancient",
      "Angry",
      "Animated",
      "Antique",
      "Apple",
      "Apricot",
      "Aquamarine",
      "Aquatic",
      "Aromatic",
      "Arrow",
      "Artistic",
      "Ash",
      "Aspiring",
      "Assorted",
      "Astonishing",
      "Atlantic",
      "Atom",
      "Attractive",
      "Auspicious",
      "Automatic",
      "Autumn",
      "Available",
      "Awake",
      "Aware",
      "Awesome",
      "Axiomatic",
      "Azure",
      "Balanced",
      "Bald",
      "Ballistic",
      "Balsam",
      "Band",
      "Basalt",
      "Battle",
      "Bead",
      "Beaded",
      "Beautiful",
      "Bedecked",
      "Befitting",
      "Bejewled",
      "Believed",
      "Beneficial",
      "Berry",
      "Beryl",
      "Best",
      "Better",
      "Bevel",
      "Big",
      "Billowy",
      "Bird",
      "Bitter",
      "Bittersweet",
      "Bloom",
      "Blossom",
      "Blue",
      "Blush",
      "Blushing",
      "Boatneck",
      "Boggy",
      "Boiled",
      "Boiling",
      "Bold",
      "Bolder",
      "Bony",
      "Boom",
      "Booming",
      "Bottlenose",
      "Boulder",
      "Bouncy",
      "Boundless",
      "Bow",
      "Brainy",
      "Bramble",
      "Branch",
      "Branched",
      "Brash",
      "Brass",
      "Brassy",
      "Brave",
      "Brawny",
      "Brazen",
      "Breezy",
      "Brick",
      "Brief",
      "Bright",
      "Brindle",
      "Bristle",
      "Broad",
      "Broadleaf",
      "Broken",
      "Bronze",
      "Bronzed",
      "Brook",
      "Bubble",
      "Bubbly",
      "Bumpy",
      "Burly",
      "Burnt",
      "Bush",
      "Bustling",
      "Busy",
      "Butter",
      "Buttercup",
      "Buttered",
      "Butternut",
      "Buttery",
      "Button",
      "Buttoned",
      "Cactus",
      "Cake",
      "Calico",
      "Calm",
      "Canary",
      "Candied",
      "Candle",
      "Candy",
      "Canyon",
      "Capable",
      "Capricious",
      "Caramel",
      "Carbonated",
      "Careful",
      "Caring",
      "Carnation",
      "Carnelian",
      "Carpal",
      "Cat",
      "Caterwauling",
      "Catkin",
      "Catnip",
      "Cautious",
      "Cedar",
      "Celestial",
      "Certain",
      "Cerulean",
      "Chain",
      "Chalk",
      "Chambray",
      "Changeable",
      "Charm",
      "Charmed",
      "Charming",
      "Chartreuse",
      "Chatter",
      "Checker",
      "Checkered",
      "Cheddar",
      "Cheerful",
      "Chemical",
      "Cherry",
      "Chestnut",
      "Chief",
      "Childish",
      "Childlike",
      "Chill",
      "Chip",
      "Chipped",
      "Chisel",
      "Chiseled",
      "Chivalrous",
      "Chlorinated",
      "Chocolate",
      "Chrome",
      "Circular",
      "Citrine",
      "Clammy",
      "Classic",
      "Classy",
      "Clean",
      "Clear",
      "Clever",
      "Cliff",
      "Climbing",
      "Closed",
      "Cloud",
      "Cloudy",
      "Clover",
      "Clumsy",
      "Coal",
      "Cobalt",
      "Coconut",
      "Coffee",
      "Coherent",
      "Cold",
      "Colorful",
      "Colossal",
      "Comet",
      "Comfortable",
      "Common",
      "Complete",
      "Complex",
      "Concise",
      "Concrete",
      "Confirmed",
      "Confused",
      "Confusion",
      "Congruous",
      "Conscious",
      "Continuous",
      "Cooing",
      "Cooked",
      "Cookie",
      "Cool",
      "Cooperative",
      "Coordinated",
      "Copper",
      "Coral",
      "Cord",
      "Cosmic",
      "Cotton",
      "Cottony",
      "Courageous",
      "Crawling",
      "Cream",
      "Creative",
      "Crimson",
      "Crocus",
      "Crystal",
      "Crystalline",
      "Cubic",
      "Cuboid",
      "Cuddly",
      "Cultured",
      "Cumbersome",
      "Curious",
      "Curly",
      "Curse",
      "Curved",
      "Curvy",
      "Cut",
      "Cute",
      "Cyan",
      "Cyber",
      "Cyclic",
      "Cypress",
      "Daffodil",
      "Daffy",
      "Daily",
      "Daisy",
      "Dandelion",
      "Dandy",
      "Dapper",
      "Dark",
      "Darkened",
      "Dashing",
      "Dawn",
      "Dazzling",
      "Deadpan",
      "Dear",
      "Debonair",
      "Deciduous",
      "Decisive",
      "Decorous",
      "Deep",
      "Deeply",
      "Defiant",
      "Delicate",
      "Delicious",
      "Delightful",
      "Delirious",
      "Deluxe",
      "Denim",
      "Dent",
      "Dented",
      "Descriptive",
      "Desert",
      "Deserted",
      "Destiny",
      "Detailed",
      "Determined",
      "Developing",
      "Diagnostic",
      "Diamond",
      "Different",
      "Difficult",
      "Diligent",
      "Dirt",
      "Disco",
      "Discovered",
      "Discreet",
      "Distinct",
      "Dog",
      "Dolomite",
      "Dorian",
      "Dot",
      "Dour",
      "Dramatic",
      "Dull",
      "Dune",
      "Dust",
      "Dusty",
      "Dynamic",
      "Eager",
      "Early",
      "Earthy",
      "East",
      "Eastern",
      "Easy",
      "Ebony",
      "Economic",
      "Educated",
      "Efficacious",
      "Efficient",
      "Eggplant",
      "Eight",
      "Elastic",
      "Elated",
      "Elderly",
      "Electric",
      "Elegant",
      "Elemental",
      "Elfin",
      "Elite",
      "Ember",
      "Emerald",
      "Eminent",
      "Emphasized",
      "Empty",
      "Enchanted",
      "Enchanting",
      "Encouraging",
      "Endurable",
      "Energetic",
      "Enormous",
      "Enshrined",
      "Entertaining",
      "Enthusiastic",
      "Equable",
      "Equal",
      "Equatorial",
      "Equinox",
      "Erratic",
      "Ethereal",
      "Evanescent",
      "Even",
      "Evening",
      "Evergreen",
      "Everlasting",
      "Excellent",
      "Excessive",
      "Excited",
      "Exciting",
      "Exclusive",
      "Expensive",
      "Experienced",
      "Extreme",
      "Exuberant",
      "Exultant",
      "Fabulous",
      "Faceted",
      "Factual",
      "Faint",
      "Fair",
      "Faithful",
      "Fallacious",
      "False",
      "Familiar",
      "Famous",
      "Fan",
      "Fanatical",
      "Fancy",
      "Fantastic",
      "Fantasy",
      "Far",
      "Fascinated",
      "Fast",
      "Fate",
      "Fearless",
      "Feather",
      "Feline",
      "Fern",
      "Festive",
      "Few",
      "Field",
      "Fierce",
      "Fifth",
      "Fine",
      "Fir",
      "Fire",
      "First",
      "Fish",
      "Fishy",
      "Five",
      "Fixed",
      "Flame",
      "Flannel",
      "Flash",
      "Flashy",
      "Flat",
      "Flawless",
      "Flax",
      "Flaxen",
      "Flicker",
      "Flint",
      "Florentine",
      "Flossy",
      "Flower",
      "Flowery",
      "Fluff",
      "Fluffy",
      "Fluorescent",
      "Fluoridated",
      "Fluttering",
      "Flying",
      "Foam",
      "Foamy",
      "Fog",
      "Foggy",
      "Foil",
      "Foregoing",
      "Foremost",
      "Forest",
      "Forested",
      "Fork",
      "Fortunate",
      "Fortune",
      "Fossil",
      "Foul",
      "Four",
      "Fourth",
      "Fragrant",
      "Freckle",
      "Free",
      "Freezing",
      "Frequent",
      "Fresh",
      "Friendly",
      "Frill",
      "Fringe",
      "Frost",
      "Frosted",
      "Fuchsia",
      "Full",
      "Functional",
      "Funky",
      "Funny",
      "Furry",
      "Furtive",
      "Future",
      "Futuristic",
      "Fuzzy",
      "Gabby",
      "Gainful",
      "Galvanized",
      "Gamy",
      "Garnet",
      "Garrulous",
      "Gaudy",
      "Gelatinous",
      "Gem",
      "General",
      "Generated",
      "Gentle",
      "Geode",
      "Giant",
      "Giddy",
      "Gifted",
      "Gigantic",
      "Gilded",
      "Ginger",
      "Glacier",
      "Glamorous",
      "Glass",
      "Glaze",
      "Gleaming",
      "Glen",
      "Glib",
      "Glimmer",
      "Glistening",
      "Glitter",
      "Glittery",
      "Global",
      "Glorious",
      "Glory",
      "Glossy",
      "Glow",
      "Glowing",
      "Gold",
      "Golden",
      "Goldenrod",
      "Good",
      "Goofy",
      "Gorgeous",
      "Gossamer",
      "Graceful",
      "Grand",
      "Grandiose",
      "Granite",
      "Grape",
      "Grass",
      "Grateful",
      "Gratis",
      "Grave",
      "Gravel",
      "Gray",
      "Great",
      "Green",
      "Gregarious",
      "Grey",
      "Grizzled",
      "Grizzly",
      "Groovy",
      "Grove",
      "Guiltless",
      "Gusty",
      "Guttural",
      "Habitual",
      "Hail",
      "Half",
      "Hallowed",
      "Halved",
      "Hammerhead",
      "Handsome",
      "Handsomely",
      "Handy",
      "Happy",
      "Harmless",
      "Harmonious",
      "Harsh",
      "Harvest",
      "Hazel",
      "Heady",
      "Healthy",
      "Heartbreaking",
      "Heather",
      "Heathered",
      "Heavenly",
      "Heavy",
      "Held",
      "Heliotrope",
      "Helix",
      "Helpful",
      "Hexagonal",
      "Hickory",
      "Highfalutin",
      "Hilarious",
      "Hill",
      "Hip",
      "Hissing",
      "Historical",
      "Holistic",
      "Hollow",
      "Holly",
      "Holy",
      "Honey",
      "Honeysuckle",
      "Honorable",
      "Honored",
      "Horn",
      "Horse",
      "Hospitable",
      "Hot",
      "Hulking",
      "Humane",
      "Humble",
      "Humdrum",
      "Humorous",
      "Hungry",
      "Hurricane",
      "Hushed",
      "Husky",
      "Hyper",
      "Hypnotic",
      "Iced",
      "Icy",
      "Illustrious",
      "Imaginary",
      "Immediate",
      "Immense",
      "Imminent",
      "Impartial",
      "Important",
      "Imported",
      "Impossible",
      "Incandescent",
      "Inconclusive",
      "Incongruous",
      "Incredible",
      "Indecisive",
      "Indigo",
      "Industrious",
      "Inexpensive",
      "Infrequent",
      "Ink",
      "Inky",
      "Innate",
      "Innovative",
      "Inquisitive",
      "Insidious",
      "Instinctive",
      "Intelligent",
      "Interesting",
      "Intermediate",
      "Internal",
      "Intriguing",
      "Invented",
      "Invincible",
      "Invited",
      "Iodized",
      "Ionian",
      "Ionized",
      "Iridescent",
      "Iris",
      "Iron",
      "Irradiated",
      "Island",
      "Ivory",
      "Ivy",
      "Jade",
      "Jagged",
      "Jasper",
      "Jazzy",
      "Jealous",
      "Jelly",
      "Jet",
      "Jewel",
      "Jeweled",
      "Jolly",
      "Joyous",
      "Judicious",
      "Juicy",
      "Jumbled",
      "Jumpy",
      "Jungle",
      "Juniper",
      "Just",
      "Juvenile",
      "Kaput",
      "Keen",
      "Kind",
      "Kindhearted",
      "Kindly",
      "Kiwi",
      "Knotty",
      "Knowing",
      "Knowledgeable",
      "Lace",
      "Laced",
      "Lackadaisical",
      "Lacy",
      "Lake",
      "Languid",
      "Lapis",
      "Large",
      "Laser",
      "Lateral",
      "Lava",
      "Lavender",
      "Lavish",
      "Lead",
      "Leaf",
      "Lean",
      "Learned",
      "Leather",
      "Leeward",
      "Legend",
      "Legendary",
      "Lemon",
      "Level",
      "Liberating",
      "Light",
      "Lightning",
      "Like",
      "Likeable",
      "Lilac",
      "Lily",
      "Lime",
      "Linen",
      "Literate",
      "Little",
      "Lively",
      "Living",
      "Lizard",
      "Local",
      "Locrian",
      "Lofty",
      "Long",
      "Longhaired",
      "Longing",
      "Lopsided",
      "Loud",
      "Lovely",
      "Loving",
      "Low",
      "Lowly",
      "Luck",
      "Lucky",
      "Ludicrous",
      "Lumbar",
      "Luminous",
      "Lumpy",
      "Lunar",
      "Lush",
      "Luxuriant",
      "Luxurious",
      "Lydian",
      "Lying",
      "Lyrical",
      "Maddening",
      "Magenta",
      "Magic",
      "Magical",
      "Magnetic",
      "Magnificent",
      "Mahogany",
      "Maize",
      "Majestic",
      "Malachite",
      "Malleable",
      "Mammoth",
      "Mango",
      "Mangrove",
      "Maple",
      "Marble",
      "Marbled",
      "Marked",
      "Marmalade",
      "Maroon",
      "Marred",
      "Married",
      "Marsh",
      "Marshy",
      "Marvelous",
      "Massive",
      "Material",
      "Materialistic",
      "Mature",
      "Maze",
      "Meadow",
      "Mellow",
      "Melodic",
      "Melodious",
      "Melon",
      "Melted",
      "Meowing",
      "Merciful",
      "Mercurial",
      "Mercury",
      "Mesquite",
      "Messy",
      "Metal",
      "Meteor",
      "Mewing",
      "Mica",
      "Midi",
      "Midnight",
      "Mighty",
      "Military",
      "Mini",
      "Miniature",
      "Mint",
      "Mirage",
      "Mire",
      "Mirror",
      "Misty",
      "Mixed",
      "Mixolydian",
      "Modern",
      "Momentous",
      "Moored",
      "Morning",
      "Motley",
      "Mountain",
      "Mountainous",
      "Mousy",
      "Mud",
      "Muddy",
      "Mulberry",
      "Mysterious",
      "Narrow",
      "Nasal",
      "Natural",
      "Navy",
      "Near",
      "Neat",
      "Nebula",
      "Nebulous",
      "Necessary",
      "Neighborly",
      "Neon",
      "Nervous",
      "Nettle",
      "Nice",
      "Nickel",
      "Nifty",
      "Night",
      "Nimble",
      "Nine",
      "Ninth",
      "Noble",
      "Noiseless",
      "Noisy",
      "Nonchalant",
      "Nonstop",
      "Noon",
      "North",
      "Northern",
      "Nostalgic",
      "Nosy",
      "Notch",
      "Nova",
      "Numerous",
      "Nutritious",
      "Oasis",
      "Observant",
      "Obsidian",
      "Obtainable",
      "Obvious",
      "Occipital",
      "Oceanic",
      "Octagonal",
      "Odd",
      "Oil",
      "Olive",
      "Olivine",
      "Omniscient",
      "Onyx",
      "Opalescent",
      "Opaque",
      "Open",
      "Opposite",
      "Orange",
      "Orchid",
      "Ordinary",
      "Organic",
      "Organized",
      "Ossified",
      "Outgoing",
      "Outrageous",
      "Outstanding",
      "Oval",
      "Overjoyed",
      "Oxidized",
      "Pacific",
      "Paint",
      "Painted",
      "Pale",
      "Palm",
      "Panoramic",
      "Paper",
      "Parallel",
      "Past",
      "Pastoral",
      "Patch",
      "Pattern",
      "Peaceful",
      "Peach",
      "Pear",
      "Peat",
      "Pebble",
      "Pentagonal",
      "Pepper",
      "Peppered",
      "Peppermint",
      "Perfect",
      "Peridot",
      "Periodic",
      "Periwinkle",
      "Perpetual",
      "Persistent",
      "Petal",
      "Petalite",
      "Petite",
      "Pewter",
      "Phantom",
      "Phase",
      "Phrygian",
      "Picayune",
      "Pickle",
      "Pickled",
      "Picturesque",
      "Pie",
      "Pine",
      "Pineapple",
      "Pinnate",
      "Pinto",
      "Piquant",
      "Pitch",
      "Placid",
      "Plaid",
      "Plain",
      "Planet",
      "Plant",
      "Plastic",
      "Platinum",
      "Plausible",
      "Playful",
      "Pleasant",
      "Plucky",
      "Plum",
      "Plume",
      "Plump",
      "Pointed",
      "Pointy",
      "Poised",
      "Polar",
      "Polarized",
      "Polished",
      "Polite",
      "Political",
      "Pollen",
      "Polydactyl",
      "Polyester",
      "Pond",
      "Pool",
      "Positive",
      "Possible",
      "Potent",
      "Pouncing",
      "Power",
      "Powerful",
      "Prairie",
      "Precious",
      "Pretty",
      "Pricey",
      "Prickle",
      "Prickly",
      "Principled",
      "Prism",
      "Private",
      "Probable",
      "Productive",
      "Profuse",
      "Prong",
      "Protective",
      "Proud",
      "Proximal",
      "Psychedelic",
      "Puddle",
      "Puffy",
      "Pumped",
      "Purple",
      "Purrfect",
      "Purring",
      "Pushy",
      "Puzzle",
      "Puzzled",
      "Puzzling",
      "Pyrite",
      "Quaint",
      "Quark",
      "Quartz",
      "Quasar",
      "Quick",
      "Quickest",
      "Quiet",
      "Quill",
      "Quilled",
      "Quilt",
      "Quilted",
      "Quintessential",
      "Quirky",
      "Quiver",
      "Quixotic",
      "Radial",
      "Radical",
      "Rain",
      "Rainbow",
      "Rainy",
      "Rambunctious",
      "Rapid",
      "Rare",
      "Raspy",
      "Rattle",
      "Real",
      "Rebel",
      "Receptive",
      "Recondite",
      "Rectangular",
      "Reflective",
      "Regal",
      "Regular",
      "Reinvented",
      "Reliable",
      "Relic",
      "Relieved",
      "Remarkable",
      "Reminiscent",
      "Repeated",
      "Resilient",
      "Resisted",
      "Resolute",
      "Resonant",
      "Respected",
      "Responsible",
      "Rhetorical",
      "Rhinestone",
      "Ribbon",
      "Rich",
      "Rift",
      "Right",
      "Righteous",
      "Rightful",
      "Rigorous",
      "Ring",
      "Ringed",
      "Ripe",
      "Ripple",
      "Ritzy",
      "River",
      "Road",
      "Roan",
      "Roasted",
      "Robust",
      "Rocky",
      "Rogue",
      "Romantic",
      "Roomy",
      "Root",
      "Rose",
      "Rough",
      "Round",
      "Rounded",
      "Rowan",
      "Royal",
      "Ruby",
      "Ruddy",
      "Rumbling",
      "Rune",
      "Rural",
      "Rust",
      "Rustic",
      "Saber",
      "Sable",
      "Safe",
      "Sage",
      "Salt",
      "Salty",
      "Same",
      "Sand",
      "Sandy",
      "Sapphire",
      "Sassy",
      "Satin",
      "Satisfying",
      "Savory",
      "Scalloped",
      "Scandalous",
      "Scarce",
      "Scarlet",
      "Scented",
      "Scientific",
      "Scintillating",
      "Scratch",
      "Scratched",
      "Scrawny",
      "Screeching",
      "Scythe",
      "Season",
      "Seasoned",
      "Second",
      "Secret",
      "Secretive",
      "Sedate",
      "Seed",
      "Seemly",
      "Seen",
      "Selective",
      "Separate",
      "Separated",
      "Sepia",
      "Sequoia",
      "Serious",
      "Shade",
      "Shaded",
      "Shadow",
      "Shadowed",
      "Shard",
      "Shared",
      "Sharp",
      "Sheer",
      "Shell",
      "Shelled",
      "Shimmer",
      "Shimmering",
      "Shine",
      "Shining",
      "Shiny",
      "Shocking",
      "Shore",
      "Short",
      "Shorthaired",
      "Showy",
      "Shrouded",
      "Shrub",
      "Shy",
      "Sideways",
      "Silent",
      "Silicon",
      "Silk",
      "Silken",
      "Silky",
      "Silly",
      "Silver",
      "Simple",
      "Simplistic",
      "Sincere",
      "Six",
      "Sixth",
      "Skillful",
      "Skinny",
      "Skitter",
      "Sky",
      "Slash",
      "Sleepy",
      "Sleet",
      "Slender",
      "Slime",
      "Slow",
      "Sly",
      "Small",
      "Smart",
      "Smiling",
      "Smoggy",
      "Smooth",
      "Snapdragon",
      "Sneaky",
      "Snow",
      "Snowy",
      "Soapy",
      "Soft",
      "Solar",
      "Solid",
      "Solstice",
      "Somber",
      "Sophisticated",
      "Sordid",
      "Sore",
      "Sour",
      "South",
      "Southern",
      "Spangle",
      "Spangled",
      "Spark",
      "Sparkling",
      "Sparkly",
      "Special",
      "Speckle",
      "Speckled",
      "Spectacled",
      "Spectacular",
      "Spectrum",
      "Sphenoid",
      "Spice",
      "Spiced",
      "Spicy",
      "Spiffy",
      "Spiky",
      "Spiny",
      "Spiral",
      "Spiritual",
      "Splashy",
      "Splendid",
      "Sponge",
      "Spot",
      "Spotless",
      "Spotted",
      "Spotty",
      "Spring",
      "Sprinkle",
      "Sprout",
      "Spurious",
      "Square",
      "Standing",
      "Star",
      "Statuesque",
      "Steadfast",
      "Steady",
      "Stealth",
      "Steel",
      "Steep",
      "Stellar",
      "Sticky",
      "Stingy",
      "Stirring",
      "Stitch",
      "Stone",
      "Storm",
      "Stormy",
      "Stream",
      "Strengthened",
      "Stripe",
      "Striped",
      "Strong",
      "Stump",
      "Stupendous",
      "Sturdy",
      "Suave",
      "Subdued",
      "Subsequent",
      "Substantial",
      "Successful",
      "Succinct",
      "Succulent",
      "Sudden",
      "Sudsy",
      "Sugar",
      "Sugared",
      "Sugary",
      "Sulfuric",
      "Sulky",
      "Summer",
      "Sumptuous",
      "Sun",
      "Sunny",
      "Sunrise",
      "Sunset",
      "Super",
      "Superb",
      "Superficial",
      "Supreme",
      "Surf",
      "Sustaining",
      "Swamp",
      "Swanky",
      "Sweet",
      "Sweltering",
      "Swift",
      "Synonymous",
      "Tabby",
      "Talented",
      "Tall",
      "Tame",
      "Tan",
      "Tangible",
      "Tangy",
      "Tar",
      "Tarry",
      "Tartan",
      "Tasteful",
      "Tasty",
      "Tattered",
      "Teal",
      "Telling",
      "Temporal",
      "Ten",
      "Tender",
      "Terrific",
      "Tested",
      "Thankful",
      "Therapeutic",
      "Thin",
      "Thinkable",
      "Third",
      "Thirsty",
      "Thoracic",
      "Thorn",
      "Thoughtful",
      "Thread",
      "Three",
      "Thrilling",
      "Thunder",
      "Thundering",
      "Tidal",
      "Tide",
      "Tidy",
      "Time",
      "Tin",
      "Tinted",
      "Tiny",
      "Titanium",
      "Toothsome",
      "Topaz",
      "Torch",
      "Torpid",
      "Tortoiseshell",
      "Tough",
      "Tourmaline",
      "Towering",
      "Trail",
      "Tranquil",
      "Translucent",
      "Transparent",
      "Trapezoidal",
      "Traveling",
      "Treasure",
      "Tree",
      "Tremendous",
      "Triangular",
      "Tricky",
      "Tricolor",
      "Trite",
      "Tropical",
      "Troubled",
      "Trusted",
      "Trusting",
      "Truth",
      "Truthful",
      "Tulip",
      "Tundra",
      "Tungsten",
      "Turquoise",
      "Twilight",
      "Twisty",
      "Typhoon",
      "Typical",
      "Ubiquitous",
      "Ultra",
      "Uncovered",
      "Understood",
      "Unequaled",
      "Uneven",
      "Unexpected",
      "Unique",
      "Universal",
      "Unleashed",
      "Unmarred",
      "Unruly",
      "Upbeat",
      "Useful",
      "Utopian",
      "Uttermost",
      "Vagabond",
      "Valiant",
      "Valley",
      "Valuable",
      "Vanilla",
      "Various",
      "Vast",
      "Vaulted",
      "Veil",
      "Veiled",
      "Verbena",
      "Verbose",
      "Verdant",
      "Versed",
      "Victorious",
      "Vigorous",
      "Vine",
      "Vintage",
      "Violet",
      "Viridian",
      "Vivacious",
      "Vivid",
      "Volcano",
      "Voltaic",
      "Voracious",
      "Waiting",
      "Wakeful",
      "Walnut",
      "Wandering",
      "Warm",
      "Warp",
      "Wary",
      "Water",
      "Watery",
      "Wave",
      "Wax",
      "Weak",
      "Wealthy",
      "Well",
      "West",
      "Western",
      "Wheat",
      "Whimsical",
      "Whip",
      "Whispering",
      "Wholesale",
      "Wide",
      "Wiggly",
      "Wild",
      "Wind",
      "Windy",
      "Winter",
      "Wirehaired",
      "Wiry",
      "Wise",
      "Wistful",
      "Witty",
      "Wobbly",
      "Wonderful",
      "Wood",
      "Wooded",
      "Wooden",
      "Wool",
      "Woolen",
      "Woolly",
      "Woozy",
      "Workable",
      "Working",
      "Worried",
      "Wry",
      "Yellow",
      "Yielding",
      "Young",
      "Youthful",
      "Yummy",
      "Zany",
      "Zealous",
      "Zenith",
      "Zest",
      "Zesty",
      "Zigzag",
      "Zinc",
      "Zippy",
      "Zircon",
    ],
  };
  const { predicates, objects } = words;
  const predicate = predicates[Math.floor(Math.random() * predicates.length)];
  const object = objects[Math.floor(Math.random() * objects.length)];
  return `${predicate} ${object}`;
}

export type AttemptedHost = {
  host: string;
  time: number;
};

export type IdentityLocalStorage = {
  id: number;
  username: string;
  attemptedHosts: Array<AttemptedHost>;
  allowedHosts: Array<string>;
  knownPeers: Array<KnownPeer>;
};

export class GlobalIdentityStorage {
  constructor() {}

  public persistIdentity(username: string) {
    const globalStorage = this.getGlobalStorage();
    globalStorage.username = username;
    const serialised = JSON.stringify(globalStorage);
    localStorage.setItem(localStorageIdentityKey, serialised);
  }

  public addPeerIdentity(userId: number, server: string, token: string, displayName?: string) {
    const globalStorage = this.getGlobalStorage();
    for (const knownPeer of globalStorage.knownPeers) {
      if (knownPeer.userId === userId) {
        knownPeer.server = server;
        knownPeer.token = token;
        knownPeer.displayName = displayName;
        this.saveGlobalStorage(globalStorage);
        return;
      }
    }
    globalStorage.knownPeers.push({
      userId,
      server,
      token,
      displayName,
    });
    this.saveGlobalStorage(globalStorage);
  }

  public removeKnownPeer(knownPeer: KnownPeer) {
    const globalStorage = this.getGlobalStorage();
    const filteredList = globalStorage.knownPeers.filter((existingPeer) => {
      return !knownPeersEqual(existingPeer, knownPeer);
    });
    globalStorage.knownPeers = filteredList;
    this.saveGlobalStorage(globalStorage);
  }

  public persistAttempt(hostLocation: string) {
    const globalStorage = this.getGlobalStorage();
    globalStorage.attemptedHosts.push({
      host: hostLocation,
      time: new Date().getTime(),
    });
    this.saveGlobalStorage(globalStorage);
  }

  public isHostAllowed(hostLocation: string): boolean {
    const globalStorage = this.getGlobalStorage();
    const hostPresentInAccepted = globalStorage.allowedHosts.some((acceptedHost) => {
      return acceptedHost === hostLocation;
    });
    return hostPresentInAccepted;
  }

  public getKnownPeers(): Array<KnownPeer> {
    return this.getGlobalStorage().knownPeers;
  }

  public getUsername(): string {
    return this.getGlobalStorage().username;
  }

  public getId(): number {
    return this.getGlobalStorage().id;
  }

  public getAllowedHosts(): Array<string> {
    return this.getGlobalStorage().allowedHosts;
  }

  public getAttemptedHosts(): Array<AttemptedHost> {
    return this.getGlobalStorage().attemptedHosts;
  }

  public allowHost(hostLocation: string) {
    const globalStorage = this.getGlobalStorage();
    const alreadyPresent = globalStorage.allowedHosts.some((acceptedHost) => {
      return acceptedHost === hostLocation;
    });
    if (!alreadyPresent) {
      globalStorage.allowedHosts.push(hostLocation);
    }
    const filteredAttemptsList = globalStorage.attemptedHosts.filter((attemptedHost) => {
      return attemptedHost.host !== hostLocation;
    });
    globalStorage.attemptedHosts = filteredAttemptsList;
    this.saveGlobalStorage(globalStorage);
  }

  public disallowHost(disallowedHost: string) {
    const globalStorage = this.getGlobalStorage();
    const filteredList = globalStorage.allowedHosts.filter((acceptedHost) => {
      return acceptedHost !== disallowedHost;
    });
    const filteredAttemptsList = globalStorage.attemptedHosts.filter((attemptedHost) => {
      return attemptedHost.host !== disallowedHost;
    });
    globalStorage.attemptedHosts = filteredAttemptsList;
    globalStorage.allowedHosts = filteredList;
    this.saveGlobalStorage(globalStorage);
  }

  private getGlobalStorage(): IdentityLocalStorage {
    // TODO - validation
    const currentStorage = localStorage.getItem(localStorageIdentityKey);
    let parsed: IdentityLocalStorage;
    if (currentStorage === null) {
      parsed = {
        id: 0,
        username: "",
        attemptedHosts: [],
        allowedHosts: [],
        knownPeers: [],
      };
    } else {
      parsed = JSON.parse(currentStorage);
      parsed = {
        ...parsed,
        username: parsed.username || "No name set",
        id: parsed.id || generateRandomId(),
        attemptedHosts: parsed.attemptedHosts || [],
        allowedHosts: parsed.allowedHosts || [],
        knownPeers: parsed.knownPeers || [],
      };
    }
    if (!parsed.id) {
      parsed.id = generateRandomId();
      this.saveGlobalStorage(parsed);
    }
    if (!parsed.username) {
      parsed.username = generateRandomName();
      this.saveGlobalStorage(parsed);
    }
    return parsed;
  }

  private saveGlobalStorage(globalStorage: IdentityLocalStorage) {
    const serialised = JSON.stringify(globalStorage);
    localStorage.setItem(localStorageIdentityKey, serialised);
  }
}
