import * as React from "react";

import styles from "./ScriptsList.module.css";

enum ScriptPresence {
  Own,
  Peer,
  Both,
}

const ScriptPresenceToClass = {
  [ScriptPresence.Own]: styles.own,
  [ScriptPresence.Peer]: styles.peer,
  [ScriptPresence.Both]: styles.both,
};

export function ScriptsList(props: {
  ownScripts: Map<string, { name: string; url: string }>;
  peerScripts: Map<
    string,
    {
      url: string;
      name: string;
    }
  >;
  loadScript: (url: string) => void;
}) {
  const allScripts = new Map<string, { name: string; url: string }>();
  for (const [url, script] of props.ownScripts) {
    allScripts.set(url, script);
  }
  for (const [url, script] of props.peerScripts) {
    allScripts.set(url, script);
  }

  const withPresence = Array.from(allScripts.values()).map((script) => {
    const own = props.ownScripts.has(script.url);
    const peer = props.peerScripts.has(script.url);
    return {
      ...script,
      presence: own && peer ? ScriptPresence.Both : own ? ScriptPresence.Own : ScriptPresence.Peer,
    };
  });

  // TODO - avoid sorting twice
  const sortedScripts = withPresence.sort((a, b) => {
    if (a.presence === b.presence) {
      return a.name.localeCompare(b.name);
    }
    return a.presence - b.presence;
  });

  return (
    <div className={styles.peerScriptsList}>
      {sortedScripts.map(({ url, name, presence }) => {
        return (
          <div
            className={[styles.peerScriptRow, ScriptPresenceToClass[presence]].join(" ")}
            key={url}
          >
            {name}
            {((presence) => {
              switch (presence) {
                case ScriptPresence.Own:
                  return " (Only You)";
                case ScriptPresence.Peer:
                  return (
                    <button
                      className={styles.button}
                      onClick={() => {
                        props.loadScript(url);
                      }}
                    >
                      Load
                    </button>
                  );
                case ScriptPresence.Both:
                  return " (Both Loaded)";
              }
            })(presence)}
          </div>
        );
      })}
    </div>
  );
}
