
const digest = 'ed6306dfaf89a22f21a03d25d8e22ae56c3b74e2ebb13f2bfb7a5f2019e5adee';
const css = `._section-header_xpwpp_1 {
    color: #787878;
    padding-top: 10px;
    padding-bottom: 0;
}

._peers-panel_1bkvl_1 {
    padding-left: 8px;
    padding-right: 8px;
}

._peers-list_1bkvl_6 {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    text-align: left;
    padding-bottom: 50px;
}

._list-content_1bkvl_15 {

}

._section-header_1bkvl_19 {
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"peers-panel":"_peers-panel_1bkvl_1","peersPanel":"_peers-panel_1bkvl_1","peers-list":"_peers-list_1bkvl_6","peersList":"_peers-list_1bkvl_6","list-content":"_list-content_1bkvl_15","listContent":"_list-content_1bkvl_15","section-header":"_section-header_1bkvl_19 _section-header_xpwpp_1","sectionHeader":"_section-header_1bkvl_19 _section-header_xpwpp_1"};
export { css, digest };
  