
const digest = 'ee35ff0d7606608098ec75be60ed38906b646347d47583c1e7279ed06751beaa';
const css = `._button_xchsj_1 {
    cursor: pointer;
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
}

._my-identity-panel_1fc0v_1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    padding: 5px;
}

._form_1fc0v_9 {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    align-content: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

._username-input_1fc0v_19 {
    width: 100%;
    margin: 2px;
}

._button_1fc0v_24 {
    flex-shrink: 0;
    margin: 2px;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"my-identity-panel":"_my-identity-panel_1fc0v_1","myIdentityPanel":"_my-identity-panel_1fc0v_1","form":"_form_1fc0v_9","username-input":"_username-input_1fc0v_19","usernameInput":"_username-input_1fc0v_19","button":"_button_1fc0v_24 _button_xchsj_1"};
export { css, digest };
  