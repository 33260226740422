import { inspect } from "util";

import * as React from "react";

import { KnownPeer } from "../../postMessages";
import { PeerIcon } from "./PeerList/PeerIcon/PeerIcon";
import styles from "./PeerRow.module.css";
import { KnownPeerConnectionStatus, KnownPeerState } from "./ScriptIframe";
import { ScriptsList } from "./ScriptsList";

export function PeerRow(props: {
  knownPeerState: KnownPeerState;
  onConnectPress: (knownPeer: KnownPeer) => void;
  onDisconnectPress: (knownPeer: KnownPeer) => void;
  ownScripts: Map<string, { name: string; url: string }>;
  loadScript: (url: string) => void;
}) {
  const knownPeerState = props.knownPeerState;
  return (
    <div className={styles.peerRow} key={knownPeerState.knownPeer.userId}>
      <div className={styles.peerIdentity}>
        <PeerIcon userId={knownPeerState.knownPeer.userId} online={!!knownPeerState.onlineStatus} />
        <div className={styles.peerName}>{knownPeerState.knownPeer.displayName || "Unknown"}</div>
        <div>
          {knownPeerState.connectionStatus === KnownPeerConnectionStatus.Connected ? (
            <>
              <button
                className={styles.button}
                onClick={() => {
                  props.onDisconnectPress(knownPeerState.knownPeer);
                }}
              >
                Disconnect
              </button>
            </>
          ) : knownPeerState.onlineStatus ? (
            <button
              className={styles.button}
              onClick={() => {
                props.onConnectPress(knownPeerState.knownPeer);
              }}
            >
              Connect
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {knownPeerState.connectionStatus === KnownPeerConnectionStatus.Connected ? (
        <ScriptsList
          ownScripts={props.ownScripts}
          peerScripts={knownPeerState.scripts}
          loadScript={props.loadScript}
        />
      ) : null}
    </div>
  );
}
