export type SerializedMember = {
  userId: number;
  userOwnCounter: number;
};

export class GroupMember {
  public readonly userId: number;
  private userOwnCounter: number;

  constructor(userId: number, userOwnCounter: number) {
    this.userId = userId;
    this.userOwnCounter = userOwnCounter;
  }

  serialize(): SerializedMember {
    return {
      userId: this.userId,
      userOwnCounter: this.userOwnCounter,
    };
  }
}
