import { SerializedMember } from "./GroupMember";

export class ProposedGroupMember {
  public readonly userId: number;
  private proposerCounter: number;

  constructor(userId: number, proposerCounter: number) {
    this.userId = userId;
    this.proposerCounter = proposerCounter;
  }

  serialize(): SerializedMember {
    return {
      userId: this.userId,
      userOwnCounter: 0, //TODO - replace
    };
  }
}
