import * as React from "react";

import { GroupManager } from "../group-manager/GroupManager";
import { InvitedGroup } from "../group-manager/InvitedGroup";
import styles from "./InvitedGroupElement.module.css";
import { PeerGroupMember } from "./PeerGroupMember";

const joinTimeoutMs = 5000;

export function InvitedGroupElement(props: {
  invitedGroup: InvitedGroup;
  groupManager: GroupManager;
}) {
  const [joining, setJoining] = React.useState(false);

  return (
    <div className={styles.invitedGroup}>
      <div className={styles.inviteGroupId}>{props.invitedGroup.groupId.toString(10)}</div>
      <div className={styles.members}>
        {Array.from(props.invitedGroup.members).map(([, member]) => {
          const user = props.groupManager.usersById.get(member.userId);
          const displayName = user ? user.displayName : undefined;
          return (
            <PeerGroupMember key={member.userId} userId={member.userId} displayName={displayName} />
          );
        })}
      </div>
      <div className={styles.joinArea}>
        {joining ? (
          <div className={styles.loadingIndicator}></div>
        ) : (
          <button
            className={styles.button}
            onClick={() => {
              setJoining(true);
              setTimeout(() => {
                setJoining(false);
              }, joinTimeoutMs);
              props.groupManager.acceptInvite(props.invitedGroup);
            }}
          >
            Join
          </button>
        )}
      </div>
    </div>
  );
}
