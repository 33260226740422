export class MessagingIframe<IncomingMessage, OutgoingMessage> {
  public iframeElement: HTMLIFrameElement;
  private pageUrl: string;
  private onMessageCallback: (msg: IncomingMessage) => void;
  private messageListener: (e: MessageEvent) => void;

  static CreateAndAppendToBody<IncomingMessage, OutgoingMessage>(
    pageUrl: string,
    onMessageCallback: (msg: IncomingMessage) => void,
  ): MessagingIframe<IncomingMessage, OutgoingMessage> {
    const htmliFrameElement = document.createElement("iframe");
    htmliFrameElement.setAttribute("src", pageUrl);
    htmliFrameElement.style.display = "none";

    const iframe = new MessagingIframe<IncomingMessage, OutgoingMessage>(
      htmliFrameElement,
      pageUrl,
      onMessageCallback,
    );
    document.body.append(iframe.iframeElement);
    return iframe;
  }

  constructor(
    iframeElement: HTMLIFrameElement,
    pageUrl: string,
    onMessageCallback: (msg: IncomingMessage) => void,
  ) {
    this.iframeElement = iframeElement;
    this.pageUrl = pageUrl;
    this.onMessageCallback = onMessageCallback;
    this.setupPostMessageHandling();
    document.body.append(this.iframeElement);
  }

  private setupPostMessageHandling() {
    this.messageListener = (e: MessageEvent) => {
      if (e.source !== this.iframeElement.contentWindow) {
        console.debug("postMessage from unrecognized source", e);
        return;
      }
      this.onMessageCallback(e.data);
    };
    window.addEventListener("message", this.messageListener);
  }

  public sendMessage(message: OutgoingMessage) {
    this.iframeElement.contentWindow!.postMessage(message, this.pageUrl);
  }

  close() {
    window.removeEventListener("message", this.messageListener);
    this.iframeElement.remove();
  }
}
