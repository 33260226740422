import { ClosedSession, OngoingPeerConnection, SessionClosePeers } from "../postMessages";

const localStorageSessionKey = "peer-social-sessions";

export type SessionLocalStorage = {
  ongoingPeerConnections: Array<OngoingPeerConnection>;
  closedSessions: Array<ClosedSession>;
};

export class GlobalSessionStorage {
  public addClosedSession(time: number, closedSession: SessionClosePeers) {
    const globalStorage = this.getGlobalStorage();
    globalStorage.closedSessions.push({
      timestamp: time,
      peers: closedSession.peers,
    });
    this.saveGlobalStorage(globalStorage);
  }

  public getClosedSessions(): Array<ClosedSession> {
    return this.getGlobalStorage().closedSessions;
  }

  public static filterOngoingPeerConnections(
    ongoingPeerConnections: Array<OngoingPeerConnection>,
    earliestTime: number,
  ) {
    return ongoingPeerConnections.filter((ongoingPeerConnection) => {
      return ongoingPeerConnection.timestamp! >= earliestTime;
    });
  }

  public static filterClosedSessions(closedSessions: Array<ClosedSession>, earliestTime: number) {
    return closedSessions.filter((ongoingPeerConnection) => {
      return ongoingPeerConnection.timestamp >= earliestTime;
    });
  }

  private getGlobalStorage(): SessionLocalStorage {
    // TODO - validation
    const currentStorage = localStorage.getItem(localStorageSessionKey);
    let parsed: SessionLocalStorage;
    if (currentStorage === null) {
      parsed = {
        ongoingPeerConnections: [],
        closedSessions: [],
      };
    } else {
      parsed = JSON.parse(currentStorage);
      parsed = {
        ...parsed,
        ongoingPeerConnections: parsed.ongoingPeerConnections || [],
        closedSessions: parsed.closedSessions || [],
      };
      const earliestTime = new Date().getTime() - 60 * 1000; // One minute ago
      const filteredOngoingPeerConnections = GlobalSessionStorage.filterOngoingPeerConnections(
        parsed.ongoingPeerConnections || [],
        earliestTime,
      );
      if (parsed.ongoingPeerConnections.length !== filteredOngoingPeerConnections.length) {
        parsed.ongoingPeerConnections = filteredOngoingPeerConnections;
        this.saveGlobalStorage(parsed);
      }
      const filteredClosedSessions = GlobalSessionStorage.filterClosedSessions(
        parsed.closedSessions || [],
        earliestTime,
      );
      if (parsed.closedSessions.length !== filteredClosedSessions.length) {
        parsed.closedSessions = filteredClosedSessions;
        this.saveGlobalStorage(parsed);
      }
    }
    return parsed;
  }

  private saveGlobalStorage(globalStorage: SessionLocalStorage) {
    const serialised = JSON.stringify(globalStorage);
    console.log("saveGlobalStorage", serialised);
    localStorage.setItem(localStorageSessionKey, serialised);
  }
}
