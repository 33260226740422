import { autoJoinGroupParamName, autoJoinParamName } from "../common/constants";
import { KnownPeer } from "../postMessages";
import { parsePeerAddress } from "./admin/PeerAddress";
import { PeerSocialAdmin } from "./admin/PeerSocialAdmin";
import { ScriptIframe } from "./script-iframe/ScriptIframe";

window.addEventListener("load", () => {
  if (self === top) {
    PeerSocialAdmin.init();
  } else {
    const searchParams = new URLSearchParams(window.location.search);

    const navigationTimeStr = searchParams.get("navigationTime");
    let navigationTime: number | undefined = undefined;
    if (navigationTimeStr) {
      const parsed = parseFloat(navigationTimeStr);
      if (parsed) {
        navigationTime = parsed;
      }
    }

    const urlStr = searchParams.get("url");
    if (!urlStr) {
      throw new Error("No url param");
    }
    const url = new URL(urlStr);
    const origin = url.origin;

    const autoJoinPeerAddresses: Array<KnownPeer> = [];
    const autoJoinAddresses = searchParams.get(autoJoinParamName) || "";
    if (autoJoinAddresses) {
      const splitAddresses = autoJoinAddresses.split(",");
      if (splitAddresses.length > 0) {
        // There were script urls in the search parameters that are not currently running - offer to run them after autoJoinAddresses are loaded
        // TODO - currently assuming confirmation of auto-join intent here - this should be a confirmation from the user
        for (const address of splitAddresses) {
          const parsed = parsePeerAddress(address);
          if (parsed instanceof Error) {
            // TODO - handle
            alert(parsed);
          } else {
            autoJoinPeerAddresses.push({ ...parsed, token: "" });
          }
        }
      }
    }

    const autoJoinGroupIds: Array<number> = [];
    const autoJoinGroups = searchParams.get(autoJoinGroupParamName) || "";
    if (autoJoinGroups) {
      const splitGroups = autoJoinGroups.split(",");
      if (splitGroups.length > 0) {
        // There were script urls in the search parameters that are not currently running - offer to run them after autoJoinAddresses are loaded
        // TODO - currently assuming confirmation of auto-join intent here - this should be a confirmation from the user
        for (const groupIdStr of splitGroups) {
          const parsed = parseInt(groupIdStr);
          if (!parsed) {
            // TODO - handle
            console.error("Failed to parse group id", groupIdStr);
          } else {
            autoJoinGroupIds.push(parsed);
          }
        }
      }
    }

    if (autoJoinPeerAddresses.length > 1 || autoJoinGroupIds.length > 1) {
      alert("Multiple auto-join addresses not yet supported");
      return;
    }

    // This page is loaded in an iframe - this page should act as a storage access method for the parent
    ScriptIframe.init(origin, urlStr, navigationTime, autoJoinPeerAddresses[0], autoJoinGroupIds[0]);
  }
});
