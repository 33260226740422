import * as React from "react";

import { hashAndConvertToHsl, hslToRgb } from "./colors";
import styles from "./PeerIcon.module.css";

export function PeerIcon(props: { userId: number; online: boolean }) {
  const userIdStr = props.userId.toString(10);
  const rgbA = hslToRgb(hashAndConvertToHsl(userIdStr));
  const rgbB = hslToRgb(hashAndConvertToHsl(userIdStr.repeat(2)));
  const gradient = `linear-gradient(225deg, rgb(${rgbA[0]},${rgbA[1]},${rgbA[2]}) 0%, rgb(${rgbB[0]},${rgbB[1]},${rgbB[2]}) 100%)`;

  return (
    <div className={styles.peerIcon} style={{ background: gradient }}>
      <div
        className={[styles.onlineStatus, props.online ? styles.onlineStatusEnabled : ""].join(" ")}
      />
    </div>
  );
}
