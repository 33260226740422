import * as React from "react";

import { PeerIcon } from "../PeerList/PeerIcon/PeerIcon";
import styles from "./PeerGroupMember.module.css";

export function PeerGroupMember(props: { userId: number; displayName?: string }) {
  return (
    <div className={styles.peerGroupMember}>
      <PeerIcon userId={props.userId} online={true} />
      <div className={styles.peerName}>{props.displayName || "Unknown"}</div>
    </div>
  );
}
