import * as Netcode from "@meta-web/peer-social-common/src/netcode";
import {
  decodeMessage,
  encodeMessage,
  isMessageType,
  OnlineStatus,
} from "@meta-web/peer-social-common/src/netcode";

type UserContext = {
  userId: number;
  // connection?: UserConnection | null;
  // outgoingConnectionAttempt?: OutgoingConnection | null;
};

type SignallingServerRemoteContextProps = {
  onUserStatus(userId: number, online: OnlineStatus | null): void;
  onAnswer(remoteUserId: number, answer: string): void;
  onCandidate(remoteUserId: number, candidate: string): void;
};

export class SignallingServerRemoteContext {
  public readonly signallingServerUrl: string;
  private props: SignallingServerRemoteContextProps;

  private ws: WebSocket;
  private users = new Map<number, UserContext>();
  private messagesAwaitingConnection: Array<Uint8Array> | null = [];
  private interestedUserIds = new Set<number>();

  constructor(
    ownUserId: number,
    signallingServerUrl: string,
    props: SignallingServerRemoteContextProps,
  ) {
    this.props = props;
    this.signallingServerUrl = signallingServerUrl;
    this.ws = new WebSocket(`${signallingServerUrl}/remote`);
    this.ws.addEventListener("open", (e: Event) => {
      this.ws.send(
        encodeMessage(Netcode.messageNumbers.loginRemoteUser, {
          userId: ownUserId,
          server: signallingServerUrl,
          token: "", // TODO - token
          name: "Remote user name not set", //TODO - name
        }),
      );
      for (const queuedMessage of this.messagesAwaitingConnection!) {
        this.ws.send(queuedMessage);
      }
      this.messagesAwaitingConnection = null;
    });
    this.ws.addEventListener("message", async (event: MessageEvent) => {
      const decoded = decodeMessage(await event.data.arrayBuffer());
      if (isMessageType(Netcode.messageNumbers.error, decoded)) {
        console.error("SignallingServerRemoteContext.error", decoded);
      } else if (isMessageType(Netcode.messageNumbers.UserStatus, decoded)) {
        console.log("UserStatus", decoded);
        this.UserStatus(decoded.payload);
      } else if (
        isMessageType(Netcode.messageNumbers.initialAnswerFromServerToRemoteConnection, decoded)
      ) {
        console.log("initialAnswerFromServerToRemoteConnection", decoded);
        this.initialAnswerFromServerToRemoteConnection(decoded.payload);
      } else if (
        isMessageType(Netcode.messageNumbers.iceNegotiationFromServerToRemoteConnection, decoded)
      ) {
        console.log("iceNegotiationFromServerToRemoteConnection", decoded);
        this.iceNegotiationFromServerToRemoteConnection(decoded.payload);
      }
    });
  }

  public addInterestedUsers(userIdAndTokens: Array<{ userId: number; token: string }>) {
    for (const userIdAndToken of userIdAndTokens) {
      this.interestedUserIds.add(userIdAndToken.userId);
    }
    this.send(
      encodeMessage(Netcode.messageNumbers.AddStatusCheck, {
        userIdAndTokens,
      }),
    );
  }

  public removeInterestedUsers(userIds: Array<number>) {
    for (const userId of userIds) {
      this.interestedUserIds.add(userId);
    }
    this.send(
      encodeMessage(Netcode.messageNumbers.RemoveStatusCheck, {
        userIds,
      }),
    );
  }

  private UserStatus(payload: Netcode.MessagePayloads[Netcode.messageNumbers.UserStatus]) {
    for (const user of payload.users) {
      console.log("UserStatus", user);
      this.props.onUserStatus(user.userId, user.online || null);
    }
  }

  public hasInterestedUsers() {
    return this.interestedUserIds.size > 0;
  }

  close() {
    this.ws.close();
  }

  private initialAnswerFromServerToRemoteConnection(
    payload: Netcode.MessagePayloads[Netcode.messageNumbers.initialAnswerFromServerToRemoteConnection],
  ) {
    this.props.onAnswer(payload.connectedUserId, payload.answer);
  }

  private iceNegotiationFromServerToRemoteConnection(
    payload: Netcode.MessagePayloads[Netcode.messageNumbers.iceNegotiationFromServerToRemoteConnection],
  ) {
    this.props.onCandidate(payload.connectedUserId, payload.ice);
  }

  private send(msg: Uint8Array) {
    if (this.messagesAwaitingConnection !== null) {
      this.messagesAwaitingConnection.push(msg);
    } else {
      this.ws.send(msg);
    }
  }

  sendOffer(userId: number, offer: string) {
    this.send(
      encodeMessage(Netcode.messageNumbers.initialOfferFromRemoteConnectionToServer, {
        connectedUserId: userId,
        offer,
      }),
    );
  }

  sendCandidate(userId: number, ice: string) {
    this.send(
      encodeMessage(Netcode.messageNumbers.iceNegotiationFromRemoteConnectionToServer, {
        connectedUserId: userId,
        ice,
      }),
    );
  }
}
