import * as React from "react";

import { KnownPeer } from "../../postMessages";
import { PeerRow } from "./PeerRow";
import styles from "./PeersPanel.module.css";
import { KnownPeerState } from "./ScriptIframe";

export function PeersPanel(props: {
  onConnectPress: (knownPeer: KnownPeer) => void;
  onDisconnectPress: (knownPeer: KnownPeer) => void;
  knownPeerStates: Map<number, KnownPeerState>;
  ownScripts: Map<string, { name: string; url: string }>;
  loadScript: (url: string) => void;
}) {
  const sortedPeers = Array.from(props.knownPeerStates.values()).sort((a, b) => {
    if (a.onlineStatus) {
      if (b.onlineStatus) {
        return a.onlineStatus.name.localeCompare(b.onlineStatus.name);
      } else {
        return -1;
      }
    } else if (b.onlineStatus) {
      if (!a.onlineStatus) {
        return 1;
      }
    }
    return a.knownPeer.userId - b.knownPeer.userId;
  });

  return (
    <div className={styles.peersPanel}>
      <div className={styles.sectionHeader}>Peers</div>
      <div className={styles.peersList}>
        {sortedPeers.map((knownPeerState) => (
          <PeerRow
            key={knownPeerState.knownPeer.userId}
            knownPeerState={knownPeerState}
            onConnectPress={props.onConnectPress}
            onDisconnectPress={props.onDisconnectPress}
            ownScripts={props.ownScripts}
            loadScript={props.loadScript}
          />
        ))}
      </div>
    </div>
  );
}
