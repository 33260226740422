
const digest = '8e0262bc31e668b18f4bb4da7ad34ec35e6f1bd680c7431603b693bcc7dd6857';
const css = `._section-header_xpwpp_1 {
    color: #787878;
    padding-top: 10px;
    padding-bottom: 0;
}._button_xchsj_1 {
    cursor: pointer;
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
}

._group_x30z6_1 {
    margin-bottom: 10px;
    border-radius: 8px;
    background: #F2F2F2;
    padding: 10px;
    padding-top: 15px;
    position: relative;
}

._group-id_x30z6_10 {
    position: absolute;
    top:0;
    left:0;
    padding: 4px;
    font-size: 10px;
}

._group-header_x30z6_18 {
}

._members_x30z6_22 {
}

._invitees_x30z6_25 {
    display: flex;
}

._button-section_x30z6_29 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

._button_x30z6_29 {
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"group":"_group_x30z6_1","group-id":"_group-id_x30z6_10","groupId":"_group-id_x30z6_10","group-header":"_group-header_x30z6_18 _section-header_xpwpp_1","groupHeader":"_group-header_x30z6_18 _section-header_xpwpp_1","members":"_members_x30z6_22","invitees":"_invitees_x30z6_25","button-section":"_button-section_x30z6_29","buttonSection":"_button-section_x30z6_29","button":"_button_x30z6_29 _button_xchsj_1"};
export { css, digest };
  