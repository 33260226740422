
const digest = 'f6ab538607d1fbd9d3b903dfa5c3a7b42474be48c8d0129ef7fe016ac2c83ff9';
const css = `._button_xchsj_1 {
    cursor: pointer;
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
}

._peer-scripts-list_1ac15_1 {
    margin-top: 4px;
}

._peer-script-row_1ac15_5 {
    border-radius: 8px;
    padding: 5px;
    font-size: 12px;
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
}

._both_1ac15_16 {
    background-color: #33AA33;
}

._own_1ac15_20 {
    background-color: yellow;
    color: black;
}

._peer_1ac15_1 {
    background-color: #AA3333;
}

._button_1ac15_29 {
}

._script-state_1ac15_33 {
    padding: 10px;
}

._script-row_1ac15_37 {
    padding: 5px;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"peer-scripts-list":"_peer-scripts-list_1ac15_1","peerScriptsList":"_peer-scripts-list_1ac15_1","peer-script-row":"_peer-script-row_1ac15_5","peerScriptRow":"_peer-script-row_1ac15_5","both":"_both_1ac15_16","own":"_own_1ac15_20","peer":"_peer_1ac15_1","button":"_button_1ac15_29 _button_xchsj_1","script-state":"_script-state_1ac15_33","scriptState":"_script-state_1ac15_33","script-row":"_script-row_1ac15_37","scriptRow":"_script-row_1ac15_37"};
export { css, digest };
  