import { ez, EZDiv, EZElement } from "ez-elements";

import styles from "./Tabs.module.css";

type TabState = {
  title: EZDiv;
  contents: EZElement<any>;
};

export const DefaultTabsClasses = {
  element: styles.tabs,
  tab: styles.tab,
  activeTab: styles.activeTab,
  tabTitleHolder: styles.tabTitleHolder,
  tabContents: styles.tabContents,
};

type TabClasses = Partial<typeof DefaultTabsClasses>;

export class Tabs extends EZDiv {
  private tabsTitlesHolder: EZElement<"div">;
  private tabsContents: EZElement<"div">;
  private tabs = new Map<string, TabState>();
  private currentTab: TabState | null = null;
  private classes: typeof DefaultTabsClasses;

  constructor(tabs: Array<[string, EZElement<any>]> = [], options: { classes?: TabClasses } = {}) {
    super();

    this.classes = {
      ...DefaultTabsClasses,
      ...options.classes,
    };

    this.addClass(this.classes.element).append(
      (this.tabsTitlesHolder = ez("div", this.classes.tabTitleHolder)),
      (this.tabsContents = ez("div", this.classes.tabContents)),
    );

    for (const [title, content] of tabs) {
      this.addTab(title, content);
    }
  }

  addTab(title: string, content: EZElement<any>): this {
    const tabState = {
      title: ez("div", this.classes.tab)
        .setTextContent(title)
        .onClick(() => {
          this.setTab(title);
        }),
      contents: content,
    };
    if (this.tabs.size === 0) {
      // This is the first tab - make it visible
      this.tabsContents.append(content);
      tabState.title.addClass(this.classes.activeTab);
      this.currentTab = tabState;
    }
    this.tabsTitlesHolder.append(tabState.title);
    this.tabs.set(title, tabState);

    return this;
  }

  private setTab(title: string): this {
    const tabState = this.tabs.get(title);
    if (!tabState) {
      throw new Error(`No tab found for title: ${title}`);
    }
    if (this.currentTab === tabState) {
      return this;
    }
    if (this.currentTab) {
      this.currentTab.title.removeClass(this.classes.activeTab);
    }

    this.currentTab = tabState;
    this.currentTab.title.addClass(this.classes.activeTab);
    this.tabsContents.applyChildren([this.currentTab.contents]);
    return this;
  }
}
