import * as React from "react";
import { NavLink, Outlet } from "react-router-dom";

import styles from "./App.module.css";

export function App() {
  return (
    <div className={styles.app}>
      <div className={styles.peerSocialHeader}>
        <div className={styles.peerSocialTitle}>Peer Social</div>
      </div>
      <div className={styles.tabs}>
        <div className={styles.tabTitleHolder}>
          <NavLink
            className={(props) => {
              return [styles.tab, props.isActive ? styles.activeTab : ""].join(" ");
            }}
            to="/"
          >
            Peers
          </NavLink>
          <NavLink
            className={(props) => {
              return [styles.tab, props.isActive ? styles.activeTab : ""].join(" ");
            }}
            to="/groups/"
          >
            Groups
          </NavLink>
        </div>
      </div>
      <div className={styles.tabContents}>
        <Outlet />
      </div>
    </div>
  );
}
