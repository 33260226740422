export type RPCHandler<serviceDefinition extends ServiceDefinition, methods extends MethodsFor<serviceDefinition>> = (
  request: RequestFor<serviceDefinition, methods>,
) => Promise<ResponseFor<serviceDefinition, methods>>;

export type ServiceHandlerFor<serviceDefinition extends ServiceDefinition> = {
  [method in MethodsFor<serviceDefinition>]: any; //RPCHandler<serviceDefinition, method>;
};

export type CallableService = {
  call: (method: string, req: unknown) => Promise<unknown>;
};

export type ServerEventDefinition = {
  [type: string]: any;
};

type InferRequest<A> = A extends { request: infer B } ? B : never;
type InferResponse<A> = A extends { response: infer B } ? B : never;

export function defineRPC<Def extends { request: any; response: any }>() {
  return { request: {} as InferRequest<Def>, response: {} as InferResponse<Def> };
}

export type ServiceDefinition = {
  [method: string]: { request: any; response: any };
};

export type MethodsFor<serviceDefinition extends ServiceDefinition> = Extract<keyof serviceDefinition, string>;

export type RequestFor<
  serviceDefinition extends ServiceDefinition,
  method extends MethodsFor<serviceDefinition>,
> = serviceDefinition[method]["request"];

export type ResponseFor<
  serviceDefinition extends ServiceDefinition,
  method extends MethodsFor<serviceDefinition>,
> = serviceDefinition[method]["response"];

export type ValueOf<T> = T[keyof T];

export type RPCsFor<serviceDefinition extends ServiceDefinition> = Extract<
  ValueOf<{
    [method in keyof serviceDefinition]: method;
  }>,
  string
>;

export type ServiceMethodMap<serviceDefinition extends ServiceDefinition> = {
  [method in RPCsFor<serviceDefinition>]: RPCHandler<serviceDefinition, method>;
};

function mapValuesWithStringKeys<T extends object, V>(
  o: T,
  fn: (value: ValueOf<T>, key: Extract<keyof T, string>, o: T) => V,
): { [key in Extract<keyof T, string>]: V } {
  const res = {} as { [key in Extract<keyof T, string>]: V };
  for (const k in o) {
    if (o.hasOwnProperty(k)) {
      res[k] = fn(o[k], k, o);
    }
  }
  return res;
}

export function createRpcClient<serviceDefinition extends ServiceDefinition>(
  callableService: CallableService,
  definition: serviceDefinition,
): ServiceMethodMap<serviceDefinition> {
  return mapValuesWithStringKeys(definition, (methodDefinition, method) => {
    return async (request: any) => {
      return await callableService.call(method as any, request);
    };
  });
}
