import * as React from "react";
import { Link } from "react-router-dom";

import { autoJoinGroupParamName, autoJoinParamName } from "../../../common/constants";
import { getDefaultSignallingServerHost } from "../../../host";
import { Group } from "../group-manager/Group";
import { GroupManager } from "../group-manager/GroupManager";
import styles from "./GroupElement.module.css";
import { PeerGroupMember } from "./PeerGroupMember";

export function GroupElement(props: {
  ownUserId: number;
  ownDisplayName: string;
  currentUrl: string;
  scriptSrc: string;
  group: Group;
  groupManager: GroupManager;
}) {
  return (
    <div className={styles.group}>
      <div className={styles.groupId}>{props.group.groupId.toString(10)}</div>
      <div className={styles.groupHeader}>Members</div>
      <div className={styles.members}>
        <PeerGroupMember key={"self"} userId={props.ownUserId} displayName={props.ownDisplayName} />
        {Array.from(props.group.members).map(([, member]) => {
          const user = props.groupManager.usersById.get(member.userId);
          if (user) {
            return (
              <PeerGroupMember
                key={user.userId}
                userId={user.userId}
                displayName={user.displayName}
              />
            );
          }
          return null;
        })}
      </div>
      {props.group.ownProposedMembers.size > 0 && (
        <>
          <div className={styles.groupHeader}>Invited</div>
          <div className={styles.invitees}>
            {Array.from(props.group.ownProposedMembers).map(([, proposedMember]) => {
              const user = props.groupManager.usersById.get(proposedMember.userId);
              if (user) {
                return (
                  <PeerGroupMember
                    key={proposedMember.userId}
                    userId={user.userId}
                    displayName={user.displayName}
                  />
                );
              }
              return null;
            })}
          </div>
        </>
      )}
      <div className={styles.buttonSection}>
        <Link to={`/groups/${props.group.groupId}/invite`}>
          <button className={styles.button}>Invite</button>
        </Link>
        <button
          className={styles.button}
          onClick={() => {
            const url = new URL(props.currentUrl);
            // Wholly replace the search params (to avoid accidentally leaking keys or similar)
            const postSearchParams = new URLSearchParams();
            postSearchParams.set("scriptsV0", props.scriptSrc);
            postSearchParams.set(
              autoJoinParamName,
              `${getDefaultSignallingServerHost()}?${props.ownUserId}`,
            );
            // TODO - include token?
            postSearchParams.set(autoJoinGroupParamName, `${props.group.groupId.toString(10)}`);
            url.search = postSearchParams.toString();
            const joinLink = url.href;
            console.warn("joinLink", joinLink);
            navigator.clipboard.writeText(joinLink).then(
              () => {
                console.log("Did copy to clipboard");
              },
              (err) => {
                console.error("Failed to copy to clipboard", err);
              },
            );
          }}
        >
          Copy Group Join Link
        </button>
      </div>
    </div>
  );
}
