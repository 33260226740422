import { defineRPC } from "@meta-web/cross-domain-rpc";

import { SerializedGroup } from "./remote-page/script-iframe/group-manager/Group";

export type ClosedSession = {
  timestamp: number;
  peers: Array<OngoingPeerConnection>;
};

export type SessionClosePeers = {
  peers: Array<OngoingPeerConnection>;
};

export type OngoingPeerConnection = {
  knownPeer: KnownPeer;
  outgoingConnection: boolean;
  timestamp?: number;
};

export type KnownPeer = {
  userId: number;
  server: string;
  token: string;
  displayName?: string;
};

export function knownPeersEqual(a: KnownPeer, b: KnownPeer): boolean {
  return a.userId === b.userId && a.server === b.server && a.token === b.token;
}

// TODO - this should be a public/private key-defined identity and should include a signed, timestamped token that the script running in the root experience
// uses to communicate with the signalling server that indicates its origin and that it's allowed to connect to the signalling server.
export type StoredIdentity = {
  id: number;
  username: string;
  server: string;
};

export const PeerSocialServiceDefinition = {
  peerConnected: defineRPC<{
    request: KnownPeer;
    response: { acknowledged: boolean };
  }>(),
  peerDisconnected: defineRPC<{
    request: KnownPeer;
    response: { acknowledged: boolean };
  }>(),

  peerSetIdentity: defineRPC<{
    request: {
      peer: KnownPeer;
      displayName: string;
    };
    response: { acknowledged: boolean };
  }>(),

  peerScriptAdded: defineRPC<{
    request: {
      peer: KnownPeer;
      script: {
        url: string;
        name: string;
      };
    };
    response: { acknowledged: boolean };
  }>(),
  peerScriptRemoved: defineRPC<{
    request: {
      peer: KnownPeer;
      scriptUrl: string;
    };
    response: { acknowledged: boolean };
  }>(),

  addOwnLoadedScript: defineRPC<{
    request: {
      name: string;
      url: string;
    };
    response: { acknowledged: boolean };
  }>(),
  removeOwnLoadedScript: defineRPC<{
    request: {
      url: string;
    };
    response: { acknowledged: boolean };
  }>(),

  closedSession: defineRPC<{
    request: {
      time: number;
    };
    response: { acknowledged: boolean };
  }>(),

  changedUrl: defineRPC<{
    request: {
      url: string;
    };
    response: { acknowledged: boolean };
  }>(),

  receivedInvite: defineRPC<{
    request: { userId: number; group: SerializedGroup };
    response: { acknowledged: boolean };
  }>(),
  remoteAcceptedInvite: defineRPC<{
    request: { userId: number; groupId: number };
    response: { acknowledged: boolean };
  }>(),
  receivedGroupUpdate: defineRPC<{
    request: { userId: number; group: SerializedGroup };
    response: { acknowledged: boolean };
  }>(),
  receivedAutoJoinGroup: defineRPC<{
    request: { userId: number; groupId: number };
    response: { acknowledged: boolean };
  }>(),

  initialOfferFromRemoteConnectionToServer: defineRPC<{
    request: {
      connectedUserId: number;
      offer: string;
    };
    response: { acknowledged: boolean };
  }>(),
  iceNegotiationFromRemoteConnectionToServer: defineRPC<{
    request: {
      connectedUserId: number;
      ice: string;
    };
    response: { acknowledged: boolean };
  }>(),

  initialAnswerFromConnectedUserToServer: defineRPC<{
    request: {
      remoteConnectionAttemptId: number;
      answer: string;
    };
    response: { acknowledged: boolean };
  }>(),
  iceNegotiationFromConnectedUserToServer: defineRPC<{
    request: {
      remoteConnectionAttemptId: number;
      ice: string;
    };
    response: { acknowledged: boolean };
  }>(),
};

export type PeerSocialRemoteEvent = {
  storedIdentity?: StoredIdentity;
  connectToPeer?: KnownPeer;
  disconnectFromPeer?: KnownPeer;

  incomingConnectionOffer?: {
    remoteConnectionAttemptId: number;
    knownPeer: KnownPeer;
    offer: string;
  };
  incomingConnectionCandidate?: {
    remoteConnectionAttemptId: number;
    userId: number;
    candidate: string;
  };

  outgoingConnectionAnswer?: {
    userId: number;
    answer: string;
  };
  outgoingConnectionCandidate?: {
    userId: number;
    candidate: string;
  };

  sendAcceptGroupInvite?: { userId: number; groupId: number };
  sendGroupUpdate?: { userId: number; group: SerializedGroup };
  sendGroupInvite?: { userId: number; group: SerializedGroup };
  sendAcceptAutoJoinGroupInvite?: {
    userId: number;
    groupId: number;
  };

  loadScript?: { url: string };
};
