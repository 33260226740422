
const digest = '4dd89321dd5a1d1a9c26f7b6f6d148598844e86a4c29066f9d9689d3f2fa12da';
const css = `._button_xchsj_1 {
    cursor: pointer;
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
}

._peer-row_1hig8_1 {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
}

._peer-identity_1hig8_7 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

._peer-name_1hig8_13 {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    padding: 4px;
}

._button_1hig8_20 {
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"peer-row":"_peer-row_1hig8_1","peerRow":"_peer-row_1hig8_1","peer-identity":"_peer-identity_1hig8_7","peerIdentity":"_peer-identity_1hig8_7","peer-name":"_peer-name_1hig8_13","peerName":"_peer-name_1hig8_13","button":"_button_1hig8_20 _button_xchsj_1"};
export { css, digest };
  