
const digest = 'd3cda77d6e77dd31a1006f6933da863cb66fe759728770c542ee29a2e2b1cd5d';
const css = `._peer-icon_53x5q_1 {
    position: relative;
    border-radius: 20px;
    background-color: white;
    font-size: 20px;
    color: white;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

._online-status_53x5q_16 {
    position: absolute;
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    bottom: 0;
    right: 0;
}

._online-status-enabled_53x5q_26 {
    background-color: #2FFF5D;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"peer-icon":"_peer-icon_53x5q_1","peerIcon":"_peer-icon_53x5q_1","online-status":"_online-status_53x5q_16","onlineStatus":"_online-status_53x5q_16","online-status-enabled":"_online-status-enabled_53x5q_26","onlineStatusEnabled":"_online-status-enabled_53x5q_26"};
export { css, digest };
  