import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GroupManager } from "../group-manager/GroupManager";
import styles from "./InviteModal.module.css";
import { PeerGroupMember } from "./PeerGroupMember";

export function InviteModalElement(props: { groupManager: GroupManager }) {
  const [invitees, setInvitees] = React.useState(new Set<number>());
  const navigate = useNavigate();

  const { groupId } = useParams();
  const groupIdNumber = parseInt(groupId || "");
  const group = props.groupManager.groups.get(groupIdNumber);
  if (!group) {
    return <div>Error loading group</div>;
  }

  const existingMembers = Array.from(group.members).map(([, member]) => {
    return props.groupManager.usersById.get(member.userId) || member.userId;
  });

  const proposedMembers = Array.from(group.ownProposedMembers).map(([, member]) => {
    return props.groupManager.usersById.get(member.userId)!;
  });

  const uninvitedUsers = Array.from(props.groupManager.usersById.values()).filter(
    (knownPeer) =>
      !(group.members.has(knownPeer.userId) || group.ownProposedMembers.has(knownPeer.userId)),
  );

  return (
    <div>
      <div>Invite To Group</div>
      <div>Select others that you&apos;re already connected to to add to the group</div>
      <div className={styles.contents}>
        <div className={styles.selectAllHolder}>
          <button
            className={styles.button}
            onClick={() => {
              for (const [userId, user] of props.groupManager.usersById) {
                if (!group.members.has(userId)) {
                  invitees.add(userId);
                }
              }
              setInvitees(new Set(invitees));
            }}
          >
            Select All
          </button>
        </div>
        <div className={styles.usersList}>
          {uninvitedUsers.length === 0 ? (
            <div className={styles.noUninvitedMessages}>No uninvited peers connected</div>
          ) : (
            uninvitedUsers.map((knownPeer) => {
              const userId = knownPeer.userId;
              return (
                <label key={userId} className={styles.inviteRow}>
                  <PeerGroupMember
                    key={userId}
                    userId={userId}
                    displayName={knownPeer.displayName}
                  />
                  <input
                    className={styles.inviteCheckbox}
                    type={"checkbox"}
                    checked={invitees.has(userId)}
                    disabled={false}
                    onChange={(val) => {
                      if (val.target.checked) {
                        invitees.add(userId);
                      } else {
                        invitees.delete(userId);
                      }
                      setInvitees(new Set(invitees));
                    }}
                  />
                </label>
              );
            })
          )}
          {proposedMembers.map((knownPeer) => {
            const userId = knownPeer.userId;
            return (
              <label key={userId} className={styles.inviteRow}>
                <PeerGroupMember key={userId} userId={userId} displayName={knownPeer.displayName} />
                <input
                  className={styles.inviteCheckbox}
                  type={"checkbox"}
                  checked={true}
                  disabled={true}
                />
              </label>
            );
          })}
        </div>
        {existingMembers.length > 0 && (
          <>
            <div className={styles.sectionHeader}>Existing Group Members</div>
            <div className={styles.existingMembersList}>
              {existingMembers.map((knownPeer) => {
                if (typeof knownPeer === "number") {
                  return (
                    <PeerGroupMember key={knownPeer} userId={knownPeer} displayName={"Unknown"} />
                  );
                }
                const userId = knownPeer.userId;
                return (
                  <PeerGroupMember
                    key={userId}
                    userId={userId}
                    displayName={knownPeer.displayName}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      <button
        className={styles.button}
        onClick={() => {
          props.groupManager.inviteNewMembersToGroup(
            group.groupId,
            Array.from(invitees).filter((userId) => !group.members.has(userId)),
          );
          navigate("/groups/");
        }}
      >
        Send Invites
      </button>
    </div>
  );
}
