
const digest = '87a4829020de2de5cac2f9daf890aa84955c14657a8e83668b34f6155550365e';
const css = `._section-header_xpwpp_1 {
    color: #787878;
    padding-top: 10px;
    padding-bottom: 0;
}._button_xchsj_1 {
    cursor: pointer;
    background: #FFFFFF;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
}

._contents_cq5fp_1 {
    margin-bottom: 10px;
    border-radius: 8px;
    background: #F2F2F2;
    padding: 10px;
    padding-top: 15px;
    position: relative;
}

._users-list_cq5fp_10 {

}

._no-uninvited-messages_cq5fp_14 {
    text-align: center;
}

._existing-members-list_cq5fp_18 {

}

._select-all-holder_cq5fp_22 {
    display: flex;
    justify-content: flex-end;
}

._select-all-button_cq5fp_27 {
    border: none;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.9);
    background: none;
    text-transform: uppercase;
}

._invite-row_cq5fp_38 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

._invite-checkbox_cq5fp_46 {
    flex-grow: 0;
    margin-right: 10px;
}

._section-header_cq5fp_51 {
}

._button_cq5fp_55 {
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"contents":"_contents_cq5fp_1","users-list":"_users-list_cq5fp_10","usersList":"_users-list_cq5fp_10","no-uninvited-messages":"_no-uninvited-messages_cq5fp_14","noUninvitedMessages":"_no-uninvited-messages_cq5fp_14","existing-members-list":"_existing-members-list_cq5fp_18","existingMembersList":"_existing-members-list_cq5fp_18","select-all-holder":"_select-all-holder_cq5fp_22","selectAllHolder":"_select-all-holder_cq5fp_22","select-all-button":"_select-all-button_cq5fp_27","selectAllButton":"_select-all-button_cq5fp_27","invite-row":"_invite-row_cq5fp_38","inviteRow":"_invite-row_cq5fp_38","invite-checkbox":"_invite-checkbox_cq5fp_46","inviteCheckbox":"_invite-checkbox_cq5fp_46","section-header":"_section-header_cq5fp_51 _section-header_xpwpp_1","sectionHeader":"_section-header_cq5fp_51 _section-header_xpwpp_1","button":"_button_cq5fp_55 _button_xchsj_1"};
export { css, digest };
  