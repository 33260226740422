import { KnownPeer } from "../../../postMessages";
import { Group, SerializedGroup } from "./Group";
import { InvitedGroup } from "./InvitedGroup";

export function generateRandomId(): number {
  return Math.ceil(Math.random() * Number.MAX_SAFE_INTEGER - 1) + 1;
}

export type GroupManagerInterface = {
  sendProposal(userId: number, group: SerializedGroup): void;
  sendGroupUpdate(userId: number, group: SerializedGroup): void;
  connectToUser(userId: number): void;
  didUpdateGroup(group: Group): void;
};

type GroupManagerProps = {
  onNewInviteGroup: (invitedGroup: InvitedGroup) => void;
  onUpdateInviteGroup: (invitedGroup: InvitedGroup) => void;
  onDeleteInviteGroup: (invitedGroup: InvitedGroup) => void;
  onNewGroup: (group: Group) => void;
  onUpdateGroup: (group: Group) => void;
  onDeleteGroup: (group: Group) => void;
  connectToUser: (userId: number) => void;

  sendGroupInvite: (userId: number, group: SerializedGroup) => void;
  sendGroupUpdate: (userId: number, group: SerializedGroup) => void;
  sendAcceptGroupInvite: (userId: number, groupId: number) => void;
};

export class GroupManager implements GroupManagerInterface {
  private ownUserId: number;
  private props: GroupManagerProps;

  public usersById = new Map<number, KnownPeer>();
  public groups = new Map<number, Group>();
  public receivedInvites = new Map<number, InvitedGroup>();

  constructor(ownUserId: number, props: GroupManagerProps) {
    this.ownUserId = ownUserId;
    this.props = props;
  }

  public receivedInvite(remoteUserId: number, serializedGroup: SerializedGroup) {
    // TODO - handle receiving this multiple times as the group is updated during invite phase
    console.warn("receivedInvite", serializedGroup);
    const connectionEntry = this.usersById.get(remoteUserId);
    if (!connectionEntry) {
      console.error("Received invite from missing connection", remoteUserId);
      return;
    }
    const groupId = serializedGroup.groupId;
    let invitedGroup = this.receivedInvites.get(groupId);
    if (invitedGroup) {
      invitedGroup.applySerializedGroup(serializedGroup);
    } else {
      invitedGroup = new InvitedGroup(remoteUserId, serializedGroup);
      this.receivedInvites.set(invitedGroup.groupId, invitedGroup);
      this.props.onNewInviteGroup(invitedGroup);
    }
  }

  public addPeerConnection(knownPeer: KnownPeer) {
    this.usersById.set(knownPeer.userId, knownPeer);

    for (const [groupId, group] of this.groups) {
      if (group.members.has(knownPeer.userId)) {
        this.sendGroupUpdate(knownPeer.userId, group.serialize());
      } else if (group.ownProposedMembers.has(knownPeer.userId)) {
        this.sendProposal(knownPeer.userId, group.serialize());
      }
    }
  }

  public removePeerConnection(remoteUserId: number) {
    const knownPeer = this.usersById.get(remoteUserId);
    if (!knownPeer) {
      throw new Error("Missing connection to remove");
    }
    const userId = knownPeer.userId;
    this.usersById.delete(remoteUserId);

    // TODO - Check for invites and groups that this user was a part of
    for (const [groupId, invitedGroup] of this.receivedInvites) {
      if (invitedGroup.inviterUserId === userId) {
        // The disconnecting user was the inviter - remove the group
        this.receivedInvites.delete(groupId);
        this.props.onDeleteInviteGroup(invitedGroup);
      }
    }
  }

  public sendProposal(userId: number, group: SerializedGroup): void {
    this.props.sendGroupInvite(userId, group);
  }

  public connectToUser(userId: number): void {
    this.props.connectToUser(userId);
  }

  public sendGroupUpdate(userId: number, group: SerializedGroup): void {
    this.props.sendGroupUpdate(userId, group);
  }

  public createNewGroup(): Group {
    const id = generateRandomId();
    const group = new Group(this.ownUserId, id, this);
    this.groups.set(id, group);
    this.props.onNewGroup(group);
    return group;
  }

  public didUpdateGroup(group: Group) {
    this.props.onUpdateGroup(group);
  }

  public acceptInvite(invitedGroup: InvitedGroup) {
    console.warn("acceptInvite", invitedGroup);
    if (this.receivedInvites.get(invitedGroup.groupId) !== invitedGroup) {
      throw new Error("Missing invited group in map");
    }

    const userId = invitedGroup.inviterUserId;
    this.props.sendAcceptGroupInvite(userId, invitedGroup.groupId);
  }

  public inviteNewMembersToGroup(groupId: number, userIds: Array<number>) {
    const group = this.groups.get(groupId);
    if (!group) {
      throw new Error(`No group with id: ${groupId}`);
    }

    group.inviteUsers(userIds);
    this.props.onUpdateGroup(group);
  }

  remoteAcceptedInvite(remoteUserId: number, groupId: number) {
    const group = this.groups.get(groupId);
    if (!group) {
      throw new Error(`No group with id: ${groupId}`);
    }

    const knownPeer = this.usersById.get(remoteUserId);
    if (!knownPeer) {
      console.error("Received invite accept from missing connection", remoteUserId);
      return;
    }
    const userId = knownPeer.userId;

    group.remoteAcceptedInvite(userId);
    this.props.onUpdateGroup(group);
  }

  receivedGroupUpdate(remoteUserId: number, serializedGroup: SerializedGroup) {
    console.log("receivedGroupUpdate", serializedGroup);
    const groupId = serializedGroup.groupId;
    let group = this.groups.get(groupId);
    if (group) {
      group.applySerializedGroup(serializedGroup);
      this.props.onUpdateGroup(group);
    } else {
      const invitedGroup = this.receivedInvites.get(groupId);
      if (invitedGroup) {
        console.log(`Received group with id: ${groupId}. Removing invite with the same id`);
        this.receivedInvites.delete(groupId);
        this.props.onDeleteInviteGroup(invitedGroup);
      }
      console.log(`No group with id: ${groupId}. Creating.`);
      // TODO - only create the group if this user is a member
      group = new Group(this.ownUserId, groupId, this);
      group.applySerializedGroup(serializedGroup);
      this.groups.set(groupId, group);
      this.props.onNewGroup(group);
    }
  }

  receivedAutoJoinGroup(remoteUserId: number, groupId: number) {
    const group = this.groups.get(groupId);
    if (!group) {
      throw new Error(`No group with id: ${groupId}`);
    }

    const knownPeer = this.usersById.get(remoteUserId);
    if (!knownPeer) {
      console.error("Received autojoin invite accept from missing connection", remoteUserId);
      return;
    }
    const userId = knownPeer.userId;

    group.remoteAcceptedAutoJoin(userId);
    this.props.onUpdateGroup(group);
  }

  public getSerializedGroups() {
    const serializedGroups: Array<SerializedGroup> = [];
    for (const [groupId, group] of this.groups) {
      serializedGroups.push(group.serialize());
    }
    return serializedGroups;
  }
}
