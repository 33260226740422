import { Tabs } from "@meta-web/tabs";
import { ez, EZDiv, EZElement, EZTextInput } from "ez-elements";

import { allowHostParam } from "../../common/constants";
import { TabStyles } from "../../common/TabStyles";
import { getDefaultSignallingServerHost } from "../../host";
import { GlobalIdentityStorage } from "../GlobalIdentityStorage";
import { parsePeerAddress } from "./PeerAddress";
import styles from "./PeerSocialAdmin.module.css";

export class PeerSocialAdmin extends EZElement<"div"> {
  private storage: GlobalIdentityStorage;
  private recentAttemptsList: EZElement<"div">;
  private allowedHostsList: EZElement<"div">;

  private idDisplay: EZElement<"div">;
  private ownPeerAddress: EZElement<"div">;

  private usernameInput: EZTextInput;
  private usernameConfirmButton: EZElement<"button">;
  private latestUsername: string | null;

  private peerInput: EZTextInput;
  private peerConfirmButton: EZElement<"button">;
  private knownPeersList: EZElement<"div">;
  private latestPeerAddress = "";

  static init() {
    const peerSocialAdmin = new PeerSocialAdmin();
    peerSocialAdmin.appendTo(document.body);
    return peerSocialAdmin;
  }

  private constructor() {
    super("div");
    this.storage = new GlobalIdentityStorage();
    this.latestUsername = this.storage.getUsername();

    const searchParams = new URLSearchParams(window.location.search);
    const allowHostAddressPrompt = searchParams.get(allowHostParam);
    let promptElement: EZDiv | null = null;
    if (allowHostAddressPrompt) {
      let host = allowHostAddressPrompt;
      if (host.substring(host.length - 1, 1) !== "/") {
        host = host + "/";
        promptElement = ez("div", styles["allow-host-prompt"]).append(
          ez("div").append(
            ez("span", styles["prompt-hostname"]).setTextContent(`"${host}"`),
            ez("span").setTextContent(
              " has requested access to your social identity so that you can connect to peers. Click below to allow or reject.",
            ),
          ),
          ez("button", styles["allow-button"])
            .setTextContent("Allow")
            .onClick(() => {
              promptElement!.removeFromParent();
              this.storage.allowHost(host);
              this.update();
            }),
          ez("button", styles.button)
            .setTextContent("Reject")
            .onClick(() => {
              promptElement!.removeFromParent();
              this.storage.disallowHost(host);
              this.update();
            }),
        );
      }
    }

    this.append(
      ez("div", styles["admin-contents"]).append(
        // Username
        ez("div", styles["section"]).append(
          ez("div", styles["section-title"]).setTextContent("Username"),
          (this.usernameInput = new EZTextInput(() => {}).setValue(this.latestUsername)),
          (this.usernameConfirmButton = ez("button", styles.button)
            .setTextContent("Set Username")
            .onClick(() => {
              this.setUsername(this.usernameInput.getValue());
            })),
        ),

        new Tabs(
          [
            [
              "Hosts",
              ez("div", styles["tab-contents"]).append(
                ez("div", styles["warning"]).setTextContent(
                  "Only allow hosts that you trust the programs running on to not abuse the ability to initiate connections to your peers on your behalf.",
                ),

                promptElement || ez("span"),

                // Attempted Hosts
                ez("div", styles["section"]).append(
                  ez("div", styles["section-title"]).setTextContent("Recent Host Access Attempts"),
                  (this.recentAttemptsList = ez("div", styles["recent-attempts-list"])),
                ),

                // Allowed Hosts
                ez("div", styles["section"]).append(
                  ez("div", styles["section-title"]).setTextContent("Allowed Hosts"),
                  (this.allowedHostsList = ez("div", styles["allowed-hosts-list"])),
                ),
              ),
            ],
            [
              "Peers",
              ez("div", styles["tab-contents"]).append(
                // Id
                ez("div", styles["section"]).append(
                  ez("div", styles["section-title"]).setTextContent("Own Id"),
                  (this.idDisplay = ez("div")),
                ),

                // Address
                ez("div", styles["section"]).append(
                  ez("div", styles["section-title"]).setTextContent("Own Address"),
                  (this.ownPeerAddress = ez("div")),
                  ez("button", styles.button)
                    .setTextContent("Copy")
                    .onClick(() => {
                      navigator.clipboard.writeText(this.latestPeerAddress).then(
                        () => {
                          console.log("Did copy to clipboard");
                        },
                        (err) => {
                          console.error("Failed to copy to clipboard", err);
                        },
                      );
                    }),
                ),

                // Known Peers
                ez("div", styles["section"]).append(
                  ez("div", styles["section-title"]).setTextContent("Known Peers"),
                  ez("div", styles["peer-input-holder"]).append(
                    (this.peerInput = new EZTextInput(() => {})),
                    (this.peerConfirmButton = ez("button", styles.button)
                      .setTextContent("Add")
                      .onClick(() => {
                        this.addPeer(this.peerInput.getValue());
                      })),
                  ),
                  (this.knownPeersList = ez("div", styles["known-peers-list"])),
                ),
              ),
            ],
          ],
          {
            classes: TabStyles,
          },
        ),
      ),

      ez("div", styles["action-row"]).append(
        ez("button", styles["ok-button"])
          .setTextContent("OK")
          .onClick(() => {
            window.close();
          }),
      ),
    );

    window.addEventListener("storage", () => {
      this.update();
    });
    this.update();
  }

  private update() {
    this.updateAttemptsList();
    this.updateAllowedHostsList();
    this.updateKnownPeersList();

    const storedUsername = this.storage.getUsername();
    if (this.usernameInput.getValue() === this.latestUsername && this.latestUsername !== storedUsername) {
      this.usernameInput.setValue(storedUsername);
      this.latestUsername = storedUsername;
    }
    this.latestPeerAddress = `${getDefaultSignallingServerHost()}?${this.storage.getId().toString(10)}`;

    this.idDisplay.setTextContent(this.storage.getId().toString(10));
    this.ownPeerAddress.setTextContent(this.latestPeerAddress);
  }

  private updateAttemptsList() {
    const hostsLatestTime = new Map<
      string,
      {
        latestTime: number;
        attempts: number;
      }
    >();
    const attemptRows = [];
    for (const attemptedHost of this.storage.getAttemptedHosts()) {
      const entry = hostsLatestTime.get(attemptedHost.host);
      if (!entry) {
        hostsLatestTime.set(attemptedHost.host, {
          latestTime: attemptedHost.time,
          attempts: 1,
        });
      } else if (entry.latestTime < attemptedHost.time) {
        entry.latestTime = attemptedHost.time;
        entry.attempts++;
      } else {
        entry.attempts++;
      }
    }
    const sortedEntries = Array.from(hostsLatestTime.entries()).sort(([hostA, a], [hostB, b]) => {
      return b.latestTime - a.latestTime;
    });
    for (const [host, hostEntry] of sortedEntries) {
      const row = ez("div", [styles["row"], styles["attempt-row"]]).append(
        ez("div", styles["row-text-content"]).setTextContent(
          `${host} - ${hostEntry.latestTime} (${hostEntry.attempts} attempts)`,
        ),
        ez("button", styles["allow-button"])
          .setTextContent("Allow")
          .onClick(() => {
            this.storage.allowHost(host);
            this.update();
          }),
        ez("button", styles.button)
          .setTextContent("Reject")
          .onClick(() => {
            this.storage.disallowHost(host);
            this.update();
          }),
      );
      attemptRows.push(row);
    }
    if (attemptRows.length !== 0) {
      this.recentAttemptsList.applyChildren(attemptRows);
    } else {
      this.recentAttemptsList.setTextContent("No recent attempts to access");
    }
  }

  private updateAllowedHostsList() {
    const allowedHostRows = [];
    for (const allowedHost of this.storage.getAllowedHosts()) {
      const row = ez("div", [styles["row"], styles["allowed-row"]]).append(
        ez("div", styles["row-text-content"]).setTextContent(allowedHost),
        ez("button", styles.button)
          .setTextContent("Remove")
          .onClick(() => {
            this.storage.disallowHost(allowedHost);
            this.update();
          }),
      );
      allowedHostRows.push(row);
    }
    if (allowedHostRows.length !== 0) {
      this.allowedHostsList.applyChildren(allowedHostRows);
    } else {
      this.allowedHostsList.setTextContent("No accepted hosts");
    }
  }

  private updateKnownPeersList() {
    const knownPeersRows = [];
    for (const knownPeer of this.storage.getKnownPeers()) {
      const row = ez("div", styles["row"]).append(
        ez("div", styles["row-text-content"]).setTextContent(`${knownPeer.server}?${knownPeer.userId.toString(10)}`),
        ez("button", styles.button)
          .setTextContent("Remove")
          .onClick(() => {
            this.storage.removeKnownPeer(knownPeer);
            this.updateKnownPeersList();
          }),
      );
      knownPeersRows.push(row);
    }
    if (knownPeersRows.length !== 0) {
      this.knownPeersList.applyChildren(knownPeersRows);
    } else {
      this.knownPeersList.setTextContent("No known peers");
    }
  }

  private setUsername(newUsername: string) {
    this.usernameInput.setValue(newUsername);
    this.storage.persistIdentity(newUsername);
    this.latestUsername = newUsername;
  }

  private addPeer(peerAddress: string) {
    const parsedPeerAddress = parsePeerAddress(peerAddress);
    if (parsedPeerAddress instanceof Error) {
      alert(parsedPeerAddress.message);
      return;
    }
    // Clear the input
    this.peerInput.setValue("", false);
    this.storage.addPeerIdentity(parsedPeerAddress.userId, parsedPeerAddress.server, "some-random-token");

    this.updateKnownPeersList();
  }
}
